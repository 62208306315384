/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef } from "react";
import { css, keyframes } from "@emotion/react";
import { isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Slider, { Settings } from "react-slick";
import { fromCDN } from "@assets/utils";
import useDevice from "@hooks/useDevice";
import theme, { getDeviceSize } from "@common/styles/theme";
import useIntersectionObserver from "@hooks/useIntersectionObserver";

const Battle1 = fromCDN("brand/images/battle04.png");
const Battle2 = fromCDN("brand/images/battle_2_low.png");
const Battle3 = fromCDN("brand/images/battle_3_low.png");
const Battle4 = fromCDN("brand/images/battle01.png");
const Battle5 = fromCDN("brand/images/battle_5_low.png");
const Battle6 = fromCDN("brand/images/battle_6_low.png");
const Effect01 = fromCDN("brand/images/04_effect_1_low.png");
const Effect02 = fromCDN("brand/images/04_7_low.png");
const Effect03 = fromCDN("brand/images/04_8_low.png");
const Vs = fromCDN("brand/images/vs.png");
const Left = fromCDN("brand/svg/left_button.svg");
const LeftHover = fromCDN("brand/svg/left_hover.svg");
const INGAME_en = fromCDN("brand/images/battle_en.png");
const INGAME_jp = fromCDN("brand/images/battle_jp.png");
const INGAME_th = fromCDN("brand/images/battle_th.png");
const INGAME_tw = fromCDN("brand/images/battle_tw.png");

type Props = {
  currentSection: number;
};

const BattleView = ({ currentSection }: Props) => {
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIntersectionObserver(ref);
  const { fromLapTop } = useDevice();
  const { t, i18n } = useTranslation();
  const sliderRef = useRef<Slider>(null);

  function handleScroll() {
    if (isInViewport && !animationPlayed) {
      let vs = document.getElementById("vs");
      vs?.classList.add("scale");

      for (let i = 0; i < 3; i++) {
        let left = document.getElementById(`leftImg${i}`);
        let right = document.getElementById(`rightImg${i}`);

        setTimeout(function () {
          left?.classList.add("toLeft", "opacity");
          right?.classList.add("toRight", "opacity");
        }, 100 * (3 - i));

        setTimeout(function () {
          left?.classList.remove("toLeft");
          right?.classList.remove("toRight");
        }, 300 * (3 - i));

        setTimeout(function () {
          left?.classList.add("float");
          right?.classList.add("float");
        }, 100 * (3 - i));
      }

      let boom1 = document.getElementById("boom1");
      let boom2 = document.getElementById("boom2");
      let fire = document.getElementById("fire");

      setTimeout(function () {
        boom1?.classList.add("boom", "opacity");
        boom2?.classList.add("boom2", "opacity");
        fire?.classList.add("fire");
      }, 300);

      setAnimationPlayed(true);
    }
  }

  useEffect(() => {
    if (currentSection === 4) {
      handleScroll();
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const settings: Settings = {
    adaptiveHeight: false,
    dots: true,
    draggable: true,
    swipe: true,
    centerPadding: "0px",
    infinite: true,
    vertical: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    prevArrow: <ArrowButton type="PREV" />,
    nextArrow: <ArrowButton type="NEXT" />,
    responsive: [
      {
        breakpoint: getDeviceSize("mobile"),
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <section
      css={
        isTablet ? [battle(i18n.language), battleTablet] : battle(i18n.language)
      }
    >
      <h2 key={i18n.language} ref={ref}>
        {t("brand:게임소개:intro12")}
      </h2>
      <p>{t("brand:게임소개:intro13")}</p>
      <div css={InnerGameImg}>
        {/* <img src={Ingame} alt="" /> */}
        <Slider {...settings} ref={sliderRef}>
          <img
            alt="In game screenshot"
            src={
              i18n.language === "JP"
                ? INGAME_jp
                : i18n.language === "TH"
                ? INGAME_th
                : i18n.language === "TW"
                ? INGAME_tw
                : INGAME_en
            }
            className="gifsize"
          />
        </Slider>
      </div>
      <div className="imgContainer">
        {!fromLapTop && <img src={Vs} alt="" id="vs" className="vs" />}
        <div className="container">
          <div className="imageRight">
            <span id="rightImg0" />
            <span id="rightImg1" />
            <span id="rightImg2" />
            <span id="boom1" className="effect03" />
          </div>
          <div className="imageLeft">
            <span id="leftImg0" />
            <span id="leftImg1" />
            <span id="leftImg2" />
            <span id="boom2" className="effect02" />
          </div>
        </div>
      </div>
      <span id="fire" className="effect01" />
    </section>
  );
};

const ArrowButton = ({
  currentSlide,
  slideCount,
  children,
  type,
  ...props
}: Record<string, unknown> & { type: "PREV" | "NEXT" }) => {
  return <div {...props} />;
};

export default BattleView;

const fire = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const boom = keyframes`
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.5);
  }
`;

const scale = keyframes`
  0% {
    transform: scale(0.5);
  }
  50%{
    transform: scale(1.3);
  }
  100% {
    transform: scale(0.9);
  }
`;

const appearLeft = keyframes`
  0% {
    opacity: 0;
    left: 30%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
`;

const appearRight = keyframes`
  0% {
    opacity: 0;
    left: -300px;
  }
  100% {
    opacity: 1;
    left: -10px;
  }
`;

const float = keyframes`
  0% {
    opacity: 1;
    transform: translatey(0px);
  }
  50% {
    opacity: 1;
    transform: translatey(-20px);
  }
  100% {
    opacity: 1;
    transform: translatey(0px);
  }
`;

const InnerGameImg = css`
  width: 80%;
  min-width: 250px;
  max-width: 620px;

  & img {
    position: relative;
    z-index: 10;
    width: 100%;
  }

  .slick-slider {
    z-index: 50;
  }

  .slick-dots {
    height: 10px;
    bottom: -20px;
    z-index: 20;

    & li {
      margin: 0 2px;
      width: unset;
      height: unset;
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        background-color: ${theme.palette.common.white};
        border-radius: 50%;
        transition: all ease 0.5s 0s;
        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          width: 30px;
          height: 10px;
          background-color: ${theme.palette.primary.main};
          border-radius: 30px;
        }
      }
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-prev,
  .slick-next {
    display: none;
  }

  @media ${theme.devices.tablet} {
    .slick-prev,
    .slick-next {
      display: none !important;
    }

    .slick-dots {
      height: 14px;
      bottom: -40px;

      & li {
        margin: 0 4px;
        button {
          width: 14px;
          height: 14px;
        }

        &.slick-active {
          button {
            width: 44px;
            height: 14px;
          }
        }
      }
    }
  }

  @media ${theme.devices.laptop} {
    .slick-prev,
    .slick-next {
      display: block !important;
      background: url(${Left}) no-repeat;
      background-size: cover;
      width: 100px;
      height: 100px;
      position: absolute;
      z-index: 999 !important;
      &:before {
        display: none;
      }

      &:hover {
        background: url(${LeftHover}) no-repeat;
      }
    }

    .slick-prev {
      left: -100px;
      top: 180px;
    }

    .slick-next {
      right: -100px;
      top: 180px;
      transform: translate(0, -50%) rotate(180deg);
    }
  }

  @media ${theme.devices.laptop} and (max-height: 800px) {
    margin-bottom: 105px;
    .gifsize {
      width: 594px !important;
    }
  }

  @media ${theme.devices.laptop} and (max-height: 880px) {
    margin-bottom: 105px;
  }
`;

const battle = (lang: string) => css`
  color: ${theme.palette.common.white};
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 0 121px 0;
  position: relative;
  display: flex;
  align-items: center;

  h2 {
    font-style: normal;
    font-size: clamp(36px, 10vw, 48px);
    line-height: 37px;
    margin-bottom: 10px;
    ${theme.applyGangwonEduFont[lang as keyof typeof theme.applyGangwonEduFont]}
  }

  p {
    width: ${["EN", "TH"].includes(lang) && "100%"};
    max-width: ${["EN", "TH"].includes(lang) && "430px"};
    padding: ${["EN", "TH"].includes(lang) && "0 24px"};
    ${theme.fonts.small1B};
    line-height: 24px;
    margin-bottom: 30px;
    position: relative;
    z-index: 100;
    white-space: ${["EN", "TH"].includes(lang) ? "unset" : "pre"};
  }

  .opacity {
    opacity: 1 !important;
  }
  .vs {
    display: block;
    width: 75%;
    position: relative;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 550px !important;
    min-width: 250px;
  }
  .scale {
    animation: ${scale} 1s ease-in-out 1 forwards !important;
  }
  .boom {
    animation: ${boom} 5.5s ease-in-out infinite !important;
  }
  .boom2 {
    animation: ${boom} 4s ease-in-out infinite !important;
  }
  .fire {
    animation: ${fire} 5.5s ease-in-out infinite !important;
  }
  .imgContainer {
    position: relative;
    max-width: 100vw;
    min-width: 430px;
    width: 100%;
    height: auto;
    z-index: 5;
  }
  .container {
    width: 100%;
    position: absolute;
    top: -4.5%;
    left: 50%;
    transform: translateX(-43%);
    height: 100%;
  }
  .container span {
    width: 80%;
    height: 83%;
    position: absolute;
    display: block;
    background-size: contain !important;
    will-change: transform;
  }
  .effect01 {
    display: block;
    position: absolute;
    width: 1200px;
    height: 600px !important;
    z-index: 1;
    background: url(${Effect01});
    opacity: 0;
    top: 33%;
    left: 50%;
    margin-left: -600px;
  }
  .effect03,
  .effect02 {
    width: 261px !important;
    height: 229px !important;
    background: url(${Effect03});
    z-index: 10;
    top: -120px;
    display: none !important;
  }
  .effect02 {
    background: url(${Effect02});
    z-index: 0;
    top: -50px;
    left: -50px;
  }
  #boom1 {
    left: 2%;
    top: 140px;
    z-index: 5;
  }
  .container .imageRight,
  .container .imageLeft {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container div {
    display: inline-block;
  }
  .container .imageLeft {
    top: 0px;
    left: -6%;
    & span {
      opacity: 0;
    }
    & span:nth-of-type(1) {
      background-size: cover;
      background-position-x: 65px !important;
      background-repeat: no-repeat !important;
      background: url(${Battle4});
    }
    & span:nth-of-type(2) {
      background-position-x: -30px !important;
      background-repeat: no-repeat !important;
      background: url(${Battle5});
    }
    & span:nth-of-type(3) {
      background-position-x: 40px !important;
      background-repeat: no-repeat !important;
      background: url(${Battle6});
      z-index: 5;
    }
  }
  .container .imageRight {
    right: -40%;
    top: 0px;

    & span {
      opacity: 0;
      z-index: 0;
    }
    & span:nth-of-type(1) {
      background: url(${Battle1});
      background-position-x: -26px !important;
      background-repeat: no-repeat;
    }
    & span:nth-of-type(2) {
      background-position-x: 75px !important;
      background-repeat: no-repeat !important;
      background: url(${Battle2});
      z-index: 2;
    }
    & span:nth-of-type(3) {
      background-position-x: 30px !important;
      background-repeat: no-repeat !important;
      background: url(${Battle3});
      z-index: 1;
    }
  }
  @media (min-width: 500px) {
    .container .imageRight {
      & span:nth-of-type(2) {
        background-position-x: 100px !important;
      }
    }
  }
 
  .container .imageLeft span:nth-of-type(1) {
    bottom: -50%;
    z-index: 4;
    animation-duration: 6.8s;
  }
  .container .imageLeft span:nth-of-type(2) {
    top: 47%;
    z-index: 3;
  }
  .container .imageLeft span:nth-of-type(3) {
    top: -13%;
    z-index: 1;
    left: -8%;
    animation-duration: 5s;
  }
  .container .imageRight span:nth-of-type(1) {
    bottom: -50%;
    z-index: 11;
    animation-duration: 7s;
  }
  .container .imageRight span:nth-of-type(2) {
    top: 85px;
    z-index: 2;
    animation-duration: 5s;
  }
  .container .imageRight span:nth-of-type(3) {
    top: -4%;
    left: 6%;
  }
  .toLeft {
    animation: ${appearLeft} 0.5s ease-out 1 forwards !important;
  }
  .toRight {
    animation: ${appearRight} 0.5s ease-out 1 forwards !important;
  }
  .float {
    animation: ${float} 6s ease-in-out infinite;
  }

  @media ${theme.devices.tablet} {
    height: 1050px;
    padding: 0;

    .imgContainer {
      max-width: 970px;
      width: 100%;
    }
    h2 {
      font-size: 60px;
      line-height: 62px;
      z-index: 10;
    }
    p {
      padding: ${["EN", "TH"].includes(lang) && "0"};
      max-width: ${["EN", "TH"].includes(lang) && "unset"};
      ${theme.fonts.subCopyB};
      line-height: 26px;
      white-space: ${["EN", "TH"].includes(lang) && "pre"};
    }
    .imgContainer {
      min-width: 720px;
      height: 600px;
    }
    .container {
      top: 70px;
      width: 700px;
      height: 400px;
      left: 50%;
      margin-left: -30px;
    }
    .vs {
      width: 100%;
      height: auto;
      margin: 0 auto;
      top: 50px;
    }
    .container span {
      width: 720px;
      height: 369px;
    }
    .container .imageLeft {
      left: -12%;
      top: 0px;

      & span {
        max-height: 340px;
      }
    }
    .container .imageRight {
      top: -40px;
      right: -45%;

      & span {
        z-index: 10;
        max-height: 340px;
      }
    }
    .container .imageLeft span:nth-of-type(1) {
      background-position-x: 100px !important;
      bottom: -10%;
    }
    .container .imageLeft span:nth-of-type(2) {
      background-position-x: -60px !important;
      top: 0px;
    }
    .container .imageLeft span:nth-of-type(3) {
      background-position-x: -30px !important;
      top: -200px;
    }
    .container .imageRight span:nth-of-type(1) {
      background-position-x: -50px !important;
      background-repeat: no-repeat;
      bottom: -20%;
    }
    .container .imageRight span:nth-of-type(2) {
      background-position-x: 150px !important;
      top: 51px;
      z-index: 2;
    }
    .container .imageRight span:nth-of-type(3) {
      top: -89px;
      left: 1%;
      background-position-x: 140px !important;
      background-repeat: no-repeat;
    }
    .effect01 {
      width: 2000px !important;
      top: 200px !important;
      height: 1000px !important;
      left: 45% !important;
      margin-left: -1000px !important;
    }
    .effect03,
    .effect02 {
      width: 261px !important;
      height: 229px !important;
      background: url(${Effect03});
      z-index: 10;
      top: -20px;
      right: 250px;
    }
    .effect02 {
      background: url(${Effect02});
      z-index: 0;
      top: -150px;
      left: 0px;
    }
    .effect03 {
      position: relative !important;
      right: -150px;
      top: 150px;
    }
  }

  @media ${theme.devices.laptop} {
    height: 100vh;
    padding-bottom: 200px;
    padding-top: 0px;

    h2 {
      margin-bottom: 6px;
    }
    .vs {
      width: 100%;
      margin-bottom: 100px;
      max-width: 1000px !important;
    }
    .imgContainer {
      min-width: 1050px;
      height: 600px;
      position: absolute;
      z-index: unset;
    }
    .container {
      height: 600px;
      position: relative;
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;
      z-index: 10;
      transform: translateX(-500px);
      top: 0;
      margin-top: 100px;
    }
    .container span {
      width: 1046px;
      height: 1000px;
    }
    .container .imageLeft {
      left: -22%;
      top: 210px;

      & span {
        max-height: 480px;s
      }
    }
    .container .imageRight {
      right: -61%;
      top: 248px;

      & span {
        max-height: 480px;
      }
    }
    .container .imageLeft span:nth-of-type(1) {
      top: 50px;
      background-position-x: 140px !important;
    }
    .container .imageLeft span:nth-of-type(2) {
      right: 8%;
      top: -120px;
      background-position-x: -50px !important;
    }
    .container .imageLeft span:nth-of-type(3) {
      top: -390px;
      background-position-x: 50px !important;
    }
    .container .imageRight span:nth-of-type(1) {
      top: 30px;
    }
    .container .imageRight span:nth-of-type(2) {
      top: -190px;
      left: 5%;
      background-position-x: 160px !important;
    }
    .container .imageRight span:nth-of-type(3) {
      top: -430px;
      background-position-x: 70px !important;
    }
    .effect01 {
      width: 4000px !important;
      top: -20px !important;
      height: 960px !important;
      margin-left: -1185px !important;
    }
    .effect03,
    .effect02 {
      display: block !important;
      width: 361px !important;
      height: 329px !important;
      top: -300px;
      right: 260px;
    }
    .effect02 {
      top: -330px;
      left: 40px;
    }
    #boom1 {
      left: 22%;
      top: -231px;
      z-index: 5;
    }
    .effect03 {
      right: -10px;
    }
  }

  @media ${theme.devices.laptop} and (max-height: 880px) {
    padding-bottom: 0;

    .imgContainer {
      height: 500px;
      max-width: 1270px;
    }
    .vs {
      width: 70%;
      margin-bottom: 100px;
      max-width: 1000px !important;
    }
    .container .imageRight {
      right: -70%;
      top: 250px;
    }
    .imageRight {
      & span {
        height: 80%;
      }
    }
    .container .imageRight span:nth-of-type(1) {
      top: -50px;
    }
    .container .imageRight span:nth-of-type(2) {
      top: -200px;
      left: 0%;
    }
    .container .imageRight span:nth-of-type(3) {
      top: -350px;
      left: -2%;
    }
    #boom1 {
      left: 11%;
      top: -262px;
    }
    .container .imageLeft span:nth-of-type(1) {
      top: -50px;
    }
    .container {
      top: -10%;
      left: 0;
      margin-top: 50px;
      transform: translateX(0px);
    }
    .container .imageLeft {
      left: -9%;
      top: 280px;
    }
    .imageLeft {
      & span {
        max-height: 360px !important;
        height: 80%;
      }
    }
    .imageRight {
      & span {
        max-height: 360px !important;
      }
    }
    .container .imageLeft span:nth-of-type(1) {
      top: -50px;
    }
    .container .imageLeft span:nth-of-type(2) {
      top: -145px;
      right: 12%;
      background-position-x: -20px !important;
    }
    .container .imageLeft span:nth-of-type(3) {
      top: -360px;
      left: unset;
    }
    .effect02 {
      top: -350px;
    }
    .effect01 {
      top: -150px!important;
    }
  }
`;

const battleTablet = css`
  overflow: visible;
  @media ${theme.devices.laptop} {
    height: -webkit-fill-available;
  }
`;
