import { useEffect } from 'react';

export default function useBodyHidden(condition = true) {
  useEffect(() => {
    if (condition) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [condition]);
}
