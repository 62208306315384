import { selector } from "recoil";
import { accessTokenAtom } from "@modules/Brand/recoil/token/atom";
import { setAccessToken } from "@modules/Brand/model";

export const accessTokenState = selector({
  key: "AccessTokenState",
  get: ({ get }) => {
    return get(accessTokenAtom);
  },
  set: ({ set }, newValue) => {
    setAccessToken(newValue as string);
    set(accessTokenAtom, newValue);
  },
});
