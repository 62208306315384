/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { Link } from "react-router-dom";
import {
  fromCDN,
  openPrivacyPolicyUrl,
  openTermsOfServiceUrl,
} from "@assets/utils";
import { useTranslation } from "react-i18next";
import { brandSocial } from "@modules/Brand/model";
import { route } from "@common/constants";

const Logo = fromCDN("brand/svg/bi_nhn.svg");
const LogoWhite = fromCDN("brand/svg/bi_nhn_blk.svg");
const Highbrow = fromCDN("brand/images/bi_highbrow@.png");
const HighbrowWhihe = fromCDN("brand/images/bi_highbrow@blk.png");

function Footer() {
  const { t, i18n } = useTranslation();

  const urlParms = window.location.pathname;

  const whiteThemePages = [
    route.brand.loginPage,
    route.brand.myPage,
    route.coupon.main,
  ];
  const isWhiteThemePage = whiteThemePages.includes(urlParms);

  return (
    <footer css={footer(i18n.language)} className="footerContainer">
      <div className={isWhiteThemePage ? "whiteStyle" : "darkStyle"}>
        <ul className="socials">
          {brandSocial[i18n.language as keyof typeof brandSocial]?.map(i => {
            return (
              <li onClick={() => window.open(i.url, "_blank")} key={i.title}>
                <img src={i.social} alt={i.title} />
              </li>
            );
          })}
        </ul>
        <div className="footerInner">
          <ul>
            <li onClick={openTermsOfServiceUrl}>{t("brand:푸터:footer1")}</li>
            <li onClick={openPrivacyPolicyUrl}>{t("brand:푸터:footer2")}</li>
          </ul>
        </div>
        <div className="textBox">
          {/* <span>
            Xsolla is an authorized global distributor of <br />
            Wooparoo Odyssey.
          </span> */}
          <span>© NHN Corp. All rights reserved.</span>
        </div>
        <div className="logos">
          <img
            className="nhn_bi"
            src={isWhiteThemePage ? LogoWhite : Logo}
            alt="로고"
          />
          <img
            className="highbrow_bi"
            src={isWhiteThemePage ? HighbrowWhihe : Highbrow}
            alt="하이브로우 로고"
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;

const footer = (lang: string) => css`
  .darkStyle {
    background: ${theme.palette.grey[8]};
    color: ${theme.palette.grey[4]};
    text-align: center;
    position: relative;
    font-size: 12px;
    display: block;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;

    li {
      color: ${theme.palette.grey[2]};
      text-decoration: none;
    }

    span {
      display: block;
      line-height: normal;
      margin-bottom: 20px;
    }

    .textBox {
      margin-bottom: 30px;
    }

    .socials {
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      li {
        height: 40px;
      }

      li img {
        width: 40px;
        height: 40px;
      }
    }

    .footerInner {
      height: 60px;
      margin-bottom: 30px;
      ${["TH", "TW"].includes(lang)
        ? theme.fonts.small2B
        : theme.fonts.small3B};
      ${theme.mixins.displayCenter};
      flex-direction: column;
      background: ${theme.palette.grey[7]};

      li {
        display: inline-block;
        position: relative;
        margin-right: 20px;
      }

      li:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      li:nth-of-type(1)::after {
        position: absolute;
        display: block;
        content: "";
        top: ${["TH", "TW"].includes(lang) ? "3px" : "2px"};
        width: 1px;
        right: -10px;
        height: 10px;
        background: ${theme.palette.grey[6]};
      }

      li:nth-last-of-type(1) {
        margin-right: 0px;
      }

      span {
        ${theme.fonts.small3R};
      }
    }

    .logos {
      padding-bottom: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;

      .nhn_bi {
        width: 76px;
        height: 18px;
      }

      .highbrow_bi {
        width: 110px;
      }
    }

    @media ${theme.devices.tablet} {
      br {
        display: none;
      }

      span {
        margin-bottom: 2px;
      }

      .logos {
        /* width: 222px; */
        margin: 0 auto;
        flex-direction: row;
        gap: 40px;

        .highbrow_bi {
          padding-top: 2px;
        }
      }
    }

    @media ${theme.devices.laptop} {
      .socials {
        padding: 30px 0;
        gap: 30px;

        li {
          &:hover {
            cursor: pointer;
          }
        }

        li img {
          width: 40px;
        }
      }
    }
  }

  .whiteStyle {
    background: #fff;
    color: ${theme.palette.grey[4]};
    text-align: center;
    width: 100%;
    display: block;
    font-size: 12px;
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;

    li {
      color: ${theme.palette.grey[2]};
      text-decoration: none;
    }

    li:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    span {
      color: ${theme.palette.grey[6]};
      display: block;
      margin-bottom: 20px;
      line-height: 17.38px;
    }

    .textBox {
      margin-bottom: 30px;
    }

    .socials {
      display: none;
    }

    .footerInner {
      height: 60px;
      background: ${theme.palette.grey[1]};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      ${["TH", "TW"].includes(lang)
        ? theme.fonts.small2B
        : theme.fonts.small3B};

      li {
        display: inline-block;
        position: relative;
        margin-right: 20px;
      }

      li:hover a {
        text-decoration: underline;
      }

      li:nth-of-type(1)::after {
        content: "";
        display: block;
        position: absolute;
        top: ${["TH", "TW"].includes(lang) ? "3px" : "2px"};
        right: -10px;
        width: 1px;
        height: 10px;
        background: ${theme.palette.grey[3]};
      }

      li:nth-last-of-type(1) {
        margin-right: 0px;
      }

      li {
        color: ${theme.palette.grey[6]};
      }

      span {
        ${theme.fonts.small3R};
      }
    }

    .logos {
      padding-bottom: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;

      .nhn_bi {
        width: 82px;
        height: 20px;
      }

      .highbrow_bi {
        width: 110px;
      }
    }

    @media ${theme.devices.tablet} {
      br {
        display: none;
      }

      span {
        margin-bottom: 2px;
      }

      .logos {
        /* width: 222px; */
        margin: 0 auto;
        flex-direction: row;
        gap: 40px;

        .highbrow_bi {
          padding-top: 2px;
        }
      }
    }
  }
`;
