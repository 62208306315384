/** @jsxImportSource @emotion/react */

import React from "react";
import Nav from "./Nav";
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { useTranslation } from "react-i18next";

type Props = {
  children: React.ReactNode;
};

function Layout({ children }: Props) {
  const { i18n } = useTranslation();

  return (
    <div className={i18n.language} css={ContainerCss}>
      <Nav />
      <main css={childrenCSS}>{children}</main>
    </div>
  );
}

export default Layout;

const ContainerCss = css`
  position: relative;

  &.JP {
    font-family: "GenJyuuGothic", "Wooparoo Sans Serif", sans-serif;

    .navContainer,
    .footerContainer {
      font-family: "Wooparoo Sans Serif", sans-serif;
    }
  }

  &.TW {
    font-family: "Wooparoo SansSC Serif", sans-serif;
  }

  &.TH {
    font-family: "Wooparoo SansTH Serif", sans-serif;
  }
`;

const childrenCSS = css`
  position: relative;
  padding-bottom: 0;

  @media ${theme.devices.laptop} {
    padding-bottom: 0;
  }
`;
