import CouponController from "@modules/Coupon/controller/CouponController";
import { CouponControllerProps } from "@modules/Coupon/controller/interface";
import CouponView from "@modules/Coupon/view";
import Layout from "@modules/Coupon/view/components/layout/Layout";
import React from "react";

function index() {
  return (
    <Layout>
      <CouponController>
        <CouponView {...({} as CouponControllerProps)} />
      </CouponController>
    </Layout>
  );
}

export default index;
