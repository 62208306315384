/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useRecoilState } from "recoil";
import { fromCDN } from "@assets/utils";
import theme from "@common/styles/theme";
import { useTranslation } from "react-i18next";
import useDevice from "@hooks/useDevice";
import useResize from "@hooks/useResize";
import ScrollDown from "../components/ScrollDown";
import PreRegisterList from "../components/PreRegisterList";
import { currentSectionState } from "@modules/Brand/recoil/section";
import { useScrollTo } from "@hooks/useScrollTo";

const PLAY = fromCDN("brand/images/play.png");
const APPLE_STORE = fromCDN("brand/svg/down_app.svg");
const GOOGLE_STORE = fromCDN("brand/svg/down_google.svg");
const ONE_STORE = fromCDN("brand/svg/down_one.svg");
const MAIN_LOGO = fromCDN("brand/images/Wooparoo-Odyssey_en.png");
const MAIN_LOGO_JP = fromCDN("brand/images/Wooparoo-Odyssey_jp.png");
const MAIN_LOGO_TW = fromCDN("brand/images/Wooparoo-Odyssey_tw.png");
const MAIN_LOGO_TH = fromCDN("brand/images/Wooparoo-Odyssey_th.png");
const PREREGIBTN = fromCDN("brand/images/counterbox.png");
const VIDEO_PC = fromCDN("brand/video/main-video-p.mp4");
const VIDEO_MB = fromCDN("brand/video/main-video-m.mp4");

type Props = {
  className?: string;
  setIsVideoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MainVideo = ({ setIsVideoModalOpen }: Props) => {
  const { width } = useResize();
  const { fromLapTop } = useDevice();
  const { scrollTo } = useScrollTo();
  const [video, setVideo] = useState("");
  const { t, i18n } = useTranslation();
  const [currentSection, setCurrentSection] =
    useRecoilState(currentSectionState);

  useEffect(() => {
    if (width && fromLapTop) {
      setVideo(VIDEO_PC);
    } else {
      setVideo(VIDEO_MB);
    }
  }, [width, video, fromLapTop]);

  const goToPreRegiSection = () => {
    const panels = Array.from(document.querySelectorAll<HTMLElement>(".panel"));
    const panelTops = panels.map(panel => panel.offsetTop);

    scrollTo({
      to: panelTops[4],
    });
  };

  return (
    <section css={mainVideo}>
      <div css={videoContainer}>
        <video
          autoPlay
          muted
          loop
          css={videoStyle}
          playsInline={true}
          key={video}
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div css={mainContainer(i18n.language)}>
        <img
          alt=""
          src={
            i18n.language === "JP"
              ? MAIN_LOGO_JP
              : i18n.language === "TW"
              ? MAIN_LOGO_TW
              : i18n.language === "TH"
              ? MAIN_LOGO_TH
              : MAIN_LOGO
          }
          className="logo"
          loading="lazy"
        />
        <img
          alt=""
          src={PLAY}
          className="play"
          onClick={() => {
            setIsVideoModalOpen(true);
          }}
          loading="lazy"
        />
        {/* <div
          onClick={() => {
            if (!fromLapTop) goToPreRegiSection();
            setCurrentSection(5);
          }}
          className={`preRegiBtn ${i18n.language}`}
        >
          {t("brand:게임소개:intro0")}
        </div> */}
        <PreRegisterList />
      </div>
      {fromLapTop && <ScrollDown sectionIdx={0} sectionVal={1} />}
    </section>
  );
};

export default MainVideo;

const mainVideo = css`
  position: relative;

  @media ${theme.devices.laptop} {
    height: 100vh;
  }
`;

const videoContainer = css`
  width: 100%;
  height: 714px;
  background: #000000;

  @media ${theme.devices.tablet} {
    height: 1050px;
  }

  @media ${theme.devices.laptop} {
    height: 100vh;
  }
`;

const videoStyle = css`
  width: 100%;
  height: 714px;
  opacity: 0.6;
  object-fit: cover;

  @media ${theme.devices.tablet} {
    height: 1050px;
  }

  @media ${theme.devices.laptop} {
    height: 100vh;
  }
`;

const mainContainer = (lang: string) => css`
  ${theme.mixins.displayCenter};
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 100%;
  /* height: 714px; */
  padding-top: 120px;

  img {
    display: block;
  }

  .logo {
    position: relative;
    width: 200px;
    height: 109px;
    margin: 0 auto;
    margin-bottom: 62px;
  }

  .play {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    margin-bottom: 157px;
  }

  .preRegiBtn {
    width: 222px;
    height: 60px;
    margin-bottom: 40px;
    background: url(${PREREGIBTN}) no-repeat center center;
    background-size: contain;
    ${theme.applyGangwonEduFont[lang as keyof typeof theme.applyGangwonEduFont]}
    font-size: 16px;
    font-style: normal;
    color: ${theme.palette.common.white};
    ${theme.mixins.displayCenter};

    &.EN {
      font-size: 14px;
    }

    &.TH {
      font-size: 12px;
      font-weight: 700;
    }

    &.JP,
    &.TW {
      font-weight: 700;
    }
  }

  .downloadContainer {
    & div {
      display: flex;
      width: 160px;
      height: 48px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
      padding: 11px 0;
      text-align: left;
      align-items: center;
      justify-content: center;
      margin-bottom: 9px;
    }

    & a {
      text-decoration: none;
      color: #fff;
    }

    & span {
      display: block;
      ${theme.fonts.small3R};
      line-height: 1.5;
    }

    & strong {
      ${theme.fonts.small3B};
    }

    .apple {
      margin-right: 19px;
      width: 20px;
      height: 24px;
      background-size: cover !important;
      background: url(${APPLE_STORE});
    }

    .google {
      margin-right: 19px;
      width: 20px;
      height: 24px;
      background-size: cover !important;
      background: url(${GOOGLE_STORE});
    }

    .one {
      margin-right: 19px;
      width: 25px;
      height: 24px;
      background-size: cover !important;
      background: url(${ONE_STORE});
    }
  }

  @media ${theme.devices.tablet} {
    height: 1050px;
    padding-top: 80px;

    .logo {
      width: 370px;
      height: 200px;
      margin-bottom: 32px;
    }

    .play {
      width: 130px;
      height: 130px;
      margin-bottom: 170px;
    }

    .preRegiBtn {
      width: 298px;
      height: 80px;
      font-size: 24px;

      &.EN {
        font-size: 20px;
      }

      &.TH {
        font-size: 18px;
      }
    }

    .downloadContainer {
      & ul {
        display: flex;
        & li {
          margin-right: 10px;
        }

        & li:nth-of-type(3) {
          margin-right: 0;
        }
      }

      & div {
        width: 200px;
        height: 60px;
        padding: 12px 0;
      }

      & span {
        ${theme.fonts.small2R};
        line-height: 1.5;
      }

      & strong {
        ${theme.fonts.small2B};
      }

      .apple {
        width: 24px;
        height: 30px;
      }

      .google {
        width: 24px;
        height: 26px;
      }

      .one {
        width: 30px;
        height: 30px;
      }
    }
  }

  @media ${theme.devices.laptop} {
    height: 100vh;
    padding-bottom: 100px;

    .play {
      margin-bottom: 156px;
    }

    .preRegiBtn {
      cursor: pointer;

      &:active {
        ${theme.mixins.buttonPressEffect};
      }

      &:hover {
        color: ${theme.palette.primary.main};
      }
    }
  }

  @media ${theme.devices.laptop} and (${theme.resolutionHeight.height1}) {
    padding-top: 0px;
    padding-bottom: 0px;

    .logo {
      width: 320px;
      height: auto;
      margin-bottom: 20px;
    }

    .play {
      width: 110px;
      height: auto;
      margin-bottom: 30px;
    }
  }
`;
