/** @jsxImportSource @emotion/react */
import { Dispatch, SetStateAction, useEffect } from "react";
import { css } from "@emotion/react";
import { fromCDN } from "@assets/utils";
import theme from "@common/styles/theme";
import useDevice from "@hooks/useDevice";
import SVGComponent from "@modules/Brand/view/components/SVGComponent";

const CLOSE = SVGComponent(fromCDN("brand/svg/close.svg"));
const VIDEO = fromCDN("brand/video/main-video-full.mp4");
const VIDEO_THUMNAIL = fromCDN("brand/images/video_thumbnail.png");

type Props = {
  setIsVideoModalOpen: Dispatch<SetStateAction<boolean>>;
};

const VideoModal = ({ setIsVideoModalOpen }: Props) => {
  const { fromTabletL } = useDevice();
  let vh = 0;

  useEffect(() => {
    vh = window.innerHeight * 0.01;
    const setVh = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight}px`,
      );
    };
    window.addEventListener("resize", setVh);
    setVh();
  }, []);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      // overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";

      if (fromTabletL) {
        document.body.style.cssText = "overflow: hidden";
      }

      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, [fromTabletL]);

  return (
    <div css={videoModal}>
      <div className="videoContainer">
        <button className="close">
          <CLOSE
            onClick={() => setIsVideoModalOpen(false)}
            className={"closeIcon"}
          />
        </button>
        <video
          controls
          className="innerVideo"
          // poster={VIDEO_THUMNAIL}
        >
          <source src={VIDEO} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default VideoModal;

const videoModal = css`
  :root {
    --vh: 100%;
  }

  display: felx;
  width: 100%;
  height: var(--vh);
  position: fixed;
  top: 0;
  z-index: 9999;
  ${theme.mixins.displayCenter};
  flex-direction: column;
  background: black;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px) brightness(0.5);
  overflow: hidden;
  // min-width: 310px;
  padding-top: 30px;

  & video {
    display: block;
  }

  video[poster] {
    display: block;
    height: 100%;
    width: 100%;
  }

  .close {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: -40px;
  }

  .closeIcon {
    filter: invert(97%) sepia(67%) saturate(6234%) hue-rotate(332deg)
      brightness(100%) contrast(102%);
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
  }

  .videoContainer {
    // min-width: 312px;
    position: relative;
    width: 90%;
  }

  .innerVideo {
    border: 2px solid ${theme.palette.primary.main};
    box-sizing: border-box;
    width: 100%;
  }

  @media (max-height: 370px) {
    .videoContainer {
      width: 5%;
    }

    .close {
      top: -20px;
    }
  }

  @media (max-height: 430px) {
    overflow-y: scroll;

    .videoContainer {
      padding-top: 20px;
      width: 70%;
    }

    .close {
      position: absolute;
      top: -15px;
    }
  }

  @media ${theme.devices.tablet} {
    .close {
      right: -10px;
    }

    .videoContainer {
      max-width: 854px;
    }

    .closeIcon {
      width: 48px;
      height: 48px;
    }

    .innerVideo {
      border: 3px solid ${theme.palette.primary.main};
    }
  }

  @media ${theme.devices.laptop} {
    overflow-x: hidden;
    overflow-y: hidden;

    .innerVideo {
      width: 849px;
      height: 480px;
    }
  }
`;
