/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { fromCDN, isMobileDevice } from "@assets/utils";
import {
  route,
  zIndex,
  language,
  getAppleCppLink,
  getGoogleCppLink,
} from "@common/constants";
import SVGComponent from "@modules/Brand/view/components/SVGComponent";
import type { NavControllerProps } from "@modules/Brand/controller/interface";
import { clearAccessToken } from "@modules/Brand/model";
import {
  authMenu,
  brandGameMenu,
  brandLogin,
  brandSalesMenu,
  brandSocial,
} from "@modules/Brand/model/static/MenuModel";
import {
  currentSectionState,
  SectionEnum,
  sectionSlideState,
} from "@modules/Brand/recoil/section";
import { useTranslation } from "react-i18next";
import MobileMenu from "./MobileMenu";
import LangDropdown from "./LangDropdown";
import Dropdown, { MenuItem } from "./DropDown";
import useDevice from "@hooks/useDevice";
import { useScrollTo } from "@hooks/useScrollTo";
import usePreloadImages from "@hooks/usePreloadImages";
import { AuthorizedState } from "@modules/Brand/recoil/isAuthorized";
import { MobileInfoState } from "@modules/Brand/recoil/mobileInfo";
import { CppIdState } from "@modules/Brand/recoil/cppid";

const Logo = fromCDN("brand/images/main_logo_low.png");
const MAIN_LOGO_JP = fromCDN("brand/images/logo_jp.png");
const MAIN_LOGO_TW = fromCDN("brand/images/logo_tw.png");
const MAIN_LOGO_TH = fromCDN("brand/images/logo_th.png");
const LoginIcon = fromCDN("brand/svg/login-act2.png");
const LogoutIcon = fromCDN("brand/svg/login-dact2.png");
const MobileMenuIcon = SVGComponent(fromCDN("brand/svg/mobile_menu.svg"));
const Check = fromCDN("brand/images/Check_32.png");
const Check_PC = fromCDN("brand/images/Check_16.png");

const Nav = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOn, setIsMobileMenuOn] = useState(false);
  const [twoDepth, setTwoDepth] = useState([false, false, false, false, false]);
  const sectionSlide = useRecoilValue(sectionSlideState);
  const [currentSection, setCurrentSection] =
    useRecoilState(currentSectionState);
  const isOutOfMain = useCallback(() => currentSection < 0, [currentSection]);
  const { fromLapTopL, fromTabletL, isTablet } = useDevice();
  const [langDropdown, setLangDropdown] = useState(false);
  const { scrollTo } = useScrollTo();
  const [isAuthorized, setIsAuthorized] = useRecoilState(AuthorizedState);
  const [mobileInfo, setMobileInfo] = useRecoilState(MobileInfoState);
  const [cppId, _] = useRecoilState(CppIdState);

  useEffect(() => {
    const isMobile = isMobileDevice.any();

    let obj: { isMobile: boolean; isIos?: boolean } = { isMobile };

    if (isMobile) {
      obj["isIos"] = isMobileDevice.iOS();
    }
    setMobileInfo(obj);
  }, []);

  // 모바일 메뉴 hidden 처리
  useEffect(() => {
    if (fromLapTopL) {
      setIsMobileMenuOn(false);
    }
  }, [isMobileMenuOn, fromLapTopL]);

  /* 모바일 메뉴 hidden 처리 */
  useEffect(() => {
    if (isMobileMenuOn && !fromLapTopL) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    if (fromLapTopL) {
      setIsMobileMenuOn(false);
      document.body.style.overflow = "hidden";
    }
  }, [isMobileMenuOn, fromLapTopL, fromTabletL]);

  const handleClickLogo = () => {
    if (location.pathname !== route.home) navigate(route.home);
    if (fromLapTopL) {
      setCurrentSection(0);
    } else {
      scrollTo({ to: 0 });
    }
  };

  const handleLink = (page: string) => {
    window.open(`/${page}`, "_blank");
  };

  const handleClick = (item: MenuItem) => () => {
    if (item.sectionMap) {
      if (location.pathname !== route.home && isOutOfMain()) {
        navigate(route.home);
      }
      setCurrentSection(item.sectionMap as number);
    }
    // 커뮤니티 클릭시 상단으로 이동하는 이슈로 주석 처리함
    // else {
    //   setCurrentSection(-1);
    // }

    switch (item.title) {
      case authMenu.mypage: {
        isAuthorized
          ? navigate(route.brand.myPage)
          : navigate(route.brand.loginPage);
        break;
      }
      case authMenu.login: {
        navigate(route.brand.loginPage);
        break;
      }
      case authMenu.logout: {
        clearAccessToken();
        navigate(route.home);
        navigate(0);
        break;
      }
    }

    setTwoDepth(twoDepth.map(() => false));
  };

  const showTwoDepth = (index: number) => {
    let newArr = [...twoDepth];
    for (let i = 0; i < newArr.length; i++) {
      if (index === i) {
        if (newArr[i] === true) {
          newArr[i] = false;
          break;
        }
        newArr[i] = true;
      } else {
        newArr[i] = false;
      }
    }
    setTwoDepth([...newArr]);
    // setTwoDepth(twoDepth.map((depth, idx) => (idx === index ? !depth : depth)));
  };

  return (
    <div className="navContainer">
      <h1 className="visuallyHidden">Wooparoo Odyssey ウパルオデッセイ</h1>
      <nav css={brandNavStyle}>
        <div className="brandNavInner">
          <img
            className="logo"
            alt="logo"
            src={
              i18n.language === "JP"
                ? MAIN_LOGO_JP
                : i18n.language === "TW"
                ? MAIN_LOGO_TW
                : i18n.language === "TH"
                ? MAIN_LOGO_TH
                : Logo
            }
            onClick={() => handleClickLogo()}
          />
          <ul
            className={
              i18n.language === "KR"
                ? "brandMenu"
                : `brandMenu ${i18n.language}BrandMenu`
            }
          >
            <li
              id="gameIntro"
              className={`intro ${
                (twoDepth[0] || sectionSlide === SectionEnum.MAIN) &&
                "highlight"
              } ${twoDepth[0] && "dropDownOpen"}`}
              onClick={() => {
                showTwoDepth(0);
              }}
            >
              {t("brand:gnb:nav1")}
              {twoDepth[0] && (
                <Dropdown
                  handleClick={handleClick}
                  MenuItems={brandGameMenu(t)}
                  ClassName={`depthMenu ${i18n.language}depthMenu`}
                  twoDepth={twoDepth}
                  setTwoDepth={setTwoDepth}
                />
              )}
            </li>
            {/* <li
              className={`pre ${
                sectionSlide === SectionEnum.PRE_REGISTER && "highlight"
              }`}
              onClick={() => {
                isOutOfMain() && navigate(route.home);
                setCurrentSection(5);
              }}
            >
              {t("brand:gnb:nav2")}
            </li> */}
            {/* 글로벌 블록체인 버전 - 시작 */}
            {/* 
            <li
              className={`pne ${
                sectionSlide === SectionEnum.PNE && "highlight"
              }`}
              onClick={() => {
                isOutOfMain() && navigate(route.home);
                setCurrentSection(9);
              }}
            >
              {t("brand:gnb:nav3")}
            </li>

            <li
              id="presale"
              className={`intro ${
                (twoDepth[1] || sectionSlide === SectionEnum.PRE_SALE) &&
                "highlight"
              } ${twoDepth[1] && "dropDownOpen"}`}
              onClick={() => {
                showTwoDepth(1);
              }}
            >
              {t("brand:gnb:nav4")}{" "}
              {twoDepth[1] && (
                <Dropdown
                  handleClick={handleClick}
                  MenuItems={brandSalesMenu(t)}
                  ClassName={`depthMenu ${i18n.language}depthMenu`}
                  twoDepth={twoDepth}
                  setTwoDepth={setTwoDepth}
                />
              )}
            </li>*/}
            <li className="outLinkIcon" onClick={() => handleLink("coupon")}>
              {t("brand:gnb:nav5")}{" "}
            </li>
            {/* <li
              className="outLinkIcon"
              onClick={() => handleLink("marketplace")}
            >
              {t("brand:gnb:nav6")}
            </li> */}
            {/* 글로벌 블록체인 버전 - 끝 */}
          </ul>

          <ul className="brandInfoMenu">
            <li
              id="community"
              className={`brandInfoMenuBox ${twoDepth[2] && "dropDownOpen"}`}
              onClick={() => {
                showTwoDepth(2);
              }}
            >
              {t("brand:gnb:nav15")}
              {twoDepth[2] && (
                <Dropdown
                  handleClick={handleClick}
                  MenuItems={
                    brandSocial[i18n.language as keyof typeof brandSocial]
                  }
                  ClassName={`depthMenu communityMenu ${i18n.language}CommuMenu`}
                  twoDepth={twoDepth}
                  setTwoDepth={setTwoDepth}
                />
              )}
            </li>
            <li
              id="langDrop"
              className={`brandInfoMenuBox ${twoDepth[3] && "dropDownOpen"}`}
              onClick={() => {
                setLangDropdown(true);
                showTwoDepth(3);
              }}
            >
              {i18n.language}
              {twoDepth[3] && (
                <LangDropdown
                  ClassName={`depthMenu langMenu `}
                  isOpen={langDropdown}
                  setOpenDropdown={setLangDropdown}
                  dropdownItems={language}
                  selectedItem={i18n.language}
                  showTwoDepth={showTwoDepth}
                  twoDepth={twoDepth}
                  setTwoDepth={setTwoDepth}
                />
              )}
            </li>
            {/* <li
              id="login"
              className="userIcon"
              onClick={() => {
                showTwoDepth(4);
              }}
            >
              <div className="account">
                <img
                  src={isAuthorized ? LoginIcon : LogoutIcon}
                  id="loginIcon"
                />
                {isAuthorized && <span className="redDot" />}
              </div>
              {twoDepth[4] && (
                <Dropdown
                  handleClick={handleClick}
                  MenuItems={brandLogin(isAuthorized)}
                  ClassName={"depthMenu langMenu logInMenu"}
                  twoDepth={twoDepth}
                  setTwoDepth={setTwoDepth}
                />
              )}
            </li> */}
          </ul>
          <div
            onClick={
              mobileInfo?.isMobile && mobileInfo.isIos
                ? () => window.open(getAppleCppLink(cppId))
                : mobileInfo?.isMobile && !mobileInfo.isIos
                ? () => window.open(getGoogleCppLink(cppId))
                : undefined
            }
            className={`storeBadge pre ${
              mobileInfo?.isMobile && mobileInfo.isIos
                ? "ios"
                : mobileInfo?.isMobile && !mobileInfo.isIos
                ? "nonIos"
                : ""
            } ${i18n.language}`}
          />
          <div
            className="brandMobileMenu"
            onClick={() => setIsMobileMenuOn(true)}
          >
            <MobileMenuIcon className={"mobileMenuIcon"} />
          </div>
        </div>
      </nav>
      <MobileMenu
        style={
          isMobileMenuOn ? "brandMobileMenu activeMenu" : "brandMobileMenu"
        }
        isAuthorized={isAuthorized}
        selectedSort={i18n.language}
        isMobileMenuOn={isMobileMenuOn}
        setIsMobileMenuOn={setIsMobileMenuOn}
      />
    </div>
  );
};

export default Nav;

const brandNavStyle = css`
  top: 0;
  width: 100vw;
  height: 60px;
  display: flex;
  position: fixed;
  background: rgba(28, 26, 39, 0.8);
  z-index: ${zIndex.filterPopup};
  justify-content: space-between;
  white-space: pre;

  & li {
    position: relative;
    display: inline-block;
  }

  & a {
    display: block;
  }

  .notActiveIcon {
    filter: invert(99%) sepia(10%) saturate(28%) hue-rotate(270deg)
      brightness(118%) contrast(91%);
  }

  .activeIcon {
    filter: invert(97%) sepia(67%) saturate(6234%) hue-rotate(332deg)
      brightness(100%) contrast(102%);
  }

  .activeMenu {
    transform: translateX(0%) !important;
  }

  .logo {
    width: 70px;
    margin-top: 10.91px;
    margin-left: 16px;
    cursor: pointer;
  }

  .account {
    width: 32px;
    height: 32px;
    position: relative;

    img {
      width: 32px;
      height: 32px;
    }

    .redDot {
      position: absolute;
      top: 4px;
      right: 0;
      background-color: ${theme.palette.error.main};
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
  }

  .login {
    background: url(${LoginIcon});
  }

  .logout {
    background: url(${LogoutIcon});
  }

  .brandMobileMenu {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  .brandInfoMenu,
  .brandMenu {
    display: none;
  }

  .mobileMenuIcon {
    width: 40px;
    margin: 10px 20px 10px 0;
  }

  .brandInfoMenuBox {
    color: ${theme.palette.common.white};
    ${theme.fonts.small2B};
    line-height: 20px;
    cursor: pointer;
    position: relative;
    &:after {
      content: "";
      width: 16px;
      height: 16px;
      display: block;
      position: relative;
      background: url(${fromCDN("brand/svg/intro_arrow_default.svg")}) no-repeat
        center center;
      right: -4px;
      transition: all 0.4s ease;
    }
    &.dropDownOpen {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  }

  .redDot {
  }

  .storeBadge {
    width: 90px;
    height: 27px;
    position: absolute;
    top: 16.55px;
    right: 68px;

    &.ios {
      background: url(${fromCDN("brand/svg/ios-mb.svg")}) center center
        no-repeat;
      background-size: contain;

      &.pre {
        background-image: url(${fromCDN("brand/svg/ios-pre-mb.svg")});

        &.JP {
          background-image: url(${fromCDN("brand/images/ios_live.png")});
        }
      }
    }

    &.nonIos {
      background: url(${fromCDN("brand/svg/google-mb.svg")}) center center
        no-repeat;
      background-size: contain;

      &.pre {
        background-image: url(${fromCDN("brand/svg/google-pre-mb.svg")});

        &.JP {
          background-image: url(${fromCDN("brand/images/aos_live.png")});
        }
      }
    }
  }

  @media ${theme.devices.tablet} {
    height: 80px;

    .logo {
      width: 90px;
      margin-top: 15.45px;
      margin-left: 30px;
    }

    .mobileMenuIcon {
      width: 48px;
      margin: 16px 30px 16px 0;
    }

    .storeBadge {
      width: 120px;
      height: 36px;
      top: 22px;
      right: 98px;
    }
  }

  @media ${theme.devices.laptopL} {
    justify-content: center;
    height: 80px;

    .brandMobileMenu,
    .storeBadge {
      display: none;
    }

    .logoContainer {
      float: left;
      margin-left: 79px;
    }

    .logo {
      width: 100px;
      margin: 0px;
    }

    .brandInfoMenuContainer {
      display: flex;
    }

    .brandNavInner {
      ${theme.mixins.displayCenter};
      justify-content: space-between;
      width: 1200px;

      .brandMenu {
        ${theme.mixins.displayCenter};
        margin: auto 195px auto 250px;
        gap: 70px;

        & li {
          color: ${theme.palette.grey[1]};
          ${theme.fonts.subCopyB};
          line-height: 26px;
          cursor: pointer;
          position: relative;
          height: 80px;
          display: flex;
          align-items: center;

          & a:hover {
            color: ${theme.palette.primary.main};
          }

          &:hover,
          &.highlight {
            color: ${theme.palette.primary.main};
          }

          &.intro {
            &:after {
              content: "";
              width: 16px;
              height: 16px;
              display: block;
              margin-left: 6px;
              background: url(${fromCDN("brand/svg/intro_arrow_default.svg")})
                no-repeat center center;
              position: relative;
              transition: transform 0.4s ease;
            }
            &:hover,
            &.highlight {
              &:after {
                background-image: url(${fromCDN(
                  "brand/svg/intro_arrow_hover.svg",
                )});
              }
            }
            &.dropDownOpen {
              &:after {
                transform: rotateZ(180deg);
              }
            }
          }

          &.outLinkIcon {
            display: flex;
            &:after {
              content: "";
              width: 16px;
              height: 16px;
              display: block;
              margin-left: 6px;
              position: relative;
              background: url(${fromCDN("brand/svg/out_link_white.svg")})
                no-repeat center center;
            }
            &:hover,
            &.highlight {
              &:after {
                background-image: url(${fromCDN(
                  "brand/svg/out_link_yellow.svg",
                )});
              }
            }
          }
        }
      }

      .brandInfoMenu {
        ${theme.mixins.displayCenter};
        gap: 24px;

        & li {
          height: 80px;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:nth-last-of-type(1) {
            margin-right: 0px;
          }
        }

        .communityMenu {
          width: 80px;
          left: -7px;

          & li {
            margin-bottom: 16px;
          }

          & li:nth-of-type(1) {
            padding-top: 16px;
          }

          & li:nth-last-of-type(1) {
            margin-bottom: 0px;
            padding-bottom: 16px;
          }

          & a {
            padding: 0;
          }
        }

        .logInMenu {
          width: 90px;
          left: -25px;

          & li:nth-of-type(1) {
            & a {
              padding: 20px 0px 0px 0px;
            }
          }

          & li:nth-last-of-type(1) {
            & a {
              padding: 24px 0px 20px 0px;
            }
          }
        }
      }

      .ENBrandMenu {
        gap: 50px;
        margin: auto 105px auto 205px;
      }

      .THBrandMenu {
        gap: 50px;
        margin: auto 105px auto 150px;
      }

      .JPBrandMenu {
        gap: 60px;
        margin: auto 160px auto 250px;
      }

      .TWBrandMenu {
        gap: 60px;
        margin: auto 170px auto 200px;
      }
    }

    .depthMenu {
      & li {
        height: unset !important;
        display: block !important;
      }
    }

    .ENdepthMenu {
      width: 160px;
      left: -5px;
    }

    #presale {
      .ENdepthMenu {
        width: 130px;
        left: -20px;
      }

      .THdepthMenu {
        left: -35px;
      }

      .TWdepthMenu {
        left: -45px;
      }
    }

    .JPdepthMenu,
    .THdepthMenu {
      width: 140px;
      white-space: pre-wrap;
    }

    .TWdepthMenu {
      width: 140px;
      white-space: pre-wrap;
      left: -25px;
    }

    .TWdepthMenu,
    .JPdepthMenu,
    .THdepthMenu,
    .ENdepthMenu {
      & a {
        padding: 12px !important;
      }

      & li:nth-of-type(1) {
        & a {
          padding-top: 20px !important;
        }
      }

      & li:nth-last-of-type(1) {
        & a {
          padding-bottom: 20px !important;
        }
      }
    }

    .ENCommuMenu,
    .JPCommuMenu {
      left: 5px !important;
    }

    .THCommuMenu,
    .TWCommuMenu {
      left: -15px !important;
    }
  }
`;
