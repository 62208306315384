import type React from "react";
import BrandController from "@modules/Brand/controller/BrandController";
import BrandView from "@modules/Brand/view";
import type { BrandControllerProps } from "@modules/Brand/controller/interface";
import Layout from "@modules/Brand/view/components/Layout/Layout";

function index() {
  return (
    <Layout>
      <BrandController>
        <BrandView {...({} as BrandControllerProps)} />
      </BrandController>
    </Layout>
  );
}

export default index;
