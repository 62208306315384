import { PRIVACY_POLICY_URL, TERMS_SERVICE_URL } from "@common/constants";

export const fromCDN = (pathname: string) => {
  return "https://wpd.cdn.toastoven.net/static/" + pathname;
};

// url에 있는 hash string 값을 전달받아 key-value 형태로 변환
export const getHashParams = (hash: string) => {
  const removeHash = hash.substring(1);
  const hashArr = removeHash.split("&");

  const hashParams: { [key: string]: string } = {};

  hashArr.forEach(e => {
    const pair = e.split("=");
    hashParams[pair[0]] = pair[1];
  });

  return hashParams;
};

export const convertToKoreaTime = () => {
  // 유저 PC에 설정된 시간대 기준으로 반환
  const curr = new Date();
  // 현재 사용자 PC 설정 시간대로부터 UTC 시간까지의 차이를  밀리초 단위로 리턴
  const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;
  // UTC 기준 한국 시차 밀리초 단위로 변환
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

  return new Date(utc + KR_TIME_DIFF);
};

export const getPopupLeftPosition = (width: number) => {
  return window.screenX + (window.outerWidth - width) / 2;
};

export const getPopupTopPosition = (height: number) => {
  return window.screenY + (window.outerHeight - height) / 2;
};

// 글자 마스킹 처리
// TODO : 게임 출시 후 마스킹 처리 제거
export const maskLastSixCharacters = (
  inputString: string,
  numToMask: number,
) => {
  if (inputString.length < numToMask) return inputString;
  if (numToMask <= 0) return inputString;

  const maskedPart = inputString
    .slice(0, -numToMask)
    .padEnd(inputString.length, "*");
  return maskedPart;
};

// 타임스탬프 날짜 변환
export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
};

// 유저 움직임 감지하여 토큰 만료 시키기
export const createTokenExpirationChecker = (
  onTokenExpired: () => void,
): NodeJS.Timeout => {
  let lastActivityTime = new Date();
  let timerId: NodeJS.Timeout | null = null;

  const handleUserActivity = () => {
    lastActivityTime = new Date();
  };

  const startTokenExpirationTimer = (): NodeJS.Timeout => {
    const expirationTime = 30 * 60 * 1000; // 30 minutes

    const events = [
      "mousemove",
      "keydown",
      "click",
      "scroll",
      "touchstart",
      "touchmove",
      "touchend",
    ];

    const cleanupListeners = () => {
      events.forEach(event => {
        window.removeEventListener(event, handleUserActivity);
      });
    };

    const checkExpiration = () => {
      const currentTime = new Date().getTime();
      if (currentTime - lastActivityTime.getTime() > expirationTime) {
        cleanupListeners(); // Remove event listeners when no longer needed
        onTokenExpired();
      } else {
        timerId = setTimeout(checkExpiration, expirationTime);
      }
    };

    // Add event listeners
    events.forEach(event => {
      window.addEventListener(event, handleUserActivity);
    });

    timerId = setTimeout(checkExpiration, expirationTime);
    return timerId;
  };

  return startTokenExpirationTimer();
};

export const openTermsOfServiceUrl = () => {
  window.open(TERMS_SERVICE_URL);
  return;
};

export const openPrivacyPolicyUrl = () => {
  window.open(PRIVACY_POLICY_URL);
  return;
};

export const isMobileDevice = {
  Android: function () {
    return /Android/i.test(navigator.userAgent);
  },
  BlackBerry: function () {
    return /BlackBerry/i.test(navigator.userAgent);
  },
  iOS: function () {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  },
  Opera: function () {
    return /Opera Mini/i.test(navigator.userAgent);
  },
  Windows: function () {
    return (
      /IEMobile/i.test(navigator.userAgent) ||
      /WPDesktop/i.test(navigator.userAgent)
    );
  },
  any: function () {
    return (
      isMobileDevice.Android() ||
      isMobileDevice.BlackBerry() ||
      isMobileDevice.iOS() ||
      isMobileDevice.Opera() ||
      isMobileDevice.Windows()
    );
  },
};
