/** @jsxImportSource @emotion/react */

import { css, keyframes } from "@emotion/react";
import theme from "@common/styles/theme";
import { fromCDN } from "@assets/utils";

const defaultIcon = fromCDN("brand/svg/moveTopIcon_default.svg");
const hoverIcon = fromCDN("brand/svg/moveTopIcon_hover.svg");

function MoveToTopButton({ clickHandler }: { clickHandler: () => void }) {
  return (
    <button onClick={clickHandler} css={[moveTopTopBtn()]}>
      <div />
    </button>
  );
}

export default MoveToTopButton;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(none);
  }
`;

const moveTopTopBtn = () => css`
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  border-radius: 10px !important;
  ${theme.mixins.displayCenter};
  animation: ${fadeIn} 0.8s ease-in-out;
  cursor: pointer;
  transition: all ease 0.5s 0s;
  div {
    width: 22px;
    height: 24px;
    background: url(${defaultIcon}) no-repeat center center;
  }
  &:active {
    ${theme.mixins.buttonPressEffect};
  }

  @media (min-width: 1024px) {
    width: 80px;
    height: 80px;

    div {
      width: 35px;
      height: 38px;
    }
  }

  @media ${theme.devices.laptop} {
    width: 50px;
    height: 50px;

    div {
      width: 22px;
      height: 24px;
    }
    &:hover {
      background: #f4f4f4 !important;
      div {
        background: url(${hoverIcon}) no-repeat center center;
      }
    }
  }
`;
