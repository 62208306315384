/** @jsxImportSource @emotion/react */
import theme from "@common/styles/theme";
import { fromCDN } from "@assets/utils";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";
import { MobileInfoState } from "@modules/Brand/recoil/mobileInfo";
import { CppIdState } from "@modules/Brand/recoil/cppid";
import { getAppleCppLink, getGoogleCppLink } from "@common/constants";
import { useTranslation } from "react-i18next";

const PreRegisterList = () => {
  const [mobileInfo] = useRecoilState(MobileInfoState);
  const [cppId] = useRecoilState(CppIdState);
  const { i18n } = useTranslation();

  return (
    <PreRegisterIconList
      className={`preList ${
        mobileInfo?.isMobile && mobileInfo.isIos
          ? "ios"
          : mobileInfo?.isMobile && !mobileInfo.isIos
          ? "nonIos"
          : ""
      }`}
    >
      <li
        onClick={() => window.open(getGoogleCppLink(cppId))}
        className={`google ${i18n.language}`}
      ></li>
      <li
        onClick={() => window.open(getAppleCppLink(cppId))}
        className={`apple ${i18n.language}`}
      ></li>
    </PreRegisterIconList>
  );
};

export default PreRegisterList;

const PreRegisterIconList = styled.ul`
  padding: 0 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;

  li {
    width: 152px;
    height: 45px;
    cursor: pointer;

    &:active {
      ${theme.mixins.buttonPressEffect};
    }

    &.google {
      background: url(${fromCDN("brand/svg/google-mb.svg")}) center center
        no-repeat;
      background-size: contain;

      &.pre {
        background-image: url(${fromCDN("brand/svg/google-pre-mb.svg")});
      }

      &.JP {
        background-image: url(${fromCDN("brand/images/aos_live.png")});
      }
    }

    &.apple {
      background: url(${fromCDN("brand/svg/ios-mb.svg")}) center center
        no-repeat;
      background-size: contain;

      &.pre {
        background-image: url(${fromCDN("brand/svg/ios-pre-mb.svg")});
      }

      &.JP {
        background-image: url(${fromCDN("brand/images/ios_live.png")});
      }
    }
  }

  &.ios {
    li.google {
      display: none;
    }
  }

  &.nonIos {
    li.apple {
      display: none;
    }
  }

  @media ${theme.devices.tablet} {
    gap: 10px;

    li {
      width: 184px;
      height: 55px;

      div {
        &.google {
          background-image: url(${fromCDN("brand/svg/google-pc.svg")});

          &.pre {
            background-image: url(${fromCDN("brand/svg/google-pre-pc.svg")});
          }
        }

        &.apple {
          background-image: url(${fromCDN("brand/svg/ios-pc.svg")});

          &.pre {
            background-image: url(${fromCDN("brand/svg/ios-pre-pc.svg")});
          }
        }
      }
    }
  }

  @media ${theme.devices.laptop} {
    &.ios,
    &.nonIos {
      li.google,
      li.apple {
        display: block;
      }
    }
  }
`;
