import useStatus from "@hooks/useStatus";

export default function useShareURL() {
  const { data, isSuccess } = useStatus();
  const searchParams = new URLSearchParams();
  const queryKey = "shareKey";
  let shareKey = "";

  if (isSuccess) {
    shareKey += data?.result?.shareKey;
  }
  searchParams.append(queryKey, shareKey);

  const url = `${window.location.origin}${
    window.location.pathname
  }?${searchParams.toString()}`;

  return { url, queryKey };
}
