import { atom } from "recoil";

export enum SectionEnum {
  MAIN = "MAIN",
  PRE_REGISTER = "PRE_REGISTER",
  PNE = "PNE",
  PRE_SALE = "PRE_SALE",
}

export const sectionMapState = atom({
  key: "SectionMapState",
  default: new Map([
    [0, SectionEnum.MAIN], // 인트로 영상
    [1, SectionEnum.MAIN], // 우파루
    [2, SectionEnum.MAIN], // 게임소개
    [3, SectionEnum.MAIN], // 시놉시스
    [4, SectionEnum.MAIN], // 배틀
    [5, SectionEnum.PRE_REGISTER], // 사전등록혜택
    [6, SectionEnum.PRE_REGISTER], // 혜택 1
    [7, SectionEnum.PRE_REGISTER], // 혜택 2
    [8, SectionEnum.PRE_REGISTER], // 주의사항
    [9, SectionEnum.PNE], // PNE
    [10, SectionEnum.PRE_SALE], // 사전판매 메인
    [11, SectionEnum.PRE_SALE], //1차 사전판매
    [12, SectionEnum.PRE_SALE], //2차 사전판매
    [13, SectionEnum.PRE_SALE], //3차 사전판매
    [14, SectionEnum.PRE_SALE], //갤럭시 우파루 소개 슬라이드1
    [15, SectionEnum.PRE_SALE], //갤럭시 우파루 소개 슬라이드2
    [16, SectionEnum.PRE_SALE], //갤럭시 우파루 소개 슬라이드3
    [17, SectionEnum.PRE_SALE], //갤럭시 우파루 소개 슬라이드4
    [18, SectionEnum.PRE_SALE], //갤럭시 우파루 소개 슬라이드5
    [19, SectionEnum.PRE_SALE], //갤럭시 우파루 소개 슬라이드6
  ]),
});

export const currentSectionState = atom<number>({
  key: "CurrentSectionState",
  default: 0,
});
