/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { zIndex } from "@common/constants";
import SocialShareInfo from "./SocialShareInfo";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  isRetrying: boolean; //재시도 중인 경우 true, 일반 뽑기일 경우 false
  id?: string;
  confirm?: () => void;
};

function DrawOutOfTicketsPopup({ isRetrying, confirm }: Props) {
  const { t } = useTranslation();

  return (
    <div css={style}>
      <div className="contents popupContainer">
        <div className="info">
          {isRetrying ? (
            <p>{t("brand:사전등록_이벤트:register_e52")}</p>
          ) : (
            <p>
              <Trans componenents={{ strong: <strong /> }}>
                {t("brand:사전등록_이벤트:register_e58")}
              </Trans>
            </p>
          )}
          <button onClick={() => confirm && confirm()}>
            {t("brand:사전등록_이벤트:register_e36")}
          </button>
        </div>

        <SocialShareInfo />
      </div>
    </div>
  );
}

export default DrawOutOfTicketsPopup;

const style = css`
  width: 100vw;
  height: 100vh;
  background-color: ${theme.palette.action.disabledBackground};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.dimmer};

  .contents {
    width: 312px;
    min-height: 260px;
    padding: 40px 0;
    background-color: ${theme.palette.common.white};
    border-radius: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .info {
    padding: 0 26px;
    margin-bottom: 30px;

    p {
      margin-bottom: 30px;
      color: ${theme.palette.grey[9]};
      ${theme.fonts.buttonB};
      line-height: 29px;
      white-space: pre-wrap;

      strong {
        color: ${theme.palette.error.main};
      }
    }
  }

  button {
    width: 150px;
    height: 50px;
    color: ${theme.palette.grey[9]};
    ${theme.fonts.small1B};
    line-height: 24px;
    background-color: ${theme.palette.primary.main};
    border-radius: 10px;
    border: none;
    cursor: pointer;

    &:active {
      ${theme.mixins.buttonPressEffect};
    }
  }
`;
