import { useTranslation } from "react-i18next";

export default function ConfirmButton({
  handleClick,
}: {
  handleClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <button className="button" onClick={handleClick}>
      {t("brand:한국_사전등록_프로세스:preregi_k9")}
    </button>
  );
}
