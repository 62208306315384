import { Model } from "@common/apis";
import type { RawAxiosRequestHeaders } from "axios";
import type {
  CouponRegisterCompleteResult,
  CouponRegisterParam,
} from "./interface/coupon.interface";

export class CouponRegisterModel extends Model {
  private readonly url: string;
  private readonly commonModelConfig: {
    config: { headers: RawAxiosRequestHeaders };
  };

  constructor() {
    super();
    this.url = "/coupon";
    this.commonModelConfig = {
      config: {
        headers: {
          ...this.urlencodedContentType(),
        },
      },
    };
  }

  public register = async (data: CouponRegisterParam) => {
    const response = await this.post<CouponRegisterCompleteResult>(
      this.url,
      data,
      this.commonModelConfig,
    );

    return {
      ...response.data,
      header: response.data.header,
      result: response.data.result,
    };
  };
}

export default function getCouponRegisterModel() {
  return new CouponRegisterModel();
}
