import { useTranslation } from "react-i18next";

export default function CancelButton({
  handleClick,
}: {
  handleClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <button className="button left" onClick={handleClick}>
      {t("brand:사전등록_이벤트:register_e35")}
    </button>
  );
}
