/** @jsxImportSource @emotion/react */
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { fromCDN } from "@assets/utils";
import theme from "@common/styles/theme";
import { Swiper, SwiperSlide } from "swiper/react";
import { cards } from "@modules/Brand/model/static/CardImage";
import { EffectCards, Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/autoplay";
import { useTranslation } from "react-i18next";
import useDevice from "@hooks/useDevice";
import { wooparoosTranslation } from "@common/constants";
import { useInView } from "react-intersection-observer";

const effect = fromCDN("brand/images/effect.png");
const left = fromCDN("brand/svg/left_button.svg");
const leftHover = fromCDN("brand/svg/left_hover.svg");

const WooparooView = forwardRef(props => {
  const { t, i18n } = useTranslation();
  const { fromTablet, isMobile } = useDevice();
  const swiperRef = useRef<any>(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (inView) {
        swiperRef.current.swiper.autoplay.start();
      } else {
        swiperRef.current.swiper.autoplay.stop();
      }
    }
  }, [inView]);

  return (
    <section ref={ref} css={wooparoo(i18n.language)}>
      <h2>{t("brand:게임소개:intro1")}</h2>
      <p>{t(`brand:게임소개:intro2:${fromTablet ? "tb" : "mb"}`)}</p>
      <div className="Container">
        <Swiper
          ref={swiperRef}
          loop
          navigation
          observer={true}
          observeParents={true}
          watchSlidesProgress={true}
          loopedSlides={1}
          initialSlide={0}
          effect={"cards"}
          grabCursor={true}
          centeredSlides={true}
          loopAdditionalSlides={2}
          pagination={{ clickable: true }}
          modules={[Navigation, EffectCards, Pagination, Autoplay]}
          autoplay={{
            disableOnInteraction: false,
          }}
          cardsEffect={{
            perSlideOffset: 10,
            rotate: true,
            perSlideRotate: 10,
          }}
          onSlideChange={(e: any) => {
            let current = e.activeIndex;
            let prev = e.activeIndex - 1;
            let prevPrev = e.activeIndex - 2;
            let next = e.activeIndex + 1;
            let nextNext = e.activeIndex + 2;

            for (let i = 0; i < e.slides.length; i++) {
              e.slides[i].style.opacity = 0;
              if (
                current === i ||
                prev === i ||
                prevPrev === i ||
                next === i ||
                nextNext === i
              ) {
                e.slides[i].style.opacity = 1;
              }
            }
          }}
        >
          <span className="bgBox" />

          {cards.map((card, i) => {
            const translation =
              wooparoosTranslation[
                card.name as keyof typeof wooparoosTranslation
              ];
            return (
              <SwiperSlide key={i}>
                {({ isActive, isNext, isPrev }) => (
                  <div
                    className={
                      isActive ? "imageWrap isActiveRotate" : "imageWrap"
                    }
                  >
                    <div className="attributes frontVisible">
                      {card.attr.map(a => {
                        return (
                          <img
                            key={a}
                            src={a}
                            alt={a}
                            className="attr"
                            loading="lazy"
                          />
                        );
                      })}
                    </div>
                    <p className="cardName frontVisible">
                      {translation[i18n.language as keyof typeof translation]}
                    </p>
                    <img
                      src={card.back}
                      alt="카드"
                      className="back"
                      loading="lazy"
                    />
                    <img
                      src={card.front}
                      alt={card.front}
                      className="front frontVisible"
                      loading="lazy"
                    />

                    {isActive || isNext || isPrev ? (
                      <img
                        // src={
                        //   supportsWebP
                        //     ? isMobile
                        //       ? card.character_mb_webp
                        //       : card.character_webp
                        //     : isMobile
                        //     ? card.character_mb
                        //     : card.character
                        // }
                        src={isMobile ? card.character_mb : card.character}
                        alt={card.front}
                        className={`front character ${card.className} frontVisible`}
                        loading="lazy"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </SwiperSlide>
            );
          })}
          <div></div>
        </Swiper>
      </div>
    </section>
  );
});

export default WooparooView;

const wooparoo = (lang: string) => css`
  width: 100%;
  ${theme.mixins.displayCenter};
  flex-direction: column;
  text-align: center;
  color: ${theme.palette.common.white};
  padding: 0px;
  margin-bottom: 80px;
  z-index: 21 !important;
  position: relative;

  & h2 {
    font-style: normal;
    font-size: clamp(36px, 10vw, 48px);
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 80px;
    ${theme.applyGangwonEduFont[lang as keyof typeof theme.applyGangwonEduFont]}
  }

  & p {
    ${theme.fonts.small1B};
    line-height: 24px;
    width: ${lang === "EN" && "100%"};
    padding: ${lang === "EN" && "0 24px"};
    max-width: ${lang === "EN" && "480px"};
    white-space: ${lang === "EN" ? "unset" : "pre"};
  }

  & img {
    width: 148px;
    height: 199px;
    position: absolute;
    backface-visibility: hidden;
  }

  .pc {
    display: none;
  }

  .Container {
    padding-top: 48px;
    z-index: 10;
    padding: 0px;
    max-width: 100%;
    min-width: 360px;
  }

  .bgBox {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${effect}) no-repeat;
    background-position-y: 44%;
    background-position-x: 58%;
    background-size: 130%;
    min-width: 486px;
    top: -4%;
    padding-right: 5px;
  }

  .swiper {
    min-height: 200px;
    width: 100%;
    height: 100%;
    padding: 11% 0;
    position: relative;
    top: -13%;
    min-height: 340px;
    display: flex;
    justify-content: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-slide {
    display: flex;
    width: 100px;
    align-items: center;
    justify-content: center;
    perspective: 1000px;
    position: relative;
    opacity: 0;
  }

  .swiper-slide-active,
  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 1;
    z-index: 21 !important;
  }

  .swiper-slide-shadow {
    display: none;
  }

  .swiper-pagination {
  }

  .swiper-pagination .swiper-pagination-bullet {
    background: white;
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: ${theme.palette.primary.main} !important;
    width: 30px !important;
    border-radius: 5px;
  }

  .imageWrap {
    min-width: 148px;
    min-height: 199px;
    display: flex;
    position: relative;
    transform-style: preserve-3d;
    transition: 0.5s;
    width: 30%;
    max-width: 258px;
    height: auto !important;
  }

  .imageWrap .frontVisible {
    transform: rotateY(-180deg);
  }

  .imageWrap .attributes {
    display: none;
  }

  .isActiveRotate .attributes {
    height: 26px;
    display: flex;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 49%;
    transform: translateX(-50%) rotateY(-180deg);

    .attr {
      width: 24px;
      height: 26px;
      position: relative;
      backface-visibility: visible;
    }
  }

  .imageWrap .cardName {
    display: none;
  }

  .isActiveRotate .cardName {
    display: block;
    ${theme.fonts.small1B}
    line-height: 24px;
    color: ${theme.palette.primary.main};
    z-index: 3;
    position: absolute;
    top: 36px;
    left: 49%;
    transform: translateX(-50%) rotateY(-180deg);
  }

  .imageWrap .back {
    z-index: 3;
    width: 100%;
    height: auto;
    height: intrinsic;
    position: relative !important;
  }

  .imageWrap .front {
    width: 100%;
    height: auto;
  }

  .isActiveRotate {
    transform: rotateY(180deg);
  }

  .swiper-slide .swiper-slide-visible .swiper-slide-active .imageWrap .back {
    z-index: 0;
  }

  .character {
    left: 22px;
    width: 70% !important;
    top: 25% !important;

    &.irene {
      width: 100% !important;
      top: 10% !important;
      left: 0 !important;
    }

    &.cupinGIF {
      width: 100% !important;
      top: -3% !important;
      left: -2% !important;
    }
  }

  .opacity {
    opacity: 1 !important;
  }

  .gifSize {
    width: 94% !important;
    top: 13% !important;
    left: 5px !important;
  }

  @media ${theme.devices.tablet} {
    height: 1050px;

    & h2 {
      font-size: 60px;
      line-height: 62px;
      margin-bottom: 10px;
    }

    & p {
      margin-bottom: 0;
      ${theme.fonts.subCopyB};
      line-height: 26px;
      padding: ${lang === "EN" && "0"};
      max-width: ${lang === "EN" && "unset"};
      white-space: ${lang === "EN" && "pre"};
    }

    & br {
      display: none;
    }

    & img {
      width: 258px;
      height: 347px;
    }

    .pc {
      display: block;
    }

    .Container {
      padding: 0;
      padding-bottom: 50px;
      max-width: unset;
    }

    .bgBox {
      background-size: 120%;
    }

    .swiper {
      width: 1000px;
      height: 600px;
      position: relative;
      top: 10px;
      padding: unset;
    }

    .swiper-slide {
      width: 258px;
    }

    .imageWrap {
      width: 258px;
      height: 347px;
    }

    .imageWrap .attributes {
      .attr {
        width: 41px;
        height: 44.5px;
      }
    }

    .imageWrap .cardName {
      font-size: 24px;
      line-height: 35px;
      top: 55px;
    }

    .character {
      top: 110px;
      left: 37px !important;
    }

    .gifSize {
      top: 8% !important;
      width: 260px !important;
      height: 260px !important;
      left: -17px !important;
    }
    .cupinGIF {
      left: 13px !important;
    }

    .swiper-pagination {
      bottom: 0;
    }

    .swiper-pagination .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
    }

    .swiper-pagination-bullet-active {
      border-radius: 7px;
    }
  }

  @media ${theme.devices.laptop} {
    height: 100vh;
    padding: 0;

    & h2 {
      margin-top: 0;
    }

    & p {
      ${theme.fonts.subCopyB};
    }

    .Container {
      width: 1320px;
      height: 700px;
      padding-top: 100px;
      margin-top: -100px;
    }

    .gifSize {
      width: 250px !important;
      height: 250px !important;
      top: 33px !important;
      left: -6px !important;
    }

    .swiper {
      top: -30px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 100px;
      height: 100px;
      display: block;
      background-size: cover;
      background: url(${left});
      background-repeat: no-repeat;
      z-index: 21 !important;

      &:hover {
        background: url(${leftHover});
        background-size: cover;
        background-repeat: no-repeat;
        cursor: hover;
      }
    }

    .swiper-button-next {
      transform: rotate(180deg);
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none;
    }

    .swiper-pagination {
      bottom: 20px;
    }
  }

  @media ${theme.devices.laptop} and (max-height: 800px) {
    h2 {
      margin-top: 4%;
    }

    .Container {
      background-size: 80%;
      background-position: center -75px;
      margin-top: -80px;
      padding: 70px 70px 100px;
    }
    .swiper {
      height: 500px;
    }

    .bgBox {
      background-size: 100%;
      background-position: center -50px;
    }

    .swiper-slide {
      width: 218px;
    }

    .imageWrap {
      width: 218px;
      height: 307px;
    }

    img {
      width: 218px;
      height: 307px;
    }

    .character {
      width: 130px;
      height: 130px;
      top: 100px;
      left: 40px;
    }

    .swiper-pagination {
      bottom: 20px;
    }

    .swiper-button-next {
      right: 30px;
    }
    .swiper-button-prev {
      left: 30px;
    }
  }
`;
