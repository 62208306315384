import { fromCDN } from "@assets/utils";

// 게임소개 - 슬라이드 영상
const Banner1 = fromCDN("brand/video/banner1_low.mp4");
const Banner2 = fromCDN("brand/video/banner2_low.mp4");
const Banner3 = fromCDN("brand/video/banner3_low.mp4");

export const banners = [
  {
    banner: Banner1,
    poster: fromCDN("brand/images/banner1_p.jpg"),
  },
  {
    banner: Banner2,
    poster: fromCDN("brand/images/banner2_p.jpg"),
  },
  {
    banner: Banner3,
    poster: fromCDN("brand/images/banner3_p.jpg"),
  },
];

// 게임소개 - 소환 슬라이드 이미지
const Intro1Image1 = fromCDN("brand/images/game_intro_1_1_low.png");
const Intro1Image2 = fromCDN("brand/images/game_intro_1_2_low.png");
const Intro1Image3 = fromCDN("brand/images/game_intro_1_3_low.png");
const Effect01 = fromCDN("brand/images/effect01.png");
const Effect02 = fromCDN("brand/images/effect02.png");
export const firstSliderImages = [
  {
    image: Intro1Image1,
  },
  {
    image: Intro1Image2,
  },
  {
    image: Intro1Image3,
  },
  {
    image: Effect01,
  },
  {
    image: Effect02,
  },
];

// 게임소개 - 육성 슬라이드 이미지
const Intro2Image1 = fromCDN("brand/images/game_intro_2_1_low.png");
const Intro2Image2 = fromCDN("brand/images/game_intro_2_2_low.png");
const Intro2Image3 = fromCDN("brand/images/game_intro_2_3_low.png");
const Snow01 = fromCDN("brand/images/snowflake.png");
const Snow02 = fromCDN("brand/images/snowflake2.png");

export const secondSliderImages = [
  {
    image: Intro2Image1,
    className: "active2_1",
  },
  {
    image: Intro2Image2,
    className: "active2_2",
  },
  {
    image: Intro2Image3,
    className: "active2_3",
  },
  {
    image: Snow01,
    className: "effect1_1",
  },
  {
    image: Snow02,
    className: "effect1_2",
  },
];

// 게임소개 - 마을 슬라이드 이미지
const Intro3Image1 = fromCDN("brand/images/game_intro_3_1_low.png");
const Intro3Image2 = fromCDN("brand/images/game_intro_3_2_low.png");
const Intro3Image3 = fromCDN("brand/images/game_intro_3_3_low.png");
const Effect3_1 = fromCDN("brand/images/effect3_1.png");
const Effect3_2 = fromCDN("brand/images/effect3_2.png");
const Effect3_3 = fromCDN("brand/images/effect3_3.png");

export const thirdSliderImages = [
  {
    image: Intro3Image1,
    className: "active3_1",
  },
  {
    image: Intro3Image2,
    className: "active3_2",
  },
  {
    image: Intro3Image3,
    className: "active3_3",
  },
  {
    image: Effect3_1,
    className: "effect3_1",
  },
  {
    image: Effect3_2,
    className: "effect3_2",
  },
  {
    image: Effect3_3,
    className: "effect3_3",
  },
];

// 게임소개 - 구름 이미지 - tablet 이상
const Cloud01 = fromCDN("brand/images/cloud01.png");
const Cloud02 = fromCDN("brand/images/cloud.png");
export const clouds = [Cloud01, Cloud02];

// 게임소개 - 구름 이미지 - 모바일
const Cloud01_M = fromCDN("brand/images/cloud01_m.png");
const Cloud02_M = fromCDN("brand/images/cloud_m.png");
export const clouds_m = [Cloud01_M, Cloud02_M];
