/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useRef, useState } from "react";
import { fromCDN } from "@assets/utils";
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";
import { isTablet } from "react-device-detect";
import useDevice from "@hooks/useDevice";
import useResize from "@hooks/useResize";
import useIntersectionObserver from "@hooks/useIntersectionObserver";
import { useTranslation } from "react-i18next";

const SlideShadow = fromCDN("brand/images/SlideShadow.png");
const Synopsis1 = fromCDN("brand/images/synopsis1_low.png");
const Synopsis2 = fromCDN("brand/images/synopsis2_low.png");
const Synopsis3 = fromCDN("brand/images/synopsis3_low.png");
const Synopsis4 = fromCDN("brand/images/synopsis4_low.png");
const Synopsis5 = fromCDN("brand/images/synopsis5_low.png");
const SliderHandle = fromCDN("brand/svg/slider_handle.svg");
const SliderHandleHover = fromCDN("brand/svg/slider_handle_hover.svg");

const images = [Synopsis1, Synopsis2, Synopsis3, Synopsis4, Synopsis5];

const SynopsisView = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [timer, setTimer] = useState(true);
  const { fromLapTop, fromTablet, device } = useDevice();
  const [currentSection, setCurrentSection] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const previous = useCallback(() => sliderRef?.current?.slickPrev(), []);
  const next = useCallback(() => sliderRef?.current?.slickNext(), []);
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIntersectionObserver(ref);
  const { width } = useResize();
  const [handleAutoPlay, setHandleAutoPlay] = useState(false);
  const { t, i18n } = useTranslation();

  var myTime: any;

  const handlGoToSlideSection = (index: any) => {
    sliderRef?.current?.slickGoTo(index);
  };

  const handleSlide = (currentButton: string) => {
    setTimer(false);

    if (currentSection === 4 && currentButton === "nextButton") {
      handlGoToSlideSection(0);
    }

    if (currentSection === 0 && currentButton === "prevButton") {
      handlGoToSlideSection(4);
    }

    if (currentSection !== 4 && currentButton === "nextButton") {
      next();
    }

    if (currentSection !== 0 && currentButton === "prevButton") {
      previous();
    }
  };

  useEffect(() => {
    if (fromLapTop) {
      const prevButton = document.getElementById("prevButton");
      const nextButton = document.getElementById("nextButton");

      if (currentSlide === 0) {
        prevButton?.classList.add("buttonOpacity");
      } else if (currentSlide === 4) {
        nextButton?.classList.add("buttonOpacity");
      }
    }
  }, [fromLapTop, currentSlide]);

  useEffect(() => {
    if (isInViewport) {
      setHandleAutoPlay(true);
      sliderRef?.current?.slickPlay();
    }
  }, [isInViewport, width, setHandleAutoPlay]);

  const settings: Settings = {
    className: "slider center variable-width ",
    centerPadding: "0px",
    variableWidth: true,
    // adaptiveHeight: true,
    touchMove: true,
    swipeToSlide: true,
    draggable: true,
    swipe: true,
    centerMode: true,
    dots: true,
    infinite: false,
    vertical: false,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, current) => {
      setCurrentSlide(current);
    },
    afterChange: current => {
      setCurrentSection(current);
      setTimer(true);

      if (current === 4 && timer) {
        myTime = setTimeout(function () {
          handlGoToSlideSection(0);
        }, 2500);
      } else {
        clearTimeout(myTime);
      }
    },
    prevArrow: (
      <ArrowButton type="PREV" id="prevButton" handleSlide={handleSlide} />
    ),
    nextArrow: (
      <ArrowButton type="NEXT" id="nextButton" handleSlide={handleSlide} />
    ),
  };

  return (
    <section
      css={
        isTablet
          ? [synopsisStyle(i18n.language), synopsisStyleTB]
          : synopsisStyle(i18n.language)
      }
      ref={ref}
    >
      <h2>{t("brand:게임소개:intro10")}</h2>
      <p>{t(`brand:게임소개:intro11:${fromTablet ? "tb" : "mb"}`)}</p>
      <div css={style}>
        <Slider
          {...settings}
          ref={sliderRef}
          pauseOnHover={false}
          autoplay={handleAutoPlay}
        >
          {images.map((i, idx) => {
            return (
              <img
                key={`${idx}_${i}`}
                src={`${i}`}
                alt="게임스크린샷"
                className="gifsize"
              />
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

const ArrowButton = ({
  currentSlide,
  slideCount,
  type,
  handleSlide,
  ...props
}: Record<string, any> & { type: "PREV" | "NEXT" }) => {
  const getSrc = () => {
    if (type === "PREV" || type === "NEXT") return SliderHandle;
    return "";
  };

  return (
    <div
      {...props}
      onClick={e => {
        let currentButton = e.currentTarget.id;
        handleSlide(currentButton);
      }}
    />
  );
};

export default SynopsisView;

const synopsisStyle = (lang: string) => css`
  text-align: center;
  color: ${theme.palette.common.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20% 0 0 0;
  min-height: 700px;

  #prevButton,
  #nextButton {
    background: url(${SliderHandle}) no-repeat;
    background-size: contain;
  }
  #prevButton:before,
  #nextButton:before {
    display: none;
  }
  #prevButton:hover,
  #nextButton:hover {
    background: url(${SliderHandleHover}) no-repeat;
    background-size: contain;
  }
  h2 {
    font-style: normal;
    font-size: clamp(36px, 10vw, 48px);
    line-height: 1;
    margin-bottom: 10px;
    ${theme.applyGangwonEduFont[lang as keyof typeof theme.applyGangwonEduFont]}
  }
  p {
    padding: ${lang === "EN" && "0 24px"};
    width: ${lang === "EN" && "100%"};
    max-width: ${lang === "EN" && "580px"};
    ${theme.fonts.small1B};
    line-height: 24px;
    margin: 0 auto 70px;
    white-space: ${lang === "EN" ? "unset" : "pre"};
  }
  .buttonOpacity {
    opacity: 0.2;
  }

  @media ${theme.devices.tablet} {
    height: 1050px;
    padding: 0;
    min-height: unset;

    h2 {
      font-size: 60px;
      line-height: 62px;
    }
    p {
      max-width: ${lang === "EN" && "unset"};
      padding: ${lang === "EN" && "0"};
      ${theme.fonts.subCopyB};
      line-height: 26px;
      margin-bottom: 30px;
      white-space: ${lang === "EN" && "pre"};
    }
    br {
      display: none;
    }
    .tb {
      display: block;
    }
  }

  @media ${theme.devices.laptop} {
    height: 100vh;
    padding: 0;
  }

  @media ${theme.devices.laptop} and (max-height: 800px) {
    h2 {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
    }
    .slick-track {
      padding: 30px 20px;
      margin-left: -20px;
      margin-top: -30px;
    }
    .slick-dots {
    }
  }
  @media ${theme.devices.laptop} {
    p {
      ${theme.fonts.subCopyB};
    }
  }
`;

const style = css`
  .slick-list {
    position: relative;
    display: block !important;
    width: 100%;
  }

  .slick-slide {
    padding: 0 4px;
    transition: transform 0.3s ease-in-out;
    width: 16.2% !important;
    min-width: 250px;

    & div {
      width: auto;
    }
  }

  .slick-dots {
    bottom: 115px;
    li {
      margin: 0 2px;
      width: unset;
      height: unset;
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        background-color: ${theme.palette.common.white};
        border-radius: 50%;
        transition: all ease 0.5s 0s;
        &:before {
          display: none;
        }
      }
      &.slick-active {
        button {
          width: 30px;
          height: 10px;
          background-color: ${theme.palette.primary.main};
          border-radius: 30px;
        }
      }
    }
  }
  .slick-list {
    padding-bottom: 150px !important;
  }
  .slick-current {
    position: relative;
  }
  .slick-current::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: -66px;
    left: 0;
    background: url(${SlideShadow});
    background-size: 100% 40px;
    background-repeat: no-repeat;
    opacity: 0.7;
  }
  .gifsize {
    width: 100% !important;
  }
  .slick-prev,
  .slick-next {
    display: none;
  }

  @media ${theme.devices.tablet} {
    .slick-slider {
      max-width: 100vw !important;
    }
    .slick-slide {
      margin-right: 0;
      padding: 0 30px;

      width: unset !important;
      min-width: unset;

      & div {
        width: auto;
      }
    }
    .slick-list {
      width: unset;
      position: unset;
      height: 500px;
      padding-top: 40px !important;
    }
    .gifsize {
      width: 500px !important;
      height: 282px;
    }
    .slick-current::after {
      width: 600px;
      height: 50px;
      left: 20px;
      background-size: 530px 46px;
      bottom: -90px;
    }
    .slick-slide.slick-current {
      transform: scale(1.2);
      position: relative;
      z-index: 1;
    }
    .slick-dots {
      bottom: 70px;
      z-index: 10;
      li {
        margin: 0 4px;
        button {
          width: 14px;
          height: 14px;
        }
        &.slick-active {
          button {
            width: 44px;
            height: 14px;
          }
        }
      }
    }
  }
  @media ${theme.devices.laptop} {
    .slick-track {
      margin-left: -20px;
      padding: 40px 20px 120px 20px;
      position: relative;
      top: -40px;
    }
    .slick-current::after {
      bottom: -80px;
    }
  }
  @media ${theme.devices.laptop} {
    .slick-track {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: -60px;
      padding: 40px 0;
      position: relative;
      top: -40px;
    }
    .slick-current {
      margin: 0 60px !important;
    }
    .slick-current::after {
      width: 600px;
      height: 50px;
      left: 5px;
      background-size: 510px 46px;
      bottom: -90px;
    }
    .slick-slide {
      margin-right: 0;
      padding: 0 20px;
    }
    .slick-dots {
      bottom: 70px;
    }
    .gifsize {
      width: 480px !important;
      height: 270px;
    }
    .slick-slide.slick-current {
      transform: scale(1.25);
      position: relative;
      z-index: 1;
    }
    .slick-prev,
    .slick-next {
      width: 100px;
      height: 100px;
      z-index: 1;
    }
    .slick-prev {
      left: calc(50vw - 310px);
      top: 180px;
      transform: translate(-100%, -50%);
    }
    .slick-next {
      left: calc(50vw + 310px);
      top: 180px;
      transform: translate(0, -50%) rotate(180deg);
    }
  }
  @media ${theme.devices.laptop} and (max-height: 800px) {
    padding-bottom: 80px;

    .slick-track {
      top: 0px;
    }
    .slick-list {
      height: 380px;
      padding-top: 40px !important;
    }
    .slick-dots {
      bottom: -10px;
    }
  }
`;

const synopsisStyleTB = css`
  #prevButton:hover,
  #nextButton:hover {
    background: url(${SliderHandle}) no-repeat;
    background-size: contain;
  }

  @media ${theme.devices.laptop} {
    height: -webkit-fill-available;
  }
`;
