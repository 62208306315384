/** @jsxImportSource @emotion/react */

import type { FC, ReactElement } from "react";
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { zIndex } from "@common/constants";
import SVGComponent from "@modules/Brand/view/components/SVGComponent";
import { fromCDN } from "@assets/utils";

const Check = SVGComponent(fromCDN("coupon/svg/check_gray7.svg"));

type Props = {
  isOpen: boolean;
  closeDropDown: () => void;
  dropdownItems: string[];
  selectedItem: string;
  selectItem: (item: string) => void;
  className?: string;
};

const renderOnCond = (cond: boolean) => (Component: ReactElement) =>
  cond ? Component : null;

//쿠폰페이지에 적용되는 언어 선택 드랍다운
const Dropdown: FC<Props> = ({
  isOpen,
  closeDropDown,
  dropdownItems,
  selectedItem,
  selectItem,
  className,
}) => {
  const renderIfOpen = renderOnCond(isOpen);
  const renderCheck = (item: string) => renderOnCond(item === selectedItem);

  return renderIfOpen(
    <ul css={style} className={className}>
      {dropdownItems.map(item => {
        const renderIfChecked = renderCheck(item);

        return (
          <li
            key={item}
            onClick={() => {
              selectItem(item);
              closeDropDown();
            }}
          >
            {item}
            <span className={`${item === selectedItem ? "show" : ""}`}>
              <Check />
            </span>
          </li>
        );
      })}
    </ul>,
  );
};

export default Dropdown;

const style = css`
  width: 100%;
  max-height: 250px;
  padding: 8px 0;
  margin-top: 20px;
  position: absolute;
  right: 0;
  background-color: ${theme.palette.common.white};
  border-radius: 10px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.15);
  z-index: ${zIndex.dropdown};
  user-select: none;

  & li {
    display: flex;
    line-height: 20px;
    padding: 17px 10px;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
    ${theme.fonts.small2B};
    justify-content: space-between;
    color: ${theme.palette.grey[7]};
    border-bottom: 1px solid ${theme.palette.grey[3]};

    &:last-of-type {
      border-bottom: none;
    }

    span {
      display: none;
      margin-bottom: 0;
    }

    span.show {
      display: block;
    }
  }

  & li:hover {
    background: ${theme.palette.grey[1]};
  }
`;
