import { FC, useEffect, useState } from "react";
import { cloneElement, useCallback } from "react";
import { useRecoilState } from "recoil";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createTokenExpirationChecker,
  getPopupLeftPosition,
  getPopupTopPosition,
} from "@common/assets/utils";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
// import FacebookSdk, { ShareParam } from "@modules/Brand/model/facebook-sdk";

import {
  PreRegistrationStatusResult,
  SocialPlatform,
} from "@modules/Brand/model";
// import getPreSaleModel, {
//   getCurrentPreSalePhase,
//   getXsollaPaymentUiUri,
//   isPersonPurchasable,
//   isValidInventory,
//   isValidPreSalePeriod,
// } from "@modules/Brand/model/PreSaleModel";
import { getPreRegistrationModel } from "@modules/Brand/model";
import { queryKeys } from "@modules/Brand/model/queryKeys";
import { popupState } from "@modules/Brand/recoil/popup";
import type { Wooparoo } from "@modules/Market/model";
import { clearAccessToken } from "@modules/Brand/model";
import type { BrandControllerParam, BrandControllerProps } from "./interface";
import { errorCode } from "@modules/Brand/model/interface/common.interface";
// import { PreSaleHandlerParam } from "@modules/Brand/model/interface/preSale.interface";

import useDrawPopups from "@hooks/useDrawPopups";
import useStatus from "@hooks/useStatus";
import useShareURL from "@hooks/useShareURL";
// import usePreSalePopups from "@hooks/usePreSalePopups";
// import useApiErorr from "@hooks/useApiError";
// import useCheckUserIP from "@hooks/useCheckUserIP";
// import usePreSaleItems from "@hooks/usePreSaleItems";
import { useLoginPopups } from "@hooks/useLoginPopups";
import { preregistState } from "@modules/Brand/recoil/preregister";
import { AuthorizedState } from "@modules/Brand/recoil/isAuthorized";
// import { presalePhaseState } from "@modules/Brand/recoil/presalePhase";
import { usePreRegistrationPopups } from "@hooks/usePreRegistrationPopups";
import { KOREA_BRAND_URL, SHARE_KEY } from "@common/constants";
// import { useTranslation } from "react-i18next";
import useCopyToClipboard from "@hooks/useCopyToClipboard";
// import useDevice from "@hooks/useDevice";
// import { useNavigate } from "react-router-dom";
import useScreenOrientation from "@hooks/useScreenOrientation";
import {
  clearShareKey,
  getShareKey,
  setShareKey,
} from "../model/PreRegistrationModel";
import { CppIdState } from "../recoil/cppid";

const BrandController: FC<BrandControllerParam> = ({ children }) => {
  // const { queryKey } = useShareURL();
  // const {
  //   data: statusData,
  //   refetch: refetchStatus,
  //   isAuthorized,
  // } = useStatus();
  const [popups, setPopups] = useRecoilState(popupState);
  const clearPopups = () => setPopups([]);
  const closePopupById = (id: string) =>
    setPopups(popups.filter(popup => popup.props.id !== id));
  const LoginPopups = useLoginPopups({ closePopupById, clearPopups });
  const [isAuthorize, setIsAuthorized] = useRecoilState(AuthorizedState);
  const { isRequiredRotate } = useScreenOrientation();
  const [_, setCppIdState] = useRecoilState(CppIdState);
  // const { isKorea } = useCheckUserIP();
  const getCppIdFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("cppid") ?? "";
  };

  /**
   * [공통] 한국 IP 접속한 경우 한국 브랜드 사이트로 리다이렉트
   */
  // useEffect(() => {
  //   if (isKorea) {
  //     window.location.href = KOREA_BRAND_URL;
  //     return;
  //   }
  // }, [isKorea]);

  /**
   * [공통] 광고 매체 경유로 접속한 경우 cppid 값에 매체명이 할당된다.
   */
  useEffect(() => {
    const cppId = getCppIdFromUrl();
    setCppIdState(cppId);
  }, []);

  /**
   *
   * [공통] 유저 로그인 상태 체크
   */
  // const checkUserLoginStatus = async () => {
  //   // try {
  //   const statusResult = await refetchStatus();

  //   const statusCode = statusResult.data?.header.resultCode;

  //   const isTokenError = [
  //     errorCode.INVALID_TOKEN,
  //     errorCode.EXPIRED_TOKEN,
  //   ].includes(statusCode!);

  //   if (isNil(statusResult?.data)) {
  //     return false;
  //   }

  //   // 로그인 안되어 있는 경우
  //   //!사전판매시 주석해제
  //   // if (!isAuthorized) {
  //   //   setPopups([<PreSalePopups.NotLogin />]);
  //   //   return false;
  //   // }

  //   // 재로그인 필요한 경우
  //   if (isAuthorized && isTokenError) {
  //     setPopups([
  //       <LoginPopups.IPChangedPopup
  //         clearPopups={clearPopups}
  //         confirm={() => {
  //           clearAccessToken();
  //           setIsAuthorized(false);
  //         }}
  //       />,
  //     ]);
  //     return false;
  //   } else if (!isAuthorized && isTokenError) {
  //     return false;
  //   }

  //   return true;
  //   // } catch (error: any) {
  //   //   console.log(error);
  //   //   return false;
  //   // }
  // };

  // useEffect(() => {
  //   // 유저 움직임 없을 경우 토큰 만료 시키기
  //   const handleTokenExpiration = () => {
  //     clearAccessToken();
  //     setIsAuthorized(false);
  //   };

  //   let timerId: NodeJS.Timeout | null = null;

  //   const startTokenExpirationTimer = () => {
  //     if (!timerId) {
  //       timerId = createTokenExpirationChecker(handleTokenExpiration);
  //     }
  //   };

  //   const clearTokenExpirationTimer = () => {
  //     if (timerId) {
  //       clearTimeout(timerId);
  //       timerId = null;
  //     }
  //   };

  //   if (isAuthorized) {
  //     startTokenExpirationTimer();
  //   }

  //   // 컴포넌트가 언마운트될 때 타이머 정리
  //   return () => {
  //     clearTokenExpirationTimer();
  //   };
  // }, [isAuthorized]);

  /**
   *
   *  [사전등록] 관련 작업
   */
  const { wooparooInfo } = queryKeys.preRegistration;
  const preRegistrationModel = getPreRegistrationModel();
  // const { data: wooparooListInfoData } = useQuery({
  //   queryKey: wooparooInfo.queryKey,
  //   queryFn: preRegistrationModel.getWooparooListInfo,
  //   refetchOnWindowFocus: false,
  // });

  const { mutateAsync: mutateRegisterAsync } = useMutation({
    mutationFn: preRegistrationModel.register,
  });
  const { mutateAsync: mutateDrawAsync } = useMutation({
    mutationFn: preRegistrationModel.draw,
  });
  const { mutate: mutateDrawComplete } = useMutation({
    mutationFn: preRegistrationModel.drawComplete,
    onSuccess: res => {
      if (res.header.isSuccessful) {
        setPopups([<DrawPopups.DrawCompletePopup clearPopups={clearPopups} />]);
      } else {
        setPopups([<PreRegisterPopups.CommonErrorPopup />]);
      }
    },
  });
  // const isUserRegistered = statusData?.result?.registered;
  // const userDrawCount = statusData?.result?.drawCnt;
  const [preregister, setPreregister] = useRecoilState(preregistState);
  const [isRegistered, setIsRegistered] = useState(false);
  const PreRegisterPopups = usePreRegistrationPopups({ closePopupById });
  const DrawPopups = useDrawPopups({ closePopupById, clearPopups });

  // const getShareKeyFromURL = () => {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   return searchParams.get(queryKey) ?? "";
  // };
  // const currentShareKey = getShareKeyFromURL();

  const handleUnload = () => {
    setPreregister(false);
  };

  // useEffect(() => {
  //   // 사전버튼 클릭 후 페이지 이동 후 로그인을 진행하지 않았을 경우 사전등록버튼 히스토리 제거
  //   if (!isAuthorized) {
  //     handleUnload();
  //   }

  //   // 이미 사전등록이 완료된 유저는 사전등록버튼 비활성화
  //   if (isAuthorized && isUserRegistered) {
  //     setIsRegistered(true);
  //   }

  //   // 페이지 새로고침시 사전등록버튼 히스토리 제거
  //   window.onbeforeunload = handleUnload;
  //   return () => {
  //     window.onbeforeunload = null;
  //   };
  // }, [isAuthorized, isUserRegistered]);
  // useEffect(() => {
  //   if (preregister) {
  //     preRegister();
  //   }
  // }, [preregister]);

  // useEffect(() => {
  //   // share key를 통해서 사이트 접속했을 경우 key 저장
  //   if (currentShareKey) {
  //     setShareKey(currentShareKey);
  //   }
  // }, []);

  /**
   *
   * [사전등록] 사전등록하기
   */
  // const preRegister = useCallback(async () => {
  //   const userIsLogined = await checkUserLoginStatus();

  //   if (!userIsLogined) {
  //     return setPopups([<PreRegisterPopups.LoginPopup />]);
  //   }

  //   const handleConsentApproval = async () => {
  //     const shareKey = getShareKey();
  //     const registerResult = await mutateRegisterAsync({
  //       shareKey: shareKey,
  //     });

  //     // 현재는 사전등록 event 기간이 아님!! (알수없는 에러로 처리)
  //     if (!registerResult?.header.isSuccessful) {
  //       return setPopups([<PreRegisterPopups.CommonErrorPopup />]);
  //     }

  //     // handleUnload();
  //     setIsRegistered(true);
  //     clearShareKey();
  //     setPopups([<PreRegisterPopups.RegistrationCompletePopup />]);
  //   };

  //   const ConsentPopupComponent = PreRegisterPopups.ConsentPopupGlobal;

  //   setPopups([
  //     <ConsentPopupComponent approveConsent={handleConsentApproval} />,
  //   ]);
  // }, [popups]);

  /**
   *
   * [사전등록] 우파루 다시 뽑기 및 뽑은 우파루 확정
   */
  // const draw = async () => {
  //   const apiDrawResult = await mutateDrawAsync();
  //   const statusResult = await refetchStatus();
  //   const userIsLogined = await checkUserLoginStatus();
  //   if (!userIsLogined) {
  //     return setPopups([<DrawPopups.LoginPopup />]);
  //   }
  //   if (isNil(wooparooListInfoData)) {
  //     return setPopups([<PreRegisterPopups.CommonErrorPopup />]);
  //   }
  //   if (isNil(apiDrawResult) || isEmpty(apiDrawResult)) {
  //     return setPopups([<PreRegisterPopups.CommonErrorPopup />]);
  //   }
  //   const { drawCnt } = (statusResult.data as PreRegistrationStatusResult)
  //     .result;
  //   const wooparooId = apiDrawResult?.result?.wooparooId;
  //   const wooparooInfo = wooparooListInfoData?.result?.find(
  //     it => it.wooparooId === wooparooId,
  //   );

  //   setPopups([
  //     <DrawPopups.DrawResultPopup
  //       remainTimes={drawCnt}
  //       wooparooInfo={wooparooInfo as Partial<Wooparoo>}
  //       confirm={() => {
  //         mutateDrawComplete({ wooparooId });
  //       }}
  //       retry={() => {
  //         if (drawCnt <= 0) {
  //           setPopups([<DrawPopups.DrawOutOfTicketsPopup isRetrying={true} />]);
  //           return;
  //         }
  //         draw();
  //       }}
  //     />,
  //   ]);
  // };

  /**
   *
   * [사전등록] 우파루 뽑기
   */
  // const drawWooparoo = useCallback(async () => {
  //   const userIsLogined = await checkUserLoginStatus();
  //   const statusResult = await refetchStatus();

  //   //뽑기 -> 미로그인
  //   if (!userIsLogined) {
  //     return setPopups([<DrawPopups.LoginPopup />]);
  //   }

  //   const { drawGacha, registered, drawCnt } = (
  //     statusResult.data as PreRegistrationStatusResult
  //   )?.result;
  //   const outOfTicket = !isNil(drawCnt) && drawCnt <= 0;

  //   //뽑기 -> 사전등록 미등록
  //   if (!registered) {
  //     setPopups([
  //       <DrawPopups.NotRegisteredPopup
  //         confirm={() => {
  //           preRegister();
  //         }}
  //       />,
  //     ]);
  //     return;
  //   }

  //   //뽑기 횟수가 다 소진된 경우
  //   if (outOfTicket) {
  //     setPopups([<DrawPopups.DrawOutOfTicketsPopup isRetrying={false} />]);
  //     return;
  //   }

  //   //뽑기한 적이 없는 유저인 경우 1회 차감 안내 팝업 노출 후 뽑기 진행
  //   if (isNil(drawGacha)) {
  //     setPopups([<DrawPopups.DrawConfirmPopup confirm={() => draw()} />]);
  //     return;
  //   }

  //   const wooparooId = drawGacha?.gachaId;
  //   const wooparooInfo = wooparooListInfoData?.result?.find(
  //     it => it.wooparooId === wooparooId,
  //   );

  //   setPopups([
  //     <DrawPopups.DrawResultPopup
  //       remainTimes={drawCnt}
  //       wooparooInfo={wooparooInfo as Partial<Wooparoo>}
  //       confirm={() => {
  //         mutateDrawComplete({ wooparooId });
  //       }}
  //       retry={() => {
  //         if (drawCnt <= 0) {
  //           setPopups([<DrawPopups.DrawOutOfTicketsPopup isRetrying={true} />]);
  //           return;
  //         }
  //         draw();
  //       }}
  //     />,
  //   ]);
  // }, [isAuthorized, popups, isUserRegistered, userDrawCount, draw]);

  /**
   *
   * [사전판매] 관련 부분
   * ! 사전판매시 주석해제
   */
  // const PreSalePopups = usePreSalePopups({ closePopupById, clearPopups });
  // const [, setCurrentPhaseTab] = useRecoilState(presalePhaseState);
  // const [invoiceId, setInvoiceId] = useState("");
  // const [orderDetail, setOrderDetail] = useState<ItemOrderInfo | null>(null);
  // const preSaleModel = getPreSaleModel();
  // const { mutateAsync: mutatePaymentToken } = useMutation({
  //   mutationFn: preSaleModel.createOrder,
  // });
  // const { order } = queryKeys.preSale;
  // useQuery({
  //   queryKey: [...order.queryKey, invoiceId],
  //   queryFn: async () => await preSaleModel.getOrderDetail(invoiceId),
  //   refetchOnWindowFocus: false,
  //   onSuccess: data => {
  //     if (!data.header.isSuccessful) {
  //       return handleError(data.header.resultCode);
  //     }
  //     setOrderDetail(data.result);
  //   },
  //   enabled: invoiceId !== "",
  // });
  // const preSaleItems = usePreSaleItems();

  //엑솔라 콜백 url에서 전달된 인보이스 아이디로 주문 상세 정보를 조회한다.
  // useEffect(() => {
  //   const handler = async (e: any) => {
  //     const invoiceId = e.data.xsollaInvoiceId;

  //     if (!invoiceId) return;

  //     setInvoiceId(invoiceId);
  //   };

  //   window.addEventListener("message", handler);
  // });

  // useEffect(() => {
  //   if (!orderDetail) return;

  //   const couponNumber = orderDetail.orderItemsList[0].cuponKey;
  //   const productType = orderDetail.productInfo.name;
  //   const wooparooName = orderDetail.productInfo.description;
  //   const wooparoos =
  //     wooparooTranslation[wooparooName as keyof typeof wooparooTranslation];

  //   if (productType === "갤럭시 크로스") {
  //     setPopups([
  //       <PreSalePopups.CompleteGalaxyCrossPurchasePopup
  //         couponNumber={couponNumber}
  //       />,
  //     ]);
  //   } else {
  //     setPopups([
  //       <PreSalePopups.CheckMysteryBoxWooparooPopup
  //         couponNumber={couponNumber}
  //         wooparoo={wooparoos[i18n.language as keyof typeof wooparoos]}
  //       />,
  //     ]);
  //   }

  //   setInvoiceId("");
  //   setOrderDetail(null);
  //   return;
  // }, [orderDetail]);

  // useEffect(() => {
  //   if (!preSaleItems || !fromLapTop) return;

  //   setCurrentPhaseTab(getCurrentPreSalePhase(preSaleItems));
  // }, [preSaleItems]);

  /**
   *
   * [사전판매] 사전판매 상품 엑솔라 결제팝업 띄우기
   */
  // const payment = async (itemSku: string) => {
  //   const order = await mutatePaymentToken(itemSku);

  //   if (!order.header.isSuccessful) {
  //     return handleError(order.header.resultCode);
  //   }

  //   const orderToken = order.result.token;
  //   const xsollaPaymentUrl = getXsollaPaymentUiUri(orderToken);

  //   const width = 980;
  //   const height = 670;
  //   const left = getPopupLeftPosition(width);
  //   const top = getPopupTopPosition(height);

  //   try {
  //     window.open(
  //       xsollaPaymentUrl,
  //       undefined,
  //       `width=${width}, height=${height}, left=${left}, top=${top}`,
  //     );
  //   } catch (err) {
  //     return handleError(-1);
  //   }
  // };

  /**
   *
   * [사전판매] 사전판매 상품 구매하기
   */
  // const preSale = async ({ productType, itemSku }: PreSaleHandlerParam) => {
  //   if (!preSaleItems) return;
  //   const userIsLogined = await checkUserLoginStatus();
  //   if (!userIsLogined) return;

  //   const item = preSaleItems!.filter(i => i.charaId === itemSku);
  //   const { nowRealInventory, personCnt, startDate, endDate } = item[0];

  //   //판매 기간이 아닌 경우 > 미판매기간 안내 팝업
  //   if (!isValidPreSalePeriod(new Date(startDate), new Date(endDate))) {
  //     return setPopups([<PreSalePopups.NotPreSalePeriodPopup />]);
  //   }
  //   //재고가 0인 경우 재고부족 안내 팝업
  //   if (!isValidInventory(nowRealInventory)) {
  //     return setPopups([<PreSalePopups.SoldOutPopup />]);
  //   }
  //   //개인 구매 가능 갯수 초과한 경우 > 구매가능 갯수 초과 안내 팝업
  //   if (!isPersonPurchasable(personCnt) && personCnt !== -1) {
  //     return setPopups([<PreSalePopups.ExceedPurchableCount />]);
  //   }

  //   if (productType === "cross") {
  //     return setPopups([
  //       <PreSalePopups.GalaxyCrossPurchaseConfirmPopup
  //         payment={() => payment(itemSku)}
  //       />,
  //     ]);
  //   } else {
  //     return setPopups([
  //       <PreSalePopups.MysteryBoxPurchaseConfirmPopup
  //         payment={() => payment(itemSku)}
  //       />,
  //     ]);
  //   }
  // };

  const props: BrandControllerProps = {
    // preRegister,
    // drawWooparoo,
    // preSale,
    isRegistered,
    // preSaleItems,
    isRequiredRotate,
  };

  return cloneElement(children, props);
};

export default BrandController;
