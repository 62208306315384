// https://dev-wpd-api.hangame.com/swagger-ui.html#/SOCIAL_API

import { Model } from "@common/apis";
import type {
  AppleAuthParam,
  AuthApiResult,
  FacebookAuthParam,
  GoogleAuthParam,
  LineAuthParam,
  LoginTypeParam,
} from "./interface/auth.interface";
import { STORAGE_KEY } from "@common/constants";

const urlencodedContentType = {
  "content-type": "application/x-www-form-urlencoded",
};

const commonHeaders = {
  headers: { ...urlencodedContentType },
};

export class AuthModel extends Model {
  public apple = async (data: AppleAuthParam) => {
    const response = await this.get<AuthApiResult>("/social/apple/me", {
      config: { ...commonHeaders, params: data },
    });
    return response.data;
  };

  public facebook = async (data: FacebookAuthParam) => {
    const response = await this.get<AuthApiResult>("/social/facebook/me", {
      config: { ...commonHeaders, params: data },
    });
    return response.data;
  };

  public google = async (data: GoogleAuthParam) => {
    const response = await this.get<AuthApiResult>("/social/google/me", {
      config: { ...commonHeaders, params: data },
    });
    return response.data;
  };

  public line = async (data: LineAuthParam) => {
    const response = await this.get<AuthApiResult>("/social/line/me", {
      config: { ...commonHeaders, params: data },
    });
    return response.data;
  };

  public saveMail = async (data: LoginTypeParam) => {
    const response = await this.post<AuthApiResult>(
      `/social/${data.category}/saveMail`,
      data,
      {
        config: { ...commonHeaders },
        withToken: true,
      },
    );

    return response.data;
  };

  public checkTokenStatus = async () => {
    const response = await this.get<AuthApiResult>(`/pre-registration/status`, {
      config: { ...commonHeaders },
      withToken: true,
    });
    return response.data;
  };
}

export function setAccessToken(accessToken: string) {
  if (!window) return;
  window.localStorage.setItem(STORAGE_KEY, accessToken);
}

export function getAccessToken() {
  if (!window) return;
  return window.localStorage.getItem(STORAGE_KEY) as string;
}

export function clearAccessToken() {
  if (!window) return;
  return window.localStorage.removeItem(STORAGE_KEY);
}

export function getLineOauthUri() {
  const client_id = process.env.REACT_APP_LINE_CLIENT_ID ?? "1234567890";
  const redirect_uri = window.location.origin + "/auth/line";
  const state = randomString(6);
  const nonce = randomString(4);
  const scope = "profile%20openid%20email";

  return `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=${scope}&nonce=${nonce}`;
}

export function getAppleOauthUri() {
  const client_id = process.env.REACT_APP_APPLE_APPLICATION_ID;
  const redirect_uri = process.env.REACT_APP_APPLE_REDIRECT_URI;
  // const scope = "name email";
  const state = "state";
  const usePopup = false;

  return `https://appleid.apple.com/auth/authorize?response_type=code id_token&response_mode=fragment&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&usePopup=${usePopup}`;
}

let authModel: AuthModel;
export default function getAuthModel() {
  if (authModel) return authModel;
  return (authModel = new AuthModel());
}

function randomString(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const generateRandomCharacter = () =>
    characters.charAt(Math.floor(Math.random() * characters.length));
  return Array.apply(null, Array(length)).map(generateRandomCharacter).join("");
}

export function getGoogleOauthUri() {
  const client_id = process.env.REACT_APP_GOOGLE_APPLICATION_ID!;
  const redirect_uri = window.location.origin + "/auth/google";

  return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&scope=email`;
}
