/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { zIndex } from "@common/constants";
import { useState } from "react";
import { fromCDN } from "@assets/utils";
import { clearAccessToken } from "@modules/Brand/model";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { preregistState } from "@modules/Brand/recoil/preregister";
import useScrollLock from "@hooks/useScrollLock";

const Checked = fromCDN("brand/svg/checked.svg");

type Props = {
  id: string;
  closePopupById: (id: string) => void;
  agreed: () => void;
  clearPopups: () => void;
  cancel: () => void;
};

const LoginLocalPopup = ({
  id,
  closePopupById,
  agreed,
  clearPopups,
  cancel,
}: Props) => {
  const [agree, setAgree] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { t, i18n } = useTranslation();
  const [, setPreregister] = useRecoilState(preregistState);
  useScrollLock();

  const handleChange = () => {
    setAgree(prev => !prev);
  };

  // 개인정보보호 수집 동의
  const handleSubmit = () => {
    setShowWarning(!agree);
    if (!agree) return;
    agreed();
    closePopupById(id);
    clearPopups();
  };

  // 개인정보보호 수집 취소
  const handleCancel = () => {
    // cancel();
    localStorage.removeItem("registerClicked");
    setPreregister(false);
    closePopupById(id);
    clearAccessToken();
    clearPopups();
  };

  return (
    <div css={loginPopup} id={id}>
      <div className="popupInner popupContainer">
        <p>{t("brand:한국_로그인_프로세스:login_k2")}</p>
        <div className="textBox">
          <span>{t("brand:한국_로그인_프로세스:login_k3")}</span>
          <span>
            <Trans
              i18nKey="brand:한국_로그인_프로세스:login_k4"
              components={{ bold: <h4 /> }}
            />
          </span>
          <span>
            <Trans
              i18nKey="brand:한국_로그인_프로세스:login_k5"
              components={{ bold: <h4 /> }}
            />
          </span>
          <span>
            <Trans
              i18nKey="brand:한국_로그인_프로세스:login_k6"
              components={{ bold: <h4 />, string: <strong /> }}
            />
          </span>
          <span>{t("brand:한국_로그인_프로세스:login_k7")}</span>
        </div>
        <div className={`checkBox ${i18n.language}css`} onClick={handleChange}>
          <div>
            <input id="checkBox" type="checkbox" checked={agree} readOnly />
            <span className="label">
              {t("brand:한국_로그인_프로세스:login_k9")}
            </span>
          </div>
          {showWarning && (
            <span className="alert">
              {t("brand:한국_로그인_프로세스:login_k8")}
            </span>
          )}
        </div>
        <div className="buttonBox">
          <button onClick={handleCancel}>
            {t("brand:한국_로그인_프로세스:login_k11")}
          </button>
          <button onClick={handleSubmit}>
            {t("brand:한국_로그인_프로세스:login_k10")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginLocalPopup;

const loginPopup = css`
  display: felx;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: ${zIndex.sharePopUp};
  ${theme.mixins.displayCenter};
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px) brightness(0.5);
  overflow: hidden;

  .popupInner {
    width: 312px;
    min-height: 500px;
    padding: 40px 0;
    position: relative;
    z-index: 10000;
    background: #fff;
    border-radius: 30px;

    & p {
      margin: 0 30px 20px;
      ${theme.fonts.small2B};
      color: ${theme.palette.grey[9]};
      white-space: pre-wrap;
    }

    & .textBox {
      width: 252px;
      height: 174px;
      border-radius: 10px;
      background: ${theme.palette.grey[1]};
      padding: 10px;
      margin: 0 auto;
      overflow: auto;
      padding-right: 4px;

      & h4 {
        display: inline-block;
        ${theme.fonts.small3B};
        line-height: 17.38px;
      }

      & span {
        ${theme.fonts.small3R};
        display: block;
        line-height: 20.27px;

        &:nth-of-type(1) {
          margin-bottom: 8px;
        }

        &:nth-last-of-type(1) {
          margin-top: 8px;
        }
      }

      & strong {
        color: ${theme.palette.error.main};
        ${theme.fonts.small2B};
      }
    }

    .textBox::-webkit-scrollbar {
      width: 4px;
    }

    .textBox::-webkit-scrollbar-thumb {
      background: ${theme.palette.grey[3]};
      border-radius: 4px;
      width: 4px;
      height: 80px;
    }

    .checkBox {
      width: 252px;
      display: flex;
      flex-direction: column;
      ${theme.fonts.small2R};
      margin: 20px auto 45px;
      position: relative;
      cursor: pointer;

      div {
        display: flex;
      }

      & input[type="checkbox"] {
        appearance: none;
        width: 24px;
        height: 24px;
        background: ${theme.palette.grey[2]};
        background-size: cover;
        position: relative;
        border-radius: 4px;
        margin-right: 8px;
        top: 0px;

        &:checked {
          background: url(${Checked});
          background-size: cover;
        }
      }

      .label {
        width: 219px;
      }

      span {
        ${theme.fonts.small2B};
        display: inline-block;
        line-height: 20.27px;
      }

      .alert {
        width: 240px;
        margin-top: 0px;
        ${theme.fonts.small3B};
        color: ${theme.palette.error.main};
        line-height: 17.38px;
        position: absolute;
        bottom: -20px;
      }
    }

    .buttonBox {
      display: flex;
      flex-direction: row;
      justify-content: center;

      & button {
        color: ${theme.palette.grey[9]};
        width: 120px;
        height: 50px;
        border-radius: 10px;
        border: none;
        background: ${theme.palette.grey[1]};
        ${theme.fonts.small1B};
        line-height: 24px;
        margin-bottom: 0px;
      }

      & button:nth-of-type(2) {
        background: ${theme.palette.primary.main};
        margin-left: 10px;
      }
    }

    .ENcss,
    .THcss {
      .alert {
        bottom: -33px !important;
      }
    }
  }
`;
