import { useEffect, useState } from "react";
import { debounce } from "lodash";

export enum Device {
  MOBILE = "MOBILE",
  TABLET = "TABLET",
  TABLETL = "TABLETL",
  LAPTOP = "LAPTOP",
  LAPTOP_L = "LAPTOP_L",
  DESKTOP = "DESKTOP",
  DESKTOP_L = "DESKTOP_L",
}

export default function useDevice() {
  const getDevice = (width: number) => {
    if (2560 <= width) return Device.DESKTOP_L;
    if (1920 <= width) return Device.DESKTOP;
    if (1300 <= width) return Device.LAPTOP_L;
    if (1200 <= width) return Device.LAPTOP;
    if (980 <= width) return Device.TABLETL;
    if (720 <= width) return Device.TABLET;
    return Device.MOBILE;
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [device, setDevice] = useState<Device>(getDevice(window.innerWidth));

  useEffect(() => {
    if (!window) return;

    const handler = debounce(() => {
      setWindowWidth(window.innerWidth);
      setDevice(getDevice(window.innerWidth));
    }, 300);

    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, [windowWidth]);

  return {
    device,
    isMobile: device === Device.MOBILE,
    isTablet: device === Device.TABLET,
    isTabletL: device === Device.TABLETL,
    fromTablet: [
      Device.TABLET,
      Device.TABLETL,
      Device.LAPTOP,
      Device.LAPTOP_L,
      Device.DESKTOP,
      Device.DESKTOP_L,
    ].includes(device),
    fromTabletL: [
      Device.TABLETL,
      Device.LAPTOP,
      Device.LAPTOP_L,
      Device.DESKTOP,
      Device.DESKTOP_L,
    ].includes(device),
    fromLapTop: [
      Device.LAPTOP,
      Device.LAPTOP_L,
      Device.DESKTOP,
      Device.DESKTOP_L,
    ].includes(device),
    fromLapTopL: [Device.LAPTOP_L, Device.DESKTOP, Device.DESKTOP_L].includes(
      device,
    ),
  };
}
