import { useState, useEffect } from "react";

const getOrientation = () => {
  if (window.screen.orientation) {
    return window.screen.orientation.type;
  } else if (window.orientation !== undefined) {
    return window.orientation === 0 ? "portrait-primary" : "landscape-primary";
  }
  return "unknown";
};

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation());
  const isMobile =
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  const updateOrientation = () => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    window.addEventListener("resize", updateOrientation);

    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  return {
    isRequiredRotate:
      isMobile && orientation === "landscape-primary" ? true : false,
  };
};

export default useScreenOrientation;
