import { css } from "@emotion/react";
import { createTheme } from "@mui/material/styles";

/* Color System */
const palette = {
  /* primary */
  primary: {
    main: "#FFDD21",
  },
  /* secondary */
  secondary: {
    main: "#6A53F8",
    main2: "#A06AF5",
    main3: "#756CE1",
    main4: "#00049E",
  },
  /* gray scale */
  grey: {
    1: "#F4F4F4",
    2: "#E8E8E9",
    3: "#D6D6DA",
    4: "#BABAC6",
    5: "#9292A3",
    6: "#696978",
    7: "#434357",
    8: "#2F2F44",
    9: "#1C1A27",
    10: "#13111C",
  },
  /* common */
  common: {
    white: "#FFFFFF",
  },
  /* system status */
  success: {
    main: "#00A136",
  },
  error: {
    main: "#FF5A5A",
  },
  action: {
    disabledBackground: "rgba(11, 11, 16, 0.8)", // dimmed background color
  },
};

export const getDeviceSize = (bp: keyof typeof sizes): number => {
  return Number(sizes[bp].replace("px", ""));
};

/* Media Query */
const sizes = {
  mobile: "360px",
  tablet: "720px",
  tabletL: "980px",
  laptop: "1200px",
  laptopL: "1300px",
  desktop: "1920px",
  desktopL: "2560px",
};

const devices = {
  mobile: `(min-width: ${sizes.mobile})`,
  tablet: `(min-width: ${sizes.tablet})`,
  tabletL: `(min-width: ${sizes.tabletL})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
  desktopL: `(min-width: ${sizes.desktopL})`,
};

const resolutionHeight = {
  height1: "(max-height: 880px)",
  height2: "(max-height: 1000px)",
};

/* fonts */
const fonts = {
  small5R: css`
    font-size: 8px;
    font-weight: 400;
  `,
  small5B: css`
    font-size: 8px;
    font-weight: 700;
  `,
  small4R: css`
    font-size: 10px;
    font-weight: 400;
  `,
  small4B: css`
    font-size: 10px;
    font-weight: 700;
  `,
  small3R: css`
    font-size: 12px;
    font-weight: 400;
  `,
  small3B: css`
    font-size: 12px;
    font-weight: 700;
  `,
  small2R: css`
    font-size: 14px;
    font-weight: 400;
  `,
  small2B: css`
    font-size: 14px;
    font-weight: 700;
  `,
  small1R: css`
    font-size: 16px;
    font-weight: 400;
  `,
  small1B: css`
    font-size: 16px;
    font-weight: 700;
  `,
  subCopyR: css`
    font-size: 18px;
    font-weight: 400;
  `,
  subCopyB: css`
    font-size: 18px;
    font-weight: 700;
  `,
  buttonR: css`
    font-size: 20px;
    font-weight: 400;
  `,
  buttonB: css`
    font-size: 20px;
    font-weight: 700;
  `,
  subTitleR: css`
    font-size: 24px;
    font-weight: 400;
  `,
  subTitleB: css`
    font-size: 24px;
    font-weight: 700;
  `,
  subTitle2B: css`
    font-size: 24px;
    font-weight: 900;
  `,
  title2B: css`
    font-size: 36px;
    font-weight: 700;
  `,
  title1B: css`
    font-size: 44px;
    font-weight: 700;
  `,
  title1Bl: css`
    font-size: 44px;
    font-weight: 900;
  `,
};

const displayCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const buttonPressEffect = css`
  transform: translate3d(0px, 2px, 0px);
`;

const buttonHoverEfect = css`
  position: relative;

  &:after {
    content: "";
    width: 200px;
    height: 50px;
    position: absolute;
    border-radius: inherit;
    z-index: -1;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover:after {
    content: "";
    opacity: 1;
  }
`;

const removeScrollBar = css`
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const applyGangwonEduFont = {
  KR: css`
    font-family: "GangwonEduPower";
    font-weight: 400;
  `,
  EN: css`
    font-family: "GangwonEduPower";
    font-weight: 400;
  `,
  JP: css`
    font-family: "GenJyuuGothic", "Wooparoo Sans Serif";
    font-weight: 700;
  `,
  TH: css`
    font-family: "Wooparoo SansTH Serif";
    font-weight: 700;
  `,
  TW: css`
    font-family: "Wooparoo SansSC Serif";
    font-weight: 700;
  `,
};

const theme = createTheme({
  palette,
  fonts,
  mixins: {
    displayCenter,
    buttonPressEffect,
    buttonHoverEfect,
    removeScrollBar,
  },
  devices,
  applyGangwonEduFont,
  resolutionHeight,
});

export default theme;
