import { useTranslation, Trans } from "react-i18next";
import { Wooparoo } from "@modules/Market/model";
import Popup from "@modules/Brand/view/components/NewPopup";
import PopupTitle from "@modules/Brand/view/components/PopupTitle";
import PopupButton from "@modules/Brand/view/components/PopupButton";
import CancelButton from "@modules/Brand/view/components/CancelButton";
import ConfirmButton from "@modules/Brand/view/components/ConfirmButton";
import PopupDesc from "@modules/Brand/view/components/PopupDesc";
import DrawOutOfTicketsPopup from "@modules/Brand/view/PreReservation/DrawOutOfTicketsPopup";
import DrawResultPopup from "@modules/Brand/view/PreReservation/DrawResultPopup";
import useScrollLock from "@hooks/useScrollLock";
import { useNavigate } from "react-router-dom";
import { route } from "@common/constants";

export default function useDrawPopups({
  closePopupById,
  clearPopups,
}: {
  closePopupById: (id: string) => void;
  clearPopups: () => void;
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /* 미로그인 상태일 경우 팝업 */
  const LoginPopup = () => {
    const id = "draw-login-popup-id";
    useScrollLock();

    return (
      <Popup id={id} className={"popupContainer drawLoginPopup"}>
        <PopupTitle>
          <p>{t("brand:사전등록_이벤트:register_e34")}</p>
        </PopupTitle>
        <PopupButton count="double">
          <CancelButton handleClick={() => closePopupById(id)} />
          <ConfirmButton
            handleClick={() => {
              navigate(route.brand.loginPage);
              closePopupById(id);
            }}
          />
        </PopupButton>
      </Popup>
    );
  };

  /* 사전등록이 안되어 있는 유저의 경우 팝업 */
  const NotRegisteredPopup = ({ confirm }: { confirm: () => void }) => {
    const id = "not-registered-popup-id";
    useScrollLock();

    return (
      <Popup id={id} className={"not-regist-popup popupContainer"}>
        <PopupTitle>
          <p>
            <Trans components={{ span: <span /> }}>
              {t("brand:사전등록_이벤트:register_e56")}
            </Trans>
          </p>
        </PopupTitle>
        <PopupDesc>
          <span>{t("brand:사전등록_이벤트:register_e57")}</span>
        </PopupDesc>
        <PopupButton count="single">
          <div className={i18n.language === "EN" ? "ENText" : ""}>
            <ConfirmButton
              handleClick={() => {
                closePopupById(id);
                confirm();
              }}
            />
          </div>
        </PopupButton>
      </Popup>
    );
  };

  /* 뽑기 팝업 */
  const DrawConfirmPopup = ({ confirm }: { confirm: () => void }) => {
    const id = "draw-confirm-popup";
    useScrollLock();

    return (
      <Popup id={id} className={`${id} popupContainer`}>
        <PopupTitle>
          <p>
            <Trans components={{ span: <span /> }}>
              {t("brand:사전등록_이벤트:register_e37")}
            </Trans>
          </p>
        </PopupTitle>

        <PopupButton count="double">
          <CancelButton handleClick={() => clearPopups()} />
          <ConfirmButton
            handleClick={() => {
              closePopupById(id);
              confirm();
            }}
          />
        </PopupButton>
      </Popup>
    );
  };

  /* 소유한 뽑기권을 모두 소진하였을 경우 팝업 */
  const DrawOutOfTickets = ({ isRetrying }: { isRetrying: boolean }) => {
    const id = "draw-out-of-tickets-popup";
    useScrollLock();

    return (
      <DrawOutOfTicketsPopup
        id={id}
        isRetrying={isRetrying}
        confirm={() => clearPopups()}
      />
    );
  };

  /* 뽑기 결과 팝업 */
  const DrawResult = ({
    wooparooInfo,
    remainTimes,
    retry,
    confirm,
  }: {
    wooparooInfo: Partial<Wooparoo>;
    remainTimes: number;
    retry: () => void;
    confirm: () => void;
  }) => {
    const id = "draw result-popup";
    useScrollLock();

    return (
      <DrawResultPopup
        id={id}
        remainTimes={remainTimes}
        wooparoo={wooparooInfo}
        close={() => closePopupById(id)}
        confirm={() => {
          closePopupById(id);
          confirm();
        }}
        retry={() => {
          closePopupById(id);
          retry();
        }}
      />
    );
  };

  /* 뽑기 확정 팝업  */
  const DrawCompletePopup = ({ clearPopups }: { clearPopups: () => void }) => {
    const id = "draw-complete-popup";
    useScrollLock();

    return (
      <Popup id={id} className={`${id} popupContainer`}>
        <PopupTitle>
          <p>{t("brand:사전등록_이벤트:register_e53")}</p>
        </PopupTitle>

        <PopupDesc>
          <>
            <strong>{t("brand:사전등록_이벤트:register_e54")}</strong>
            <p>{t("brand:사전등록_이벤트:register_e55")}</p>
          </>
        </PopupDesc>

        <PopupButton count="single">
          <ConfirmButton handleClick={() => clearPopups()} />
        </PopupButton>
      </Popup>
    );
  };

  return {
    LoginPopup,
    NotRegisteredPopup,
    DrawConfirmPopup,
    DrawOutOfTicketsPopup: DrawOutOfTickets,
    DrawResultPopup: DrawResult,
    DrawCompletePopup,
  };
}
