import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { queryKeys } from "@modules/Brand/model/queryKeys";
import { accessTokenState } from "@modules/Brand/recoil/token";
import {
  getPreRegistrationModel,
  PreRegistrationStatusResult,
} from "@modules/Brand/model";
import useApiErorr from "./useApiError";
import { AuthorizedState } from "@modules/Brand/recoil/isAuthorized";

export default function useStatus() {
  const accessToken = useRecoilValue(accessTokenState);
  const { status } = queryKeys.preRegistration;
  const model = getPreRegistrationModel();
  const [statusData, setStatusData] =
    useState<PreRegistrationStatusResult | null>(null);
  const [isAuthorized, setIsAuthorized] = useRecoilState(AuthorizedState);
  // const { handleError } = useApiErorr();

  const { isSuccess, refetch } = useQuery({
    queryKey: [...status.queryKey, accessToken],
    queryFn: model.status,
    retryOnMount: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    cacheTime: 1000,
    onSuccess: data => {
      if (!data.header.isSuccessful) {
        //  handleError(data.header.resultCode);
        return;
      }
      setStatusData(data);
      setIsAuthorized(!!data.result?.userId);
    },
  });

  return {
    data: statusData,
    isSuccess,
    refetch,
    isAuthorized: isAuthorized,
  };
}
