import { useEffect, useState } from 'react';

export default function useVH() {
  const [vh, setVH] = useState<number>(0);

  useEffect(() => {
    const handler = () => {
      setVH(window.innerHeight);
    };

    handler();
    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [vh]);

  return { vh };
}
