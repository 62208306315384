import { Model } from "@common/apis";
import { UserIPCheck } from "./interface";
import type { RawAxiosRequestHeaders } from "axios";
import { convertToKoreaTime } from "@common/assets/utils";
import {
  OrderApiResult,
  OrderDetailApiResult,
  PreSaleItem,
  PreSaleItemsApiResult,
} from "./interface/preSale.interface";

/**
 * 사전판매에서 호출되는 API에 대해 정의한다.
 */
export class PreSaleModel extends Model {
  private readonly url: string;
  private readonly commonModelConfig: {
    config: { headers: RawAxiosRequestHeaders };
    withToken: boolean;
  };

  constructor() {
    super();
    this.url = "/pre-sell";
    this.commonModelConfig = {
      config: {
        headers: {
          ...this.urlencodedContentType(),
        },
      },
      withToken: true,
    };
  }

  public getUserIP = async () => {
    const response = await this.get<UserIPCheck>(
      this.url + "/checkCountry",
      this.commonModelConfig,
    );

    return response.data;
  };

  public getPreSaleItems = async () => {
    const response = await this.get<PreSaleItemsApiResult>(
      this.url + "/getPresellItemListByids",
      this.commonModelConfig,
    );

    return response.data;
  };

  public createOrder = async (itemSku: string) => {
    const response = await this.get<OrderApiResult>(this.url + "/get-order", {
      config: {
        ...this.commonModelConfig.config,
        params: {
          productSKU: itemSku,
          quantity: 1,
        },
      },
      withToken: true,
    });

    return response.data;
  };

  public getOrderDetail = async (invoiceId: string) => {
    const response = await this.get<OrderDetailApiResult>(
      this.url + "/get-myOrder",
      {
        config: {
          ...this.commonModelConfig.config,
          params: {
            invoiceId,
          },
        },
        withToken: true,
      },
    );

    return response.data;
  };
}

export function getXsollaPaymentUiUri(paymentToken: string) {
  const xsollaPaymentUiUrl = process.env.REACT_APP_XSOLLA_PAYMENT_UI_URL;

  return `${xsollaPaymentUiUrl}=${paymentToken}`;
}

export default function getPreSaleModel() {
  return new PreSaleModel();
}

export function isValidPreSalePeriod(startDate: Date, endDate: Date) {
  const curr = convertToKoreaTime();

  return curr >= startDate && curr < endDate;
}

export function getCurrentPreSalePhase(preSaleItems: PreSaleItem[]) {
  const curr = convertToKoreaTime();
  const preSaleDates = {
    1: { startDate: 0, endDate: 0 },
    2: { startDate: 0, endDate: 0 },
    3: { startDate: 0, endDate: 0 },
  };

  preSaleItems
    .filter(i => [1, 2, 3].includes(i.buyCnt))
    .forEach(
      i =>
        (preSaleDates[i.buyCnt as keyof typeof preSaleDates] = {
          startDate: i.startDate,
          endDate: i.endDate,
        }),
    );
  let currPhase = 0;

  for (let d in preSaleDates) {
    const startDate = new Date(
      preSaleDates[d as unknown as keyof typeof preSaleDates].startDate,
    );
    const endDate = new Date(
      preSaleDates[d as unknown as keyof typeof preSaleDates].endDate,
    );

    if (curr >= startDate && curr <= endDate) {
      currPhase = parseInt(d);
      break;
    }
  }

  return currPhase;
}

export function formatPreSaleStartDate(date: Date) {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  const formattedDate = year + "." + month + "." + day;

  return formattedDate;
}

export function formatPreSaleEndDate(date: Date) {
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  const formattedDate = month + "." + day;

  return formattedDate;
}

export function isValidInventory(inventory: number) {
  return inventory > 0;
}

export function isPersonPurchasable(count: number) {
  return count > 0;
}
