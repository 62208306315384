/** @jsxImportSource @emotion/react */
import { useRef, useEffect, useState, Fragment } from "react";
import type { BrandControllerProps } from "@modules/Brand/controller/interface";
import { useRecoilState, useRecoilValue } from "recoil";
import { css, keyframes } from "@emotion/react";
import theme from "@common/styles/theme";
import { fromCDN } from "@assets/utils";
import useVH from "@hooks/useVH";
import useWheel from "@hooks/useWheel";
import useDevice from "@hooks/useDevice";
import useResize from "@hooks/useResize";
import useBodyHidden from "@hooks/useBodyHidden";
import { useScrollTo } from "@hooks/useScrollTo";
import MainVideo from "@modules/Brand/view/GameIntro/MainVideo";
import WooparooView from "@modules/Brand/view/GameIntro/WooparooView";
import GameIntro from "@modules/Brand/view/GameIntro/GameIntro";
import SynopsisView from "@modules/Brand/view/GameIntro/SynopsisView";
import BattleView from "@modules/Brand/view/GameIntro/BattleView";
import Intro from "@modules/Brand/view/PreRegister/Intro";
import Notice from "@modules/Brand/view/PreRegister/Notice";
import Islands from "@modules/Brand/view/PreRegister/Islands";
import WooparooSwiper from "@modules/Brand/view/PreRegister/swiper";
import AppDownloads from "@modules/Brand/view/PreRegister/AppDownloads";
import Benefits from "@modules/Brand/view/PreRegister/Benefits/Benefits";
import Footer from "@modules/Brand/view/components/Layout/Footer";
import VideoModal from "./GameIntro/VideoModal";
import { popupState } from "@modules/Brand/recoil/popup";
import { currentSectionState } from "@modules/Brand/recoil/section/atom";
import { presalePhaseState } from "../recoil/presalePhase";
import RotateNotice from "./components/Layout/RotateNotice";
import PreRegisterButtons from "./PreRegister/PreRegisterButtons";
import MoveToTopButton from "./components/MoveToTopButton";
import { zIndex } from "@common/constants";
import { debounce, throttle } from "lodash";

// 글로벌 블록체인 버전
// import PnEView from "@modules/Brand/view/PnEView";
// import PreSale from "@modules/Brand/view/PreSale/PreSale";
// import PreSaleMain from "@modules/Brand/view/PreSale/PreSaleMain";
// import GalaxyWooparoo from "@modules/Brand/view/PreSale/GalaxyWooparoo";
const mobileBg = fromCDN("brand/images/m_end_bg.jpg");
const tabletBg = fromCDN("brand/images/desktop_end.jpg");
const PCBg = fromCDN("brand/images/desktop_end.jpg");

const BrandView = (props: BrandControllerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { vh } = useVH();
  const { width } = useResize();
  const { fromLapTop } = useDevice();
  useBodyHidden(fromLapTop);
  const [slides, setSlides] = useState<NodeListOf<Element>>();
  const [currentSection, setCurrentSection] =
    useRecoilState(currentSectionState);
  const [currentPhaseTab, setCurrentPhaseTab] =
    useRecoilState(presalePhaseState);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const popups = useRecoilValue(popupState);
  const { wheelValue, reset, hold } = useWheel(containerRef);
  const [y, setY] = useState(0);
  const [wooparooSlideActiveIdx, setWooparooSlideActiveIdx] = useState(0);
  const { scrollTo } = useScrollTo();
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  // TODO : currentSection 디폴트값 0으로 설정 변경해서 임시 주석처리했어요! 추후 코드 제거 해도 될 것 같아요!
  // useEffect(() => {
  //   if (currentSection < 0) {
  //     setCurrentSection(0);
  //   }
  // }, [currentSection, setCurrentSection]);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    const panels = document.querySelectorAll(".panel");
    setSlides(panels);
  }, []);

  const goToSlide = (index: number) => {
    if (0 <= index) {
      reset();
      setCurrentSection(index);
    }
  };

  const goToNext = () => {
    // 글로벌 블록체인 버전
    // const totalSlides = 20;
    const totalSlides = 5;
    if (slides && currentSection < totalSlides)
      return goToSlide(
        currentSection === 10
          ? currentSection + currentPhaseTab
          : currentSection + 1,
      );
    return reset();
  };

  const goToPrev = () => {
    if (slides && 1 <= currentSection) return goToSlide(currentSection - 1);
    return reset();
  };

  useEffect(() => {
    if (isVideoModalOpen) return;
    if (!fromLapTop || hold) return;

    const std = 200;
    if (wheelValue < -std) goToPrev();
    if (std < wheelValue) goToNext();
  }, [fromLapTop, wheelValue]);

  useEffect(() => {
    const scrollContainer =
      document.querySelector<HTMLElement>(".scrollContainer");
    const notice = document.querySelector<HTMLElement>("#notice");
    const noticeHeight = Number(notice?.getBoundingClientRect().height);
    const footer = document.querySelector<HTMLElement>("#footer");
    const footerHeight = Number(footer?.getBoundingClientRect().height);
    const normalVHLimit = 6;

    function calcY() {
      let y = vh * currentSection;

      // if (currentSection === 7) {
      //   // 혜택 영역의 두번째 슬라이드
      //   y = vh * normalVHLimit;
      // }

      // if (currentSection >= 8) {
      //   // 주의사항 이후 슬라이드
      //   y = vh * (currentSection - 2) + noticeHeight;
      // }

      if (currentSection === 5) {
        // 글로벌 일반 버전 푸터 영역 처리
        y = vh * 4 + footerHeight;
      }

      /*
      // 글로벌 블록체인 버전 - 시작
      if (8 <= currentSection) {
        //주의사항 이후 슬라이드
        y = vh * (currentSection - 2) + noticeHeight;
      }
      if (currentSection === 12 || currentSection === 13) {
        //사전판매 2, 3차 영역 슬라이드
        y = vh * 9 + noticeHeight;
      }
      if (currentSection >= 14) {
        y = vh * 10 + noticeHeight;
      }
      if (currentSection === 20) {
        //푸터 영역
        y = vh * 10 + noticeHeight + footerHeight;
      }
      // 글로벌 블록체인 버전 - 끝
      */

      return y;
    }

    if (!fromLapTop && scrollContainer) {
      scrollContainer.style.transform = "";
    }

    if (fromLapTop && scrollContainer) {
      window.scrollTo(0, 0);
      scrollContainer.style.transform = `matrix(1, 0, 0, 1, 0, -${
        currentSection === 0 ? calcY() : calcY() - 2
      })`;
    }
  }, [hold, currentSection, vh, reset, fromLapTop]);

  const handleScroll = (scrollY: number) => {
    setY(scrollY);
  };

  const getCurrentSectionIdx = (currentSection: number) => {
    let idx = currentSection;

    if ([6, 7].includes(currentSection)) {
      idx = 6;
    } else if (currentSection === 8) {
      idx = 7;
    } else if (currentSection === 9) {
      idx = 8;
    } else if (currentSection === 10) {
      idx = 9;
    } else if ([11, 12, 13].includes(currentSection)) {
      idx = 10;
    } else if ([14, 15, 16, 17, 18, 19].includes(currentSection)) {
      idx = 11;
    }

    return idx;
  };

  //모바일&태블릿 분기에서 모바일메뉴 하이라이트 표기를 위한 스크롤 구간별 섹션값 할당
  useEffect(() => {
    if (fromLapTop) return;

    const panels = Array.from(document.querySelectorAll<HTMLElement>(".panel"));
    const panelTops = panels.map(panel => panel.offsetTop);

    const listener = () => {
      const windowY = window.scrollY;
      const idx = getCurrentSectionIdx(currentSection);

      const nextTops =
        idx === 0
          ? panelTops[idx] * 0.5
          : idx === 10
          ? panelTops[idx - 1] + (panelTops[idx] - panelTops[idx - 1]) * 0.8
          : idx === 7
          ? panelTops[idx - 1] + (panelTops[idx] - panelTops[idx - 1]) * 0.1
          : panelTops[idx - 1] + (panelTops[idx] - panelTops[idx - 1]) * 0.5;

      const prevTops =
        idx === 1
          ? panelTops[0] * 0.5
          : idx === 11
          ? panelTops[idx - 2] + (panelTops[idx - 1] - panelTops[idx - 2]) * 0.9
          : panelTops[idx - 2] +
            (panelTops[idx - 1] - panelTops[idx - 2]) * 0.5;

      const isScrollDown = windowY > y;

      if (nextTops < windowY && isScrollDown) {
        if ([6, 7].includes(currentSection)) {
          return setCurrentSection(8);
        }

        /*
        // 글로벌 블록체인 버전 - 시작 
        if (currentSection === 10) {
          return setCurrentSection(currentPhaseTab + 10);
        }
        if ([11, 12, 13].includes(currentSection)) {
          return setCurrentSection(wooparooSlideActiveIdx + 14);
        }
        if ([14, 15, 16, 17, 18, 19].includes(currentSection)) {
          return;
        }
        // 글로벌 블록체인 버전 - 끝  
        */
        setCurrentSection(currentSection + 1);
      } else if (prevTops > windowY && !isScrollDown) {
        if ([6, 7].includes(currentSection)) {
          return setCurrentSection(5);
        }
        if ([11, 12, 13].includes(currentSection)) {
          return setCurrentSection(10);
        }
        if ([14, 15, 16, 17, 18, 19].includes(currentSection)) {
          return setCurrentSection(currentPhaseTab + 10);
        }
        setCurrentSection(currentSection - 1);
      }
      handleScroll(windowY);
    };

    window.addEventListener("scroll", listener);

    // 리사이징 할 경우 재 호출
    if (width) {
      listener();
    }
    return () => window.removeEventListener("scroll", listener);
  }, [currentSection, width, vh]);

  useEffect(() => {
    if (fromLapTop) return;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsScrollingUp(
        currentSection !== 0 && prevScrollPos > currentScrollPos,
      );
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, currentSection]);

  return (
    <>
      <div ref={containerRef} css={fitVh}>
        <div css={mainContainer}>
          <div css={style} id="scrollContainer" className="scrollContainer">
            <MainVideo
              className="panel"
              setIsVideoModalOpen={setIsVideoModalOpen}
            />
            <div className="bg">
              <div className="panel">
                <WooparooView />
              </div>
              <div className="effect">
                <div className="topEffect"></div>
              </div>
              <div className="panel gameintoBox">
                <GameIntro />
              </div>
              <div className="effect">
                <div className="topEffect topEffect2"></div>
              </div>
              <div className="panel synopsisBox">
                <SynopsisView />
              </div>
              <div className="panel battleBox">
                <BattleView currentSection={currentSection} />
              </div>
              {/* <div
                className="panel preRegistFirst"
                css={[displayCenterColumn, headerPadding]}
              >
                <Intro />
                <AppDownloads />
                <WooparooSwiper />
                <PreRegisterButtons {...props} />
                <Islands />
              </div>
              <div className="panel" css={headerPadding}>
                <Benefits currentSlide={currentSection} />
              </div> */}
            </div>
            {/* <div id="notice" className="panel h-fit notice-fit">
              <Notice />
            </div> */}

            {/* 글로벌 블록체인 버전 - 시작 */}
            {/* <div className="panel" css={fitHeight}>
              <PnEView />
            </div>

            <div css={mbBackground}>
              <div className="panel presaleMain" css={fitHeight}>
                <PreSaleMain preSaleItems={props.preSaleItems!} />
              </div>
              <div className="panel preSale" css={[fitHeight, fitVh]}>
                <PreSale {...props} />
              </div>
              <div className="panel galaxyWooap" css={fitHeight}>
                <GalaxyWooparoo
                  wooparooSlideActiveIdx={wooparooSlideActiveIdx}
                  setWooparooSlideActiveIdx={setWooparooSlideActiveIdx}
                />
              </div>
            </div> */}
            {/* 글로벌 블록체인 버전 - 끝 */}
            <div className="panel" css={fitHeight} id="footer">
              <Footer />
            </div>
          </div>
          <div
            className={`moveToTopBtn ${
              (isScrollingUp && !fromLapTop) ||
              (currentSection === 5 && !fromLapTop) ||
              (currentSection !== 0 && fromLapTop)
                ? "expose"
                : ""
            }`}
          >
            <MoveToTopButton
              clickHandler={() =>
                fromLapTop ? setCurrentSection(0) : scrollTo({ to: 0 })
              }
            />
          </div>
        </div>
      </div>

      {popups?.length
        ? popups.map((popup, i) => <Fragment key={i}>{popup}</Fragment>)
        : null}

      {isVideoModalOpen && (
        <VideoModal setIsVideoModalOpen={setIsVideoModalOpen} />
      )}
      {props.isRequiredRotate && <RotateNotice />}
    </>
  );
};

export default BrandView;
const effect = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
`;

const animationScrolltop = keyframes`
  0% {
margin-bottom: -15px;
  }
  100% {
margin-bottom: 0;
  }
`;

// const mbBackground = css`;
//   @media ${theme.devices.laptop} {
//     background-image: url(${fromCDN("brand/images/preregist-pc.png")});
//     background-position-y: top;
//     background-size: cover;
//     background-position-x: center;
//   }
// `;

const fitVh = css`
  @media ${theme.devices.laptop} {
    height: 100vh;
  }
`;

const fitHeight = css`
  height: fit-content !important;
`;

const displayCenterColumn = css`
  ${theme.mixins.displayCenter};
  flex-direction: column;
  height: 740px !important;

  @media ${theme.devices.tablet} {
    height: 1050px !important;
  }
`;

const headerPadding = css`
  @media ${theme.devices.tablet} {
    padding-top: 80px;
  }

  @media ${theme.devices.desktopL} {
    padding-top: 0;
  }
`;

const mainContainer = css`
  /* overflow: hidden; */ //! 사전판매 차수탭 sticky 처리때매 주석처리했는데 혹시 해제하실 경우 알려주세요!

  .moveToTopBtn {
    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: 1000;
    display: none;

    &.expose {
      display: block;
      animation: ${animationScrolltop} 0.4s ease-out 1;
    }
  }

  @media ${theme.devices.tablet} {
    .moveToTopBtn {
      right: 32px;
      bottom: 32px;
    }
  }

  @media (min-width: 1024px) {
    .moveToTopBtn {
      right: 48px;
      bottom: 48px;
    }
  }

  @media ${theme.devices.laptop} {
    .moveToTopBtn {
      right: 32px;
      bottom: 32px;
    }
  }

  @media ${theme.devices.laptopL} {
    .moveToTopBtn {
      right: calc((100vw - 1200px) / 2);
    }
  }
`;

const style = css`
  position: relative;
  overflow-x: clip; //! 사전판매 차수탭 스크롤할때 덜덜거리는 버그 때문에 주석처리했는데 혹시 해제하실 경우 알려주세요! by lily
  transition: transform 1s cubic-bezier(0.5, 0, 0.5, 1);

  .gameintoBox {
    max-height: 930px;
  }

  .battleBox {
    min-height: 700px;
  }

  .synopsisBox {
    max-height: 755px;
  }

  .presaleMain {
    overflow-x: hidden;
  }

  .bg {
    background-image: url(${mobileBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: 0;
    /* padding-bottom: calc(100vw - 99%);
    margin-bottom: calc((100vw - 99%) * -1); */
    overflow-x: hidden;
  }

  /* @media (min-width: 500px) {
    .bg {
      padding-bottom: calc(100vw - 92%);
      margin-bottom: calc((100vw - 92%) * -1);
    }
  }

  @media (min-width: 550px) {
    .bg {
      padding-bottom: calc(100vw - 76%);
      margin-bottom: calc((100vw - 76%) * -1);
    }
  }

  @media (min-width: 580px) {
    .bg {
      padding-bottom: calc(100vw - 73%);
      margin-bottom: calc((100vw - 73%) * -1);
    }
  }

  @media (min-width: 600px) {
    .bg {
      padding-bottom: calc(100vw - 74%);
      margin-bottom: calc((100vw - 74%) * -1);
    }
  } */

  .panel {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-width: 100vw;
    min-width: 250px;
  }

  .preRegistFirst {
    height: auto !important;
    // overflow: unset;
    overflow: hidden;
    padding-top: 80px;
    padding-bottom: clamp(0px, 20%, 60px);
  }

  .topEffect {
    position: relative;
    top: 70px;
    left: 50%;
    margin-left: -60px;
    width: 120px;
    height: 175px;
    background-image: url(${fromCDN("brand/images/연결.png")});
    background-size: cover;
    margin-top: -178px;
    animation: ${effect} 2.5s infinite;
  }

  .topEffect2 {
    top: 126px;
    margin-top: -175px;
  }

  .preBenefit {
    padding-top: 0 !important;
  }

  .preSale {
    padding-top: 0 !important;
    overflow: unset;
  }

  .galaxyWooap {
    padding-top: 0px !important;
  }

  #notice {
    background-color: ${theme.palette.grey[7]};
  }

  @media ${theme.devices.tablet} {
    .synopsisBox,
    .gameintoBox {
      max-height: unset;
    }

    .bg {
      background-image: url(${tabletBg});
      /* padding-bottom: 120px;
      margin-bottom: -120px; */
    }

    .preRegistFirst {
      height: 1050px !important;
      padding-top: 128px;
      padding-bottom: 242px;
    }

    .panel {
      overflow: unset;
    }

    .preBenefit,
    .presaleMain {
      overflow: hidden;
    }

    .topEffect {
      width: 240px;
      height: 350px;
      margin-top: -350px;
      margin-left: -120px;
      top: 150px;
    }

    .topEffect2 {
      top: 200px;
    }

    .preBenefit {
      padding-top: 80px !important;
    }
  }

  @media ${theme.devices.laptop} {
    overflow: hidden;

    .moveToTopBtn {
      display: block;
    }

    .bg {
      background-image: url(${PCBg});
      padding-bottom: 0px;
      margin-bottom: 0px;
      overflow-x: unset;
    }

    .panel {
      height: 100vh !important;
      padding-top: 80px;
    }

    .h-fit {
      height: 100% !important;
    }

    .notice-fit {
      padding-top: 0px;
    }

    .effect {
      position: relative;
    }

    .topEffect {
      position: absolute;
      height: 260px;
      width: 150px;
      margin-left: -80px;
      top: 260px;
    }

    .topEffect2 {
      top: 180px;
    }

    .preSale {
      padding-top: 80px !important;
    }

    #footer {
      padding-top: 0px !important;
      height: fit-content !important;
    }

    .preRegistFirst {
      padding-bottom: 0;
    }
  }

  @media (min-width: 1500px) and (${theme.resolutionHeight.height1}) {
    .bg {
      background-size: 100% 100%;
    }
  }

  @media ${theme.devices.desktop} {
    .topEffect {
      top: 240px;
    }

    .topEffect2 {
      top: 260px;
    }
  }

  @media ${theme.devices.desktopL} {
    .topEffect2 {
      top: 230px;
    }
  }

  @media (min-width: 2000px) and (min-height: 670px) {
    .bg {
      background-size: 100% 100%;
    }
  }
`;
