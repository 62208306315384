import { currentSectionState, sectionMapState } from '@modules/Brand/recoil/section/atom';
import { selector } from 'recoil';

export const sectionSlideState = selector({
  key: 'SectionSlideMap',
  get: ({ get }) => {
    const sectionStateIndexMap = get(sectionMapState);
    const currentSlide = get(currentSectionState);
    const sectionEnum = sectionStateIndexMap.get(currentSlide);

    return sectionEnum ?? null;
  }
});
