/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import SocialShareInfo from "./SocialShareInfo";
import { drawWooparooTranslation, zIndex } from "@common/constants";
import type { Wooparoo } from "@modules/Market/model";
import { fromCDN } from "@assets/utils";
import { useTranslation, Trans } from "react-i18next";
import { getTranslatedDrawWooparoo } from "@modules/Brand/model/PreRegistrationModel";

const Dummy = fromCDN("market/images/wooparoo_dummy.png");
const Close = fromCDN("brand/svg/close_gray.svg");

type Props = {
  remainTimes: number;
  wooparoo: Partial<Wooparoo>;
  id?: string;
  confirm: () => void;
  retry: () => void;
  close: () => void;
};

function DrawResultPopup({
  remainTimes,
  wooparoo,
  confirm,
  retry,
  close,
}: Props) {
  const outOfTickets = remainTimes === 0;
  const { t, i18n } = useTranslation();

  const pad = (num: number) => {
    return num < 10 ? "0" + num.toString() : num.toString();
  };

  return (
    <div css={style}>
      <div className="contents popupContainer">
        <div css={closeBtn} onClick={() => close()} />
        <div className="info">
          <div className="title">
            <span>{t("brand:사전등록_이벤트:register_e39")}</span> <br />
            <Trans wooparoo={wooparoo.name}>
              {t("brand:사전등록_이벤트:register_e40", {
                wooparoo: getTranslatedDrawWooparoo(
                  wooparoo.wooparooId!,
                  i18n.language,
                ),
              })}
            </Trans>
          </div>
          <div className="drawTimes">
            {t("brand:사전등록_이벤트:register_e46")}
            <span className={`${outOfTickets ? "red" : ""}`}>
              {outOfTickets ? "00" : pad(remainTimes)}
            </span>
          </div>

          <div className="innerContainer popupContainer">
            <div style={{ overflow: "auto" }}>
              <div className="result">
                <img src={wooparoo.image ?? Dummy} alt="wooparoo" />
                <div className="btns">
                  <button onClick={() => confirm()}>
                    {t("brand:사전등록_이벤트:register_e47")}
                  </button>
                  <button onClick={() => retry()}>
                    {t("brand:사전등록_이벤트:register_e48")}
                  </button>
                </div>
              </div>
              <ul className="notice">
                <li>{t("brand:사전등록_이벤트:register_e49")}</li>
                <li>{t("brand:사전등록_이벤트:register_e50")}</li>
              </ul>
              <SocialShareInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrawResultPopup;
const closeBtn = css`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  background-image: url(${Close});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
const style = css`
  width: 100vw;
  height: 100vh;
  background-color: ${theme.palette.action.disabledBackground};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.dimmer};

  button {
    width: 150px;
    height: 50px;
    color: ${theme.palette.grey[9]};
    ${theme.fonts.small1B};
    line-height: 24px;
    background-color: ${theme.palette.primary.main};
    border-radius: 10px;
    border: none;
    cursor: pointer;

    &:active {
      ${theme.mixins.buttonPressEffect};
    }
  }

  .contents {
    width: 312px;

    max-height: 550px;
    // padding-bottom: 40px;
    // background-color: ${theme.palette.common.white};
    border-radius: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    .closeBtn {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 14px;
      right: 14px;
      cursor: pointer;

      &.gray {
        color: ${theme.palette.grey[2]};
        stroke: ${theme.palette.grey[2]};
      }
    }
  }

  .title {
    padding: 25px 0;
    background-color: ${theme.palette.secondary.main3};
    color: ${theme.palette.common.white};
    ${theme.fonts.buttonB};
    line-height: 29px;
    // border-top-left-radius: 30px;
    // border-top-right-radius: 30px;

    span {
      ${theme.fonts.small1B};
      line-height: 23px;
    }
  }

  .info {
    background-color: ${theme.palette.common.white};
    padding-bottom: 40px;
    padding: 0px;
    overflow: hidden;
    height: 550px;

    .drawTimes {
      padding: 16px 0;
      // margin-bottom: 20px;
      background-color: ${theme.palette.grey[7]};
      color: ${theme.palette.grey[3]};
      ${theme.fonts.small1B};
      ${theme.mixins.displayCenter};

      span {
        margin-left: 14px;
        color: ${theme.palette.common.white};
        ${theme.fonts.subTitleB};

        &.red {
          color: ${theme.palette.error.main};
        }
      }
    }

    .result {
      img {
        width: 110px;
        height: 110px;
        margin-bottom: 20px;
        border: 2px solid ${theme.palette.primary.main};
        border-radius: 20px;
      }

      .btns {
        margin-bottom: 20px;

        button {
          width: 120px;
          cursor: pointer;

          &:first-of-type {
            margin-right: 10px;
            background-color: ${theme.palette.grey[2]};
          }
        }
      }
    }

    .notice {
      margin: 0 20px 20px 20px;
      ${theme.fonts.small2R};
      color: ${theme.palette.grey[7]};
      text-align: start;

      li {
        list-style: none;
        padding-left: 15px;
        line-height: 19px;
        position: relative;

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: ${theme.palette.grey[7]};
          top: 8px;
          left: 3px;
        }

        &:first-of-type {
          margin-bottom: 8px;
        }
      }
    }

    .innerContainer {
      background-color: #fff;
      overflow-y: auto;
      padding: 20px 0;
      height: 390px;
    }
  }
`;
