/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { fromCDN } from "@assets/utils";
import SVGComponent from "@modules/Brand/view/components/SVGComponent";
import { useRecoilState } from "recoil";
import { currentSectionState } from "@modules/Brand/recoil/section";

const ScrollDownIcon = SVGComponent(fromCDN("brand/svg/scroll_down.svg"));

type Props = {
  sectionIdx: number;
  sectionVal: number;
};
const ScrollDown = ({ sectionIdx, sectionVal }: Props) => {
  const targetSection = Array.from(
    document.querySelectorAll<HTMLElement>(".panel"),
  )[sectionIdx];
  const [currentSection, setCurrentSection] =
    useRecoilState(currentSectionState);

  return (
    <div css={scrollDown} className="scroll-down-box">
      <span>Scroll down</span>
      <ScrollDownIcon
        className="down"
        onClick={() => {
          targetSection.scrollIntoView({ behavior: "smooth" });
          setCurrentSection(sectionVal);
        }}
      />
    </div>
  );
};

export default ScrollDown;

const scrollDown = css`
  position: absolute;
  bottom: 60px;
  left: 50%;
  margin-left: -35px;
  text-align: center;

  span {
    display: block;
    ${theme.fonts.small3B};
    color: ${theme.palette.grey[4]};
    margin-bottom: 15px;
    animation: text 2s infinite;
    -webkit-animation: text 2s infinite;

    @keyframes text {
      0% {
        transform: scale(1);
        opacity: 0;
      }
      10% {
        transform: scale(1.11);
        opacity: 1;
      }
      100% {
        transform: scale(1.13);
        opacity: 0;
      }
    }
  }

  .down {
    width: 35px;
    height: 20px;
    animation: arrow 2s infinite;

    @keyframes arrow {
      0% {
        opacity: 0;
      }
      10% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(13px);
        opacity: 0;
      }
    }
  }

  @media ${theme.devices.tablet} {
    .down {
      width: 49px;
      height: 27px;
    }
  }

  @media ${theme.devices.laptop} and (${theme.resolutionHeight.height1}) {
    bottom: 22px;
  }
`;
