/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import useShareURL from "@hooks/useShareURL";
import { SocialPlatform } from "@modules/Brand/model";
import { useRecoilState } from "recoil";
import { AuthorizedState } from "@modules/Brand/recoil/isAuthorized";
import { popupState } from "@modules/Brand/recoil/popup";
import useDrawPopups from "@hooks/useDrawPopups";
import { useTranslation } from "react-i18next";
import { openSocialShareLink } from "@modules/Brand/model/PreRegistrationModel";
import { fromCDN } from "@common/assets/utils";

const facebook_popup = fromCDN("brand/images/facebook_color@.png");
const x_popup = fromCDN("brand/images/x_color@.png");
const line_popup = fromCDN("brand/images/line_color@.png");
const facebook_normal = fromCDN("brand/images/facebook@.png");
const x_normal = fromCDN("brand/images/x@.png");
const line_normal = fromCDN("brand/images/line@.png");
const facebook_normal_hover = fromCDN("brand/images/facebook_hover@.png");
const x_normal_hover = fromCDN("brand/images/x_hover@.png");
const line_normal_hover = fromCDN("brand/images/line_hover@.png");

type Props = {
  isPopup?: boolean;
};

const SnsShareIcons = ({ isPopup }: Props) => {
  const { url } = useShareURL();
  const { t } = useTranslation();
  const [isAuthorize] = useRecoilState(AuthorizedState);
  const [popups, setPopups] = useRecoilState(popupState);
  const clearPopups = () => setPopups([]);
  const closePopupById = (id: string) =>
    setPopups(popups.filter(popup => popup.props.id !== id));
  const DrawPopups = useDrawPopups({ closePopupById, clearPopups });

  const handler = (
    type: SocialPlatform,
    url: string,
    text?: string,
    hashTags?: string,
  ) => {
    openSocialShareLink(type, url, text, hashTags);
  };

  return (
    <ul css={style} className={`socialIcons ${isPopup ? "popup" : ""}`}>
      <li
        onClick={() => {
          if (!isAuthorize) return setPopups([<DrawPopups.LoginPopup />]);
          handler(SocialPlatform.FACEBOOK, url);
        }}
        className="socialIcon facebook"
      ></li>
      <li
        onClick={() => {
          if (!isAuthorize) return setPopups([<DrawPopups.LoginPopup />]);
          const text = t("brand:공통:twitterMsg");
          const hashTags = t("brand:공통:twitterHashTags");
          handler(SocialPlatform.X, url, text, hashTags);
        }}
        className="socialIcon x"
      ></li>
      <li
        onClick={() => {
          if (!isAuthorize) return setPopups([<DrawPopups.LoginPopup />]);
          handler(SocialPlatform.LINE, url);
        }}
        className="socialIcon line"
      ></li>
    </ul>
  );
};

export default SnsShareIcons;

const style = css`
  display: grid;
  grid-template-columns: auto auto auto;

  &.popup {
    .socialIcon {
      &.facebook {
        background-image: url(${facebook_popup});
        &:hover {
          background-image: url(${facebook_popup});
        }
      }
      &.x {
        background-image: url(${x_popup});
        &:hover {
          background-image: url(${x_popup});
        }
      }
      &.line {
        background-image: url(${line_popup});
        &:hover {
          background-image: url(${line_popup});
        }
      }
    }
  }

  .socialIcon {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &.facebook {
      background-image: url(${facebook_normal});

      &:hover {
        background-image: url(${facebook_normal_hover});
      }
    }
    &.x {
      background-image: url(${x_normal});
      &:hover {
        background-image: url(${x_normal_hover});
      }
    }
    &.line {
      background-image: url(${line_normal});
      &:hover {
        background-image: url(${line_normal_hover});
      }
    }
  }

  & li {
    cursor: pointer;
  }

  @media ${theme.devices.laptop} {
    .socialIcon {
      cursor: pointer;
    }
  }
`;
