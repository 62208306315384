import { fromCDN } from "@assets/utils";
import { TFunction } from "i18next";

const Facebook = fromCDN("brand/images/facebook_white@.png");
const Twitter = fromCDN("brand/images/x_white@.png");
const Discord = fromCDN("brand/svg/Discord_icon.svg");
const Youtube = fromCDN("brand/images/youtube_white@.png");
const Insta = fromCDN("brand/images/insta_white@.png");

// 브랜드 페이지 네비게이션 two depth 메뉴
export const brandGameMenu = (
  t: TFunction<"translation", undefined, "translation">,
) => {
  return [
    { id: 1, title: t("brand:gnb:nav7"), sectionMap: 1 },
    { id: 2, title: t("brand:gnb:nav8"), sectionMap: 2 },
    { id: 3, title: t("brand:gnb:nav9"), sectionMap: 3 },
    { id: 4, title: t("brand:gnb:nav10"), sectionMap: 4 },
  ];
};

export const brandSalesMenu = (
  t: TFunction<"translation", undefined, "translation">,
) => {
  return [
    { id: 1, title: t("brand:gnb:nav11"), sectionMap: 11 },
    { id: 2, title: t("brand:gnb:nav12"), sectionMap: 12 },
    { id: 3, title: t("brand:gnb:nav13"), sectionMap: 13 },
    { id: 4, title: t("brand:gnb:nav14"), sectionMap: 14 },
  ];
};

export const brandSocial = {
  EN: [
    {
      title: "facebook",
      social: Facebook,
      url: "https://www.facebook.com/wooparoo.odyssey.global",
    },
    {
      title: "twitter",
      social: Twitter,
      url: "https://twitter.com/WooparooOdyssey",
    },
    {
      title: "youtube",
      social: Youtube,
      url: "https://www.youtube.com/@WooparooOdyssey",
    },
    {
      title: "instagram",
      social: Insta,
      url: "https://www.instagram.com/wooparooodyssey_official",
    },
  ],
  TH: [
    {
      title: "facebook",
      social: Facebook,
      url: "https://www.facebook.com/wooparoo.odyssey.global",
    },
    {
      title: "twitter",
      social: Twitter,
      url: "https://twitter.com/WooparooOdyssey",
    },
    {
      title: "youtube",
      social: Youtube,
      url: "https://www.youtube.com/@WooparooOdyssey",
    },
    {
      title: "instagram",
      social: Insta,
      url: "https://www.instagram.com/wooparooodyssey_official",
    },
  ],
  JP: [
    {
      title: "twitter",
      social: Twitter,
      url: "https://twitter.com/Wooparoo_Japan",
    },
    {
      title: "youtube",
      social: Youtube,
      url: "https://www.youtube.com/@WooparooOdyssey",
    },
  ],
  TW: [
    {
      title: "facebook",
      social: Facebook,
      url: "https://www.facebook.com/wooparoo.odyssey.tw/",
    },
    {
      title: "youtube",
      social: Youtube,
      url: "https://www.youtube.com/@WooparooOdyssey",
    },
  ],
};

export const brandLang = [
  { id: 1, title: "KR" },
  { id: 2, title: "EN" },
  { id: 3, title: "JP" },
  { id: 4, title: "TH" },
  { id: 5, title: "TW" },
];

export const authMenu = {
  mypage: "My Page",
  login: "Login",
  logout: "Logout",
};

export const brandLogin = (isAuthorized: boolean | null) => [
  { title: authMenu.mypage },
  // { title: isAuthorized ? authMenu.logout : authMenu.login },
  {
    title: isAuthorized ? authMenu.logout : authMenu.login,
  },
];

export const brandLoginMobile = (isAuthorized: boolean | null) => ({
  title: isAuthorized ? authMenu.logout : authMenu.login,
});
