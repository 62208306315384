/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useState } from "react";
import theme from "@common/styles/theme";
import { zIndex } from "@common/constants";
import {
  fromCDN,
  openPrivacyPolicyUrl,
  openTermsOfServiceUrl,
} from "@common/assets/utils";
import { useTranslation } from "react-i18next";
import useScrollLock from "@hooks/useScrollLock";
import { clearAccessToken } from "@modules/Brand/model";

type Props = {
  agreed: () => void;
  closePopupById: (id: string) => void;
  id: string;
  type: string;
};

const PersonalInfoPopupGlobal = ({
  agreed,
  closePopupById,
  id,
  type,
}: Props) => {
  const [showWarning, setShowWarning] = useState(false);
  const [agreePresonal, setAgreePresonal] = useState(false);
  const { t, i18n } = useTranslation();
  useScrollLock();

  return (
    <div id={id} css={personalInfoPopupGlobal}>
      <div className={`contents ${i18n.language}popup popupContainer`}>
        <h3>
          <span className="consent-title">
            {type === "login"
              ? t("brand:글로벌_로그인_프로세스:login_g2")
              : t("brand:글로벌_사전등록_프로세스:preregi_g1")}
          </span>
        </h3>

        <div className="consent-container">
          <div className="consent-content">
            <span>
              {t("brand:글로벌_사전등록_프로세스:preregi_g2")}{" "}
              <strong onClick={openTermsOfServiceUrl}>
                {t("brand:글로벌_사전등록_프로세스:preregi_g12")}
              </strong>
            </span>
            <span>
              {t("brand:글로벌_사전등록_프로세스:preregi_g3")}{" "}
              <strong onClick={openPrivacyPolicyUrl}>
                {t("brand:글로벌_사전등록_프로세스:preregi_g12")}
              </strong>
            </span>
          </div>

          <div className="consent-checkbox-container">
            <label className="consent-checkbox" htmlFor="consent-checkbox">
              <input
                id="consent-checkbox"
                type="checkbox"
                onChange={e => {
                  setShowWarning(!e.target.checked);

                  if (e.target.checked) {
                    setAgreePresonal(true);
                  } else {
                    setAgreePresonal(false);
                  }
                }}
              />
              <div
                className={
                  showWarning ? "custom-checkbox focusedBox" : "custom-checkbox"
                }
              />
              <span className="consent-checkbox-text">
                {t("brand:글로벌_사전등록_프로세스:preregi_g4")}
              </span>
            </label>
            {showWarning && (
              <span className="consent-warning">
                {t("brand:글로벌_사전등록_프로세스:preregi_g7")}
              </span>
            )}
          </div>
        </div>

        <div className="buttonBox">
          <button
            onClick={() => {
              if (type === "login") {
                clearAccessToken();
              }
              closePopupById(id);
            }}
            className="cancel"
          >
            {t("brand:글로벌_사전등록_프로세스:preregi_g6")}
          </button>
          <button
            onClick={() => {
              if (!agreePresonal) {
                setShowWarning(true);
                return;
              }

              closePopupById(id);
              agreed();
            }}
          >
            {t("brand:글로벌_사전등록_프로세스:preregi_g5")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoPopupGlobal;

const personalInfoPopupGlobal = css`
  width: 100vw;
  height: 100vh;
  background-color: ${theme.palette.action.disabledBackground};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.dimmer};

  .contents {
    width: 312px;
    min-height: 260px;
    padding: 40px 0px;
    background-color: ${theme.palette.common.white};
    border-radius: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    & h3 {
      color: ${theme.palette.grey[9]};
      ${theme.fonts.buttonB};
      line-height: 18px;
      white-space: pre-wrap;
      padding: 0 30px;
    }

    .desc {
      margin-top: 7px;
      ${theme.fonts.small1R};
      line-height: 23px;
    }

    .consent-title {
      font-size: 14px;
      font-weight: 700;
      line-height: -10;
    }

    .consent-container {
      margin: 12px auto;

      .consent-content {
        ${theme.fonts.small1B};
        line-height: 34px;
        padding: 16px 22px;
        background-color: ${theme.palette.grey[1]};
        text-align: start;
        margin: 0 auto 23px;
        margin-top: 24px;

        & span {
          display: block;
          line-height: 34px;
          padding-left: 28px;
          position: relative;

          &::after {
            display: block;
            content: "";
            width: 4px;
            height: 4px;
            position: absolute;
            border-radius: 50%;
            left: 10px;
            top: 15px;
            background: ${theme.palette.grey[9]};
          }
        }

        & strong {
          display: inline;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .consent-checkbox-container {
        margin-bottom: 57px;
        position: relative;
        padding: 0 30px;

        .consent-checkbox {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          cursor: pointer;

          #consent-checkbox {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          #consent-checkbox:checked ~ .custom-checkbox {
            background-color: ${theme.palette.grey[7]};
          }

          #consent-checkbox:checked ~ .custom-checkbox:after {
            display: block;
          }

          .custom-checkbox {
            position: relative;
            background-color: ${theme.palette.grey[2]};
            border: 2px solid transparent;
            border-radius: 4px;
            width: 24px;
            padding: 12px;
            margin-right: 8px;
            position: relative;
            top: 3px;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }

          .custom-checkbox:after {
            background: url(${fromCDN("brand/svg/Check_32.svg")});
            width: 24px;
            height: 24px;
            left: 0;
            top: 0px;
          }

          .consent-checkbox-text {
            font-weight: 700;
            font-size: 14px;
            color: ${theme.palette.grey[9]};
            text-align: left;
            line-height: 20px;
          }

          .focusedBox {
            border: 2px solid ${theme.palette.grey[7]};
          }
        }
      }
      .consent-checkbox-container-ad {
        margin-bottom: 38px;

        #consent-checkbox-ad {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
        }

        #consent-checkbox-ad:checked ~ .custom-checkbox {
          background-color: ${theme.palette.grey[7]};
        }

        #consent-checkbox-ad:checked ~ .custom-checkbox:after {
          display: block;
        }
      }

      .consent-warning {
        position: absolute;
        bottom: -24px;
        font-weight: 700;
        font-size: 12px;
        color: ${theme.palette.error.main};
        padding-left: 30px;
        padding-right: 30px;
        word-break: break-word;
        left: 0;
        text-align: left;
      }
    }

    strong {
      font-weight: 700;
    }

    .red {
      font-weight: 700;
      font-size: 14px;
      color: ${theme.palette.error.main};
    }

    .buttonBox {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;

      & button {
        width: 120px;
        height: 50px;
        border-radius: 10px;
        background-color: ${theme.palette.primary.main};
        ${theme.fonts.small1B};
        color: ${theme.palette.grey[9]};

        &.cancel {
          background-color: ${theme.palette.grey[1]};
        }
      }
    }
  }

  .JPpopup,
  .ENpopup {
    .consent-warning {
      bottom: -28px !important;
    }
  }
`;
