import PopupTitle from "@modules/Brand/view/components/PopupTitle";
import PopupDesc from "@modules/Brand/view/components/PopupDesc";
import PopupButton from "@modules/Brand/view/components/PopupButton";
import Popup from "@modules/Brand/view/components/NewPopup";
import PreregisterPopup from "@modules/Brand/view/PreRegister/PreregisterPopup";
import ErrorPopup from "@modules/Brand/view/components/ErrorPopup";
import { Trans, useTranslation } from "react-i18next";
import useScrollLock from "@hooks/useScrollLock";
import PreregisterPopupGlobal from "@modules/Brand/view/components/PersonalInfoPopupGlobal";
import i18n from "src/utiles/i18n";

export function usePreRegistrationPopups({
  closePopupById,
}: {
  closePopupById: (id: string) => void;
}) {
  const { t } = useTranslation();

  /* 미로그인 상태일 경우 팝업 */
  const LoginPopup = () => {
    const id = "pre-register-login-popup";
    useScrollLock();

    return (
      <Popup id={id} className={"pre-login-popup popupContainer"}>
        <PopupTitle>{t("brand:한국_사전등록_프로세스:preregi_k13")}</PopupTitle>
        <PopupDesc>
          <p>
            <Trans components={{ strong: <strong /> }}>
              {t("brand:한국_사전등록_프로세스:preregi_k14")}
            </Trans>
          </p>
        </PopupDesc>
        <PopupButton count="single">
          <button
            onClick={() => {
              // goToLogin();
              closePopupById(id);
            }}
            className="button"
          >
            {t("brand:한국_사전등록_프로세스:preregi_k9")}
          </button>
        </PopupButton>
      </Popup>
    );
  };

  /* 사전등록하기 개인정보 수집동의 팝업 - 국내 IP일 경우 */
  const ConsentPopup = ({
    approveConsent,
  }: {
    approveConsent: () => Promise<void>;
  }) => {
    const id = "consent-popup-id";
    useScrollLock();

    return (
      <PreregisterPopup
        id={id}
        approveConsent={approveConsent}
        closePopupById={closePopupById}
      />
    );
  };

  /* 사전등록하기 개인정보 수집동의 팝업 - 글로벌 IP일 경우 */
  const ConsentPopupGlobal = ({
    approveConsent,
  }: {
    approveConsent: () => Promise<void>;
  }) => {
    const id = "consent-popup-id";
    useScrollLock();

    return (
      <PreregisterPopupGlobal
        id={id}
        type={"preregister"}
        agreed={approveConsent}
        closePopupById={closePopupById}
      />
    );
  };

  /* 사전등록 완료 팝업 */
  const RegistrationCompletePopup = () => {
    const id = "registration-popup-complete-id";
    useScrollLock();

    return (
      <Popup id={id} className={"regist-complete-popup popupContainer"}>
        <PopupTitle>{t("brand:한국_사전등록_프로세스:preregi_k15")}</PopupTitle>
        <PopupDesc>
          <span style={{ fontWeight: 700 }}>
            {t("brand:한국_사전등록_프로세스:preregi_k16")}
          </span>
        </PopupDesc>
        <PopupButton count="single">
          <button onClick={() => closePopupById(id)} className="button">
            {t("brand:한국_사전등록_프로세스:preregi_k9")}
          </button>
        </PopupButton>
      </Popup>
    );
  };

  /* URL 복사 팝업 */
  const CopyPopup = () => {
    const id = "copy-popup-id";
    useScrollLock();

    return (
      <Popup id={id} className={"copy-popup popupContainer"}>
        <span className="smallText">
          {t("brand:사전등록_이벤트:register_e7")}
        </span>

        <PopupDesc>
          <span className={`urlShareText ${i18n.language}`}>
            <Trans components={{ strong: <strong /> }}>
              {t("brand:사전등록_이벤트:register_e8")}
            </Trans>
          </span>
        </PopupDesc>

        <PopupButton count="single">
          <button
            className="button"
            onClick={() => {
              closePopupById(id);
            }}
          >
            {t("brand:한국_사전등록_프로세스:preregi_k9")}
          </button>
        </PopupButton>
      </Popup>
    );
  };

  /* 에러 팝업 */
  const CommonErrorPopup = () => {
    const id = "error-popup";
    useScrollLock();

    return <ErrorPopup id={id} closePopupById={closePopupById} />;
  };

  return {
    LoginPopup,
    ConsentPopup,
    RegistrationCompletePopup,
    CommonErrorPopup,
    ConsentPopupGlobal,
    CopyPopup,
  };
}
