import { useTranslation } from "react-i18next";
import LoginPopupLocal from "@modules/Brand/view/Login/LoginLocal";
import LoginPopupGlobal from "@modules/Brand/view/components/PersonalInfoPopupGlobal";
import ErrorPopup from "@modules/Brand/view/components/ErrorPopup";
import CommonPopup from "@modules/Brand/view/components/CommonPopup";
import { css, SerializedStyles } from "@emotion/react";

export function useLoginPopups({
  closePopupById,
  clearPopups,
}: {
  closePopupById: (id: string) => void;
  clearPopups?: () => void;
}) {
  const { t, i18n } = useTranslation();

  /* 최초로그인 개인정보동의 팝업 - 국내 IP */
  const FirstLoginPopupLocal = ({
    agreed,
    clearPopups,
    cancel,
  }: {
    agreed: () => void;
    clearPopups: () => void;
    cancel: () => void;
  }) => {
    const id = "first-login-popup-local";
    return (
      <LoginPopupLocal
        id={id}
        closePopupById={closePopupById}
        agreed={agreed}
        clearPopups={clearPopups}
        cancel={cancel}
      />
    );
  };

  /* 최초로그인 개인정보동의 팝업 - 글로벌 IP */
  const FirstLoginPopupGlobal = ({ agreed }: { agreed: () => void }) => {
    const id = "first-login-popup-global";
    return (
      <LoginPopupGlobal
        type={"login"}
        agreed={agreed}
        id={id}
        closePopupById={closePopupById}
      />
    );
  };

  /* IP 변동에 따른 재로그인 안내 팝업 */
  const IPChangedPopup = ({
    confirm,
    clearPopups,
  }: {
    confirm: () => void;
    clearPopups: () => void;
  }) => {
    const id = "user-ip-changed-popup";

    return (
      <CommonPopup
        id={id}
        closePopupById={closePopupById}
        message={[t("brand:팝업:popup1")]}
        clearPopups={clearPopups}
        confirm={() => {
          closePopupById(id);
          confirm();
        }}
      />
    );
  };

  /* 에러 팝업 */
  const LoginErrorPopup = () => {
    const id = "login-error-popup";
    return <ErrorPopup id={id} closePopupById={closePopupById} />;
  };

  /* 트위터/페이스북 인앱브라우저 환경에서 라인 로그인 시도시 */
  const LoginNoticeInAppBrowserPopup = ({
    clearPopups,
  }: {
    clearPopups: () => void;
  }) => {
    const id = "login-notice-in-app-browser-popup";
    const lang = i18n.language;

    return (
      <CommonPopup
        id={id}
        closePopupById={closePopupById}
        message={[t("brand:글로벌_로그인_프로세스:login_g10")]}
        clearPopups={clearPopups}
        confirm={() => {
          closePopupById(id);
        }}
        custom={css`
          .contents {
            width: 312px;

            span {
              line-height: ${lang === "TH" ? "19.31px" : "23.17px"};
            }

            .innerContent {
              margin-bottom: 24px;
              padding: 0 20px;

              & span {
                display: block;
              }
              & span:nth-of-type(1) {
                margin-top: 24px;
                margin-bottom: 10px;
              }
            }
          }
        `}
      />
    );
  };

  return {
    FirstLoginPopupLocal,
    FirstLoginPopupGlobal,
    LoginErrorPopup,
    IPChangedPopup,
    LoginNoticeInAppBrowserPopup,
  };
}
