/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { fromCDN } from "@common/assets/utils";
import { zIndex } from "@common/constants";
import { useTranslation } from "react-i18next";

const Rotate = fromCDN("brand/images/rotate.png");

const RotateNotice = () => {
  const { t } = useTranslation();
  return (
    <section css={style}>
      <img src={Rotate} alt="roatate icon" className="rotateIcon" />
      <p className="title">{t("brand:공통:common1")}</p>
    </section>
  );
};

export default RotateNotice;

const style = css`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0b0b10cc;
  opacity: 0.8;
  z-index: ${zIndex.rotateDimmed};

  .rotateIcon {
    width: 32px;
    height: 32px;
    margin-bottom: 12px;
  }

  .title {
    color: ${theme.palette.grey[3]};
    ${theme.fonts.small1B};
    line-height: 23px;
    text-align: center;
    white-space: pre;
  }
`;
