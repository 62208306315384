/** @jsxImportSource @emotion/react */
import React, { SetStateAction, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { fromCDN } from "@assets/utils";
import theme from "@common/styles/theme";
import { useTranslation } from "react-i18next";
import { language, route, zIndex } from "@common/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  brandGameMenu,
  brandSocial,
} from "@modules/Brand/model/static/MenuModel";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentSectionState,
  SectionEnum,
  sectionSlideState,
} from "@modules/Brand/recoil/section";
import { clearAccessToken } from "@modules/Brand/model";
import SVGComponent from "@modules/Brand/view/components/SVGComponent";
import useDevice from "@hooks/useDevice";
import { useScrollTo } from "@hooks/useScrollTo";
import LangDropdown from "./LangDropdown";
import Dropdown, { MenuItem } from "./DropDown";

const Close = fromCDN("brand/images/close_40.png");
const LangIcon = fromCDN("brand/svg/lang.svg");
const UserIcon = fromCDN("brand/svg/user.svg");
const OutLink = fromCDN("brand/svg/Outlink.svg");
const MainLogo = fromCDN("brand/images/main_logo.png");
const MAIN_LOGO_JP = fromCDN("brand/images/logo_jp.png");
const MAIN_LOGO_TW = fromCDN("brand/images/logo_tw.png");
const MAIN_LOGO_TH = fromCDN("brand/images/logo_th.png");
const Arrowup = SVGComponent(fromCDN("brand/svg/up_.svg"));
const Arrowdown = SVGComponent(fromCDN("brand/svg/down_.svg"));
const ArrowdownLang = SVGComponent(fromCDN("brand/svg/arrow_down.svg"));
const ArrowupLang = SVGComponent(fromCDN("brand/svg/arrow_up.svg"));
const ArrowupHighlight = SVGComponent(fromCDN("brand/svg/up_yellow.svg"));
const ArrowdownHighlight = SVGComponent(fromCDN("brand/svg/down_yellow.svg"));

type Props = {
  style: string;
  selectedSort: string;
  isAuthorized: boolean | null;
  isMobileMenuOn: boolean;
  setIsMobileMenuOn: React.Dispatch<SetStateAction<boolean>>;
};

function MobileMenu({
  setIsMobileMenuOn,
  style,
  isAuthorized,
  selectedSort,
  isMobileMenuOn,
}: Props) {
  const { scrollTo } = useScrollTo();
  const [langDropdown, setLangDropdown] = useState(false);
  const [twoDepth, setTwoDepth] = useState([false, false, false, false, false]);
  const sectionSlide = useRecoilValue(sectionSlideState);
  const [currentSection, setCurrentSection] =
    useRecoilState(currentSectionState);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isMobile } = useDevice();
  const location = useLocation();

  useEffect(() => {
    setLangDropdown(false);
    setTwoDepth([false, false, false, false, false]);
  }, [isMobileMenuOn]);

  const showTwoDepth = (index: number) => {
    let newArr = [...twoDepth];
    for (let i = 0; i < newArr.length; i++) {
      if (index === i) {
        if (newArr[i] === true) {
          newArr[i] = false;
          break;
        }
        newArr[i] = true;
      } else {
        newArr[i] = false;
      }
    }
    setTwoDepth([...newArr]);
  };

  const handleClick = (item: MenuItem) => async () => {
    await navigate(route.home);
    await setCurrentSection(item.sectionMap as number);
    await goToSection(item.sectionMap as number);
    await setTwoDepth(twoDepth.map(() => false));
  };

  const goToSection = (section: number) => {
    const panels = Array.from(document.querySelectorAll<HTMLElement>(".panel"));
    const panelTops = panels.map(panel => panel.offsetTop);
    const defaultIdx = section - 1; // sectionMap 과 panels 의 index 에 차이가 있음

    if (13 <= defaultIdx) {
      scrollTo({
        to: panelTops[defaultIdx - 3],
      });
    } else if (10 <= defaultIdx) {
      const preSaleSection = Array.from(
        document.querySelectorAll<HTMLElement>(".panel"),
      )[9];
      let movePosition = preSaleSection.offsetTop;

      const firstRef = document.getElementById("firstPhase");
      const secondRef = document.getElementById("secondPhase");

      const { height: firstRefHeight } = firstRef!.getBoundingClientRect();
      const { height: secondRefHeight } = secondRef!.getBoundingClientRect();

      if (section === 12) {
        movePosition += firstRefHeight;
      } else if (section === 13) {
        movePosition += firstRefHeight;
        movePosition += secondRefHeight;
      }

      scrollTo({
        to: isMobile ? movePosition - 60 : movePosition - 80,
      });
    } else if (8 <= defaultIdx) {
      // 사전등록혜택1,2 때문에 section6,7의 offset이 중복되어 있다.
      scrollTo({
        to: panelTops[defaultIdx - 1],
      });
    } else {
      scrollTo({
        to: panelTops[defaultIdx],
      });
    }

    setIsMobileMenuOn(false);
  };

  const handleLogin = () => {
    setCurrentSection(-1);

    // 모바일 메뉴 로그인 로그아웃
    if (isAuthorized) {
      clearAccessToken();
      navigate(route.home);
      navigate(0);
    } else {
      navigate(route.brand.loginPage);
    }
  };

  return (
    <div css={styleContainer}>
      <div className={style} id="mobileMenu">
        <button
          css={closeBtn}
          aria-label="mobile menu"
          onClick={() => setIsMobileMenuOn(false)}
        ></button>
        <div className="container">
          <img
            className="logo"
            alt="logo"
            src={
              i18n.language === "JP"
                ? MAIN_LOGO_JP
                : i18n.language === "TW"
                ? MAIN_LOGO_TW
                : i18n.language === "TH"
                ? MAIN_LOGO_TH
                : MainLogo
            }
          />
          <section>
            <ul className="mobileMenuList">
              <li className="twoDepth">
                <button onClick={() => showTwoDepth(0)}>
                  <span
                    className={
                      sectionSlide === SectionEnum.MAIN ? "highlight" : ""
                    }
                  >
                    {t("brand:gnb:nav1")}{" "}
                  </span>
                  {twoDepth[0] ? (
                    sectionSlide === SectionEnum.MAIN ? (
                      <ArrowupHighlight />
                    ) : (
                      <Arrowup />
                    )
                  ) : sectionSlide === SectionEnum.MAIN ? (
                    <ArrowdownHighlight />
                  ) : (
                    <Arrowdown />
                  )}
                </button>
                <Dropdown
                  MenuItems={brandGameMenu(t)}
                  ClassName={`twoDepthMenu ${twoDepth[0] && "show"}`}
                  handleClick={handleClick}
                />
              </li>
              {/* <li
                className={
                  sectionSlide === SectionEnum.PRE_REGISTER ? "highlight" : ""
                }
                onClick={async () => {
                  await navigate(route.home);
                  await setCurrentSection(5);
                  await goToSection(5);
                }}
              >
                <span style={{ padding: "0" }}>{t("brand:gnb:nav2")}</span>
              </li> */}
              {/* 글로벌 블록체인 버전 - 시작 */}
              {/* <li
                className={sectionSlide === SectionEnum.PNE ? "highlight" : ""}
                onClick={async () => {
                  await navigate(route.home);
                  await setCurrentSection(9);
                  await goToSection(9);
                }}
              >
                {t("brand:gnb:nav3")}
              </li>
              <li className="twoDepth">
                <button
                  onClick={() => {
                    showTwoDepth(1);
                  }}
                >
                  <span
                    className={
                      sectionSlide === SectionEnum.PRE_SALE ? "highlight" : ""
                    }
                  >
                    {t("brand:gnb:nav4")}{" "}
                  </span>
                  {twoDepth[1] ? (
                    sectionSlide === SectionEnum.PRE_SALE ? (
                      <ArrowupHighlight />
                    ) : (
                      <Arrowup />
                    )
                  ) : sectionSlide === SectionEnum.PRE_SALE ? (
                    <ArrowdownHighlight />
                  ) : (
                    <Arrowdown />
                  )}
                </button>
                <Dropdown
                  MenuItems={brandSalesMenu(t)}
                  ClassName={`twoDepthMenu ${twoDepth[1] && "show"}`}
                  handleClick={handleClick}
                />
              </li> */}
              {/* 글로벌 블록체인 버전 - 끝 */}
              <li className="twoDepth">
                <Link
                  to={route.coupon.main}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t("brand:gnb:nav5")}</span>
                  <span className="outLink" />
                </Link>
              </li>
              {/* 글로벌 블록체인 버전 - 시작 */}
              {/* <li className="twoDepth">
                <Link
                  to={route.market.main}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t("brand:gnb:nav6")}</span>
                  <span className="outLink" />
                </Link>
              </li> */}
              {/* 글로벌 블록체인 버전 - 끝 */}
              {/* <li className="twoDepth">
                <Link
                  onClick={() => setCurrentSection(-1)}
                  to={isAuthorized ? route.brand.myPage : route.brand.loginPage}
                  rel="noopener noreferrer"
                >
                  <span
                    className={
                      location.pathname === route.brand.myPage
                        ? "highlight"
                        : ""
                    }
                  >
                    My Page
                  </span>
                </Link>
              </li> */}
            </ul>
          </section>
          <div className="socialContainer">
            {brandSocial[i18n.language as keyof typeof brandSocial]?.map(i => {
              return (
                <img
                  onClick={() => window.open(i.url, "_blank")}
                  key={i.title}
                  src={i.social}
                  alt={i.title}
                />
              );
            })}
          </div>
          <div className="userContainer">
            <button
              className="btn"
              onClick={() => setLangDropdown(!langDropdown)}
            >
              <img className="langIcon" src={LangIcon} alt="Langague Icon" />
              <span className="text">{selectedSort}</span>
              {langDropdown ? (
                <ArrowupLang className="notActiveIcon arrow" />
              ) : (
                <ArrowdownLang className="notActiveIcon arrow" />
              )}
            </button>
            {langDropdown && (
              <LangDropdown
                ClassName={"langBox"}
                isOpen={langDropdown}
                setOpenDropdown={setLangDropdown}
                dropdownItems={language}
                selectedItem={selectedSort}
              />
            )}
            {/* <button className="btn loginBtn" onClick={() => handleLogin()}>
              <img
                className={`${
                  isAuthorized ? "auth" : ""
                } loginIcon notActiveIcon`}
                src={UserIcon}
                alt="User Icon"
              />
              {isAuthorized ? (
                <span className="text">LOGOUT</span>
              ) : (
                <span className="text">LOGIN</span>
              )}
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;

const styleContainer = css`
  & a {
    display: block;
    text-decoration: none;
    color: ${theme.palette.grey[1]};
  }

  & span {
    cursor: pointer;
    line-height: normal;
    font-weight: 700;
    font-size: clamp(24px, 5.5vw, 40px);
    color: ${theme.palette.grey[1]};
  }

  .notActiveIcon {
    filter: invert(99%) sepia(10%) saturate(28%) hue-rotate(270deg)
      brightness(118%) contrast(91%);
  }

  .activeIcon {
    filter: invert(97%) sepia(67%) saturate(6234%) hue-rotate(332deg)
      brightness(100%) contrast(102%);
  }

  .highlight {
    color: ${theme.palette.primary.main} !important;

    & span {
      color: ${theme.palette.primary.main} !important;
    }
  }

  .brandMobileMenu {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    z-index: ${zIndex.mobileMenu};
    ${theme.fonts.subCopyB};
    ${theme.mixins.displayCenter}
    ${theme.mixins.removeScrollBar};
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.5s ease;
    background: rgba(28, 26, 39, 0.9);

    .outLink {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(${OutLink});
      background-size: cover;
      background-repeat: no-repeat;
      margin-left: 4px;
    }

    .container {
      padding: 62px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      flex-wrap: nowrap;
      color: ${theme.palette.grey[1]};
      width: 100%;
      overflow-y: auto;
    }

    .container section {
      width: 100%;
    }

    .logo {
      width: clamp(150px, 27.7vw, 200px);
      margin-bottom: clamp(48px, 11.1vw, 80px);
    }

    .mobileMenuList {
      text-align: center;
      color: ${theme.palette.grey[1]};
    }

    .mobileMenuList li {
      margin-bottom: 30px;

      &:nth-last-of-type(1) {
        margin-bottom: 0px;
      }

      & span {
        padding-left: 10px;
      }

      &:nth-last-of-type(1) span {
        padding-left: 0px;
      }

      & svg {
        margin-left: 4px;
      }

      &:nth-of-type(7) {
        margin-bottom: 0;
      }
    }

    .socialContainer {
      gap: 24px;
      width: 100%;
      padding: 20px 0;
      margin: 48px 0;
      ${theme.mixins.displayCenter}
      background: rgba(67, 67, 87, 0.8);

      img {
        width: clamp(40px, 6.6vw, 48px);
        height: clamp(40px, 6.6vw, 48px);
      }
    }

    .userContainer {
      width: 200px;
      height: 140px;
      display: flex;
      position: relative;
      flex-direction: column;
    }

    .userContainer div {
      position: relative;
    }

    .btn {
      width: 200px;
      height: 60px;
      position: relative;
      border-radius: 40px;
      margin-bottom: 20px;
      border: 1px solid ${theme.palette.grey[4]};
      -webkit-appearance: none; /* iOS의 기본 스타일 무시 */
      appearance: none;

      .arrow {
        width: 8px;
        height: 8px;
      }

      .langIcon {
        position: absolute;
        left: 49px;
        top: 18px;
      }

      .text {
        position: absolute;
        top: 18px;
        left: calc(50% + 10px);
        transform: translateX(-50%);
      }

      .arrow {
        position: absolute;
        right: 49px;
        top: 26px;
      }
    }

    .btn span {
      ${theme.fonts.small1B};
      line-height: 24px;
    }

    .loginBtn {
      margin-bottom: 0;
      background: ${theme.palette.grey[7]};
      border: none;
      gap: 13px;

      .loginIcon {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 49px;
        top: 18px;

        &.auth {
          left: 39px;
        }
      }

      .text {
        position: absolute;
        top: 18px;
        left: calc(50% + 10px);
        transform: translateX(-50%);
      }
    }

    .twoDepth {
      & img {
        width: 8px;
        position: relative;
        top: -6px;
        right: -3px;
      }
    }

    .twoDepthMenu {
      height: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
      transition: all 0.3s ease;
      background: rgba(67, 67, 87, 0.8);

      &.show {
        height: auto;
        margin-top: 30px;
        padding: 8px;
      }
    }

    .twoDepthMenu li {
      margin: 0;
      width: 100%;
      cursor: pointer;
      margin-bottom: 20px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }

    .langBox {
      position: absolute;
      width: 200px;
      top: -240px;
      border-radius: 10px;
      border: 1px solid #d6d6da;
      z-index: ${zIndex.mobileMenu};
      background-color: ${theme.palette.grey[3]};
    }

    .langBox li {
      width: 100%;
      background: #fff;
      color: ${theme.palette.grey[9]} !important;
      ${theme.fonts.small2B};
      padding: 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #d6d6da;
    }

    .langBox li:nth-of-type(1) {
      border-radius: 10px 10px 0 0;
      border-top: none;
    }

    .langBox li:nth-last-of-type(1) {
      border-radius: 0 0 10px 10px;
      border-bottom: none;
    }
  }

  .activeMenu {
    transform: translateX(0%);
  }

  @media ${theme.devices.tablet} {
    .brandMobileMenu {
      ${theme.fonts.subTitleB};

      & span {
        color: ${theme.palette.grey[1]};
        font-size: 40px;
      }

      .logo {
        width: clamp(200px, 20.8vw, 250px);
        margin-bottom: 80px;
      }

      .outLink {
        position: relative;
        width: 24px;
        height: 24px;
        top: -7px;
      }

      .container {
        padding: 90px 0;
      }

      .mobileMenuList {
        & li {
          margin-bottom: 40px;
        }

        & svg {
          width: 24px;
          height: 24px;
          position: relative;
          top: 2px;
        }
      }

      .userContainer {
        width: 260px;
        height: 70px;
        /* gap: 24px;
        flex-direction: row; */
      }

      .langBox {
        left: 15px;
      }

      .btn {
        width: 260px;
        height: 70px;
        line-height: 24px;
        margin-bottom: 0px;

        .langIcon {
          left: 69px;
          top: 23px;
        }
        .text {
          top: 23px;
        }

        .arrow {
          right: 69px;
          top: 30px;
        }
      }

      .loginBtn {
        .loginIcon {
          left: 69px;
          top: 23px;

          &.auth {
            left: 59px;
          }
        }
      }

      .twoDepth {
        & img {
          width: 16px;
          top: -10px;
          right: -3px;
        }
      }

      .twoDepthMenu {
        &.show {
          margin-top: 40px;
          padding: 30px 0px;
        }
      }

      .twoDepthMenu li {
        padding: 0px;
        margin-bottom: 40px;
      }

      .twoDepthMenu li a {
        font-size: 30px;
      }

      .socialContainer {
        height: 90px;
        gap: 32px;
        margin: 80px 0 48px;

        img {
          width: clamp(48px, 5.3vw, 64px);
          height: clamp(48px, 5.3vw, 64px);
        }
      }
    }
  }

  @media ${theme.devices.laptop} {
    .brandMobileMenu {
      .logo {
        margin-bottom: 60px;
      }

      .container {
        padding: 96px 0 120px;
      }

      .socialContainer {
        gap: 48px;
        margin: 120px 0 48px;
      }
    }
  }
`;

const closeBtn = css`
  position: absolute;
  background: transparent;
  border: none;
  top: 24px;
  right: 24px;
  cursor: pointer;
  width: clamp(40px, 6.6vw, 48px);
  height: clamp(40px, 6.6vw, 48px);
  background: url(${Close}) !important;
  z-index: 10000;

  @media ${theme.devices.tablet} {
    width: clamp(48px, 4.6vw, 56px);
    height: clamp(48px, 4.6vw, 56px);
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  @media ${theme.devices.laptop} {
    top: 40px;
    right: 40px;
  }
`;
