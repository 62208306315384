/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import "slick-carousel/slick/slick.css";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";
import theme, { getDeviceSize } from "@common/styles/theme";
import {
  banners,
  clouds,
  firstSliderImages,
  secondSliderImages,
  thirdSliderImages,
} from "@modules/Brand/model/static/GameIntroImage";
import { fromCDN } from "@assets/utils";
import { useTranslation } from "react-i18next";
import useDevice from "@hooks/useDevice";

const Left = fromCDN("brand/svg/left_button.svg");
const LeftHover = fromCDN("brand/svg/left_hover.svg");

const GameIntro = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const { t, i18n } = useTranslation();
  const { fromLapTop } = useDevice();

  const handleOnClick = (index: number) => {
    sliderRef?.current?.slickGoTo(index);
  };

  const imgPreload = (imageUrls: any) => {
    const imagePromises = imageUrls.map(({ image }: any) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    return Promise.all(imagePromises);
  };

  const videoPreload = (videoUrl: any) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.src = videoUrl;
      video.preload = "auto";
      video.oncanplaythrough = resolve;
      video.onerror = reject;
    });
  };

  useLayoutEffect(() => {
    const allSliderImages = [
      ...firstSliderImages,
      ...secondSliderImages,
      ...thirdSliderImages,
    ];

    imgPreload(allSliderImages);
  }, []);

  const settings: Settings = {
    adaptiveHeight: false,
    dots: true,
    draggable: true,
    swipe: true,
    centerPadding: "0px",
    infinite: true,
    vertical: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    prevArrow: <ArrowButton type="PREV" />,
    nextArrow: <ArrowButton type="NEXT" />,
    afterChange: current => {
      setActiveSlide(current);
    },
    responsive: [
      {
        breakpoint: getDeviceSize("mobile"),
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const tabs = [
    t("brand:게임소개:intro4"),
    t("brand:게임소개:intro5"),
    t("brand:게임소개:intro6"),
  ];

  const contents = [
    t("brand:게임소개:intro7"),
    t("brand:게임소개:intro8"),
    t("brand:게임소개:intro9"),
  ];

  const breakPoint = fromLapTop ? "pc" : "mb";

  return (
    <section css={gameIntro(i18n.language)}>
      <div css={style}>
        <h2>{t("brand:게임소개:intro3")}</h2>
        <ul css={tabMenu(i18n.language)}>
          {tabs.map((i, idx) => {
            return (
              <li
                onClick={() => {
                  setActiveSlide(idx);
                  handleOnClick(idx);
                }}
                key={i}
              >
                <button className={activeSlide === idx ? "active" : ""}>
                  {i}
                </button>
              </li>
            );
          })}
        </ul>
        <p key={activeSlide}>{contents[activeSlide][breakPoint as any]}</p>
        <div className="sliderWrap">
          <Slider {...settings} ref={sliderRef}>
            {banners.map((i, idx) => {
              return (
                <video
                  key={`${idx}_${i}`}
                  poster={i.poster}
                  className="gifsize"
                  preload="metatag"
                  playsInline={true}
                  autoPlay
                  loop
                  muted
                >
                  <source src={i.banner}></source>
                </video>
              );
            })}
          </Slider>
        </div>
        {activeSlide === 0 ? (
          <div className={i18n.language} css={backgroundImage}>
            {firstSliderImages.map((i, idx) => {
              return <img src={i.image} key={idx} alt="소환" />;
            })}
          </div>
        ) : null}
        {activeSlide === 1 ? (
          <div className={i18n.language} css={backgroundImage}>
            {secondSliderImages.map((i, idx) => {
              return (
                <img
                  src={i.image}
                  key={idx}
                  className={i.className}
                  alt="육성"
                />
              );
            })}
          </div>
        ) : null}
        {activeSlide === 2 ? (
          <div className={i18n.language} css={backgroundImage}>
            {thirdSliderImages.map((i, idx) => {
              return (
                <img
                  src={i.image}
                  key={idx}
                  className={i.className}
                  alt="마을"
                />
              );
            })}
          </div>
        ) : null}
        <div css={cloud}>
          {clouds.map((i, idx) => {
            return <img src={i} key={idx} alt="구름" />;
          })}
        </div>
      </div>
    </section>
  );
};

const ArrowButton = ({
  currentSlide,
  slideCount,
  children,
  type,
  ...props
}: Record<string, unknown> & { type: "PREV" | "NEXT" }) => {
  return <div {...props} />;
};

export default GameIntro;

const floating = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const effectAnimation = keyframes`
  0% {
    opacity:1;
  }
  50% {
    opacity:0;
  }
  100% {
    opacity:0.990099773451273;
  }
  101% {
    opacity:0.99;
  }
`;

const opacityAnimation = keyframes`
  0% {
    opacity:0; 
  }
  100% {
opacity: 1;
  }
`;

const textAnimation = keyframes`
  0% {
    opacity:0; 
    transform: translatey(10px);
  }
  100% {
    opacity: 1;
    transform: translatey(0px);
  }
`;

const gameIntro = (lang: string) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 0 40px 0;

  .effects {
    animation: ${effectAnimation} linear 4s infinite;
  }

  & h2 {
    font-style: normal;
    font-size: clamp(36px, 10vw, 48px);
    line-height: 1;
    ${theme.applyGangwonEduFont[lang as keyof typeof theme.applyGangwonEduFont]}
  }

  & p {
    white-space: pre;
    ${theme.fonts.small1B};
    line-height: 24px;
    margin: 0 auto;
    height: 100px;
    animation: ${textAnimation} 2s;
    position: relative;
    z-index: 10;
  }

  .pc {
    display: none;
  }

  @media ${theme.devices.tablet} {
    height: 1050px;
    padding: 80px 0 40px 0;

    & h2 {
      font-size: 60px;
      line-height: 62px;
    }

    & p {
      padding: ${["EN", "JP"].includes(lang) && "0"};
      max-width: ${["EN", "JP"].includes(lang) && "unset"};
      ${theme.fonts.subCopyB};
      line-height: 26px;
      height: 130px;
      margin-bottom: 45px;
      white-space: ${["EN", "JP"].includes(lang) && "pre"};
    }
  }

  @media ${theme.devices.laptop} {
    height: calc(100vh - 80px);
    padding: 0;

    p {
      height: 55px;
      margin-bottom: 50px;
      /* width: 600px; */
    }
  }

  @media ${theme.devices.laptop} and (${theme.resolutionHeight.height1}) {
    p {
      height: 80px;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 22px;
    }
  }

  @media ${theme.devices.desktop} {
    height: 100vh;
    overflow: unset;

    h2 {
      line-height: 61.8px;
    }

    p {
      ${theme.fonts.subCopyB};
      margin-bottom: 50px;
    }

    br {
      display: none;
    }

    .pc {
      display: block;
    }
  }
`;

const style = css`
  color: ${theme.palette.common.white};
  position: relative;
  text-align: center;

  & video {
    display: block !important;
  }

  .sliderWrap {
    position: relative;
    z-index: 999;
    width: 80%;
    margin: 0 auto;
    min-width: 250px !important;
    max-width: 600px;
  }

  .slick-slider {
    z-index: 10;
    margin-top: 250px;
    padding-bottom: 30px;
    width: 100%;
  }

  .slick-list {
    padding: 0 1px !important;
  }

  .slick-track {
    width: 100%;
    height: 100%;
  }

  .slick-current {
    margin-left: 0px;
  }

  .slick-dots {
    height: 10px;
    bottom: 10px;

    & li {
      margin: 0 2px;
      width: unset;
      height: unset;
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        background-color: ${theme.palette.common.white};
        border-radius: 50%;
        transition: all ease 0.5s 0s;
        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          width: 30px;
          height: 10px;
          background-color: ${theme.palette.primary.main};
          border-radius: 30px;
        }
      }
    }
  }

  .gifsize {
    border: 4px solid ${theme.palette.common.white};
    border-radius: 20px;
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 99% !important;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-prev,
  .slick-next {
    display: none;
  }

  @media ${theme.devices.tablet} {
    & p {
      margin-bottom: 97px;
    }

    .sliderWrap {
      width: 600px;
      margin: 0 auto;
    }

    .slick-slider {
      margin-top: 0;
    }

    .slick-current {
      margin-left: 2px;
    }

    .slick-prev,
    .slick-next {
      display: none !important;
    }

    .slick-dots {
      height: 14px;
      bottom: 30px;

      & li {
        margin: 0 4px;
        button {
          width: 14px;
          height: 14px;
        }

        &.slick-active {
          button {
            width: 44px;
            height: 14px;
          }
        }
      }
    }

    .slick-track {
      width: 4800px;
    }

    .slick-slide {
      width: 600px;
    }

    .slick-list {
      height: 400px;
    }

    .gifsize {
      width: 595px !important;
      height: 338px;
      border: 4px solid ${theme.palette.common.white};
      border-radius: 30px;
      margin-left: 50%;
      position: relative;
      box-sizing: border-box;
      left: -300px;
    }
  }

  @media ${theme.devices.laptop} {
    padding-bottom: 50px;

    & p {
      margin-bottom: 50px;
    }

    .slick-list {
      height: 337px;
    }

    .slick-dots {
      bottom: -10px;
    }

    .slick-prev,
    .slick-next {
      display: block !important;
      background: url(${Left}) no-repeat;
      background-size: cover;
      width: 100px;
      height: 100px;
      position: absolute;
      z-index: 999 !important;
      &:before {
        display: none;
      }

      &:hover {
        background: url(${LeftHover}) no-repeat;
      }
    }

    .slick-prev {
      left: -100px;
      top: 180px;
    }

    .slick-next {
      right: -100px;
      top: 180px;
      transform: translate(0, -50%) rotate(180deg);
    }

    .slick-track {
      width: 4800px;
    }

    .slick-slide {
      display: flex;
      justify-content: center;
      width: 600px;
    }

    .gifsize {
      margin-left: 0;
      left: 0;
    }
  }

  @media ${theme.devices.laptop} and (max-height: 800px) {
    padding-bottom: 0;
    h2 {
      margin-top: 0px;
    }

    p {
      height: 80px;
      margin-bottom: 0px;
    }

    .slick-list {
      height: 280px;
    }

    .slick-dots {
      display: block !important;
      bottom: -5px;
    }

    .slick-prev,
    .slick-next {
      top: 150px;
    }

    .gifsize {
      width: 494px !important;
      height: 280px;
    }
  }
`;

const tabMenu = (lang: string) => css`
  ${theme.mixins.displayCenter};
  margin: 24px;
  transition: all ease 0.3s 0s;
  position: relative;
  z-index: 10;

  & button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: rgba(0, 4, 158, 0.5);
    width: 104px;
    height: 60px;
    color: ${theme.palette.grey[2]};
    font-style: normal;
    font-size: 18px;
    line-height: 19px;
    ${theme.applyGangwonEduFont[lang as keyof typeof theme.applyGangwonEduFont]}
  }

  & li {
    position: relative;
  }

  & li::after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    right: -3px;
    top: 28px;
    background: #e8e8e9;
    transform: rotate(45deg);
    z-index: 1;
  }

  & li:nth-of-type(2)::after {
    right: -4px;
  }

  & li:nth-of-type(3)::after {
    display: none;
  }

  & li:nth-of-type(1) button {
    border-radius: 20px 0 0 20px;
    border-right: 1px solid rgba(151, 149, 235, 0.5);
  }

  & li:nth-of-type(3) button {
    border-radius: 0 20px 20px 0;
    border-left: 1px solid rgba(151, 149, 235, 0.5);
  }

  .active {
    background-color: ${theme.palette.secondary.main4};
    color: ${theme.palette.grey[2]};
    transition: all ease 0.5s 0s;
    opacity: 1;
  }

  @media ${theme.devices.tablet} {
    margin: 30px;
    position: relative;
    z-index: 100;

    & button {
      width: 130px;
      height: 70px;
      font-size: 24px;
      line-height: 25px;
    }
  }

  @media ${theme.devices.laptop} and (${theme.resolutionHeight.height1}) {
    margin: 20px;
  }

  @media ${theme.devices.desktop} {
    margin: 19px 0 30px 0;

    & li::after {
      width: 10px;
      height: 10px;
      right: -4px;
      top: 30px;
    }

    & li:nth-of-type(2)::after {
      right: -5.5px;
    }
  }
`;

const backgroundImage = css`
  position: absolute;
  top: 32%;
  left: 50%;
  margin-left: -245px;

  & img {
    display: block;
    position: absolute;
    width: 200px;
    animation: ${floating} 6s ease infinite;
  }

  & img:nth-of-type(1) {
    top: -15px;
    left: 10px;
    animation: ${floating} 4s ease infinite, ${opacityAnimation} 1s;
  }

  &.JP {
    & img:nth-of-type(1) {
      top: 0;
    }
  }

  & img:nth-of-type(2) {
    top: 55px;
    left: 90px;
    z-index: 1;
    animation: ${floating} 5s ease-in-out infinite, ${opacityAnimation} 1s;
  }

  & img:nth-of-type(3) {
    top: 10px;
    left: 230px;
    animation: ${floating} 3s ease-in-out infinite, ${opacityAnimation} 1s;
  }

  & img:nth-of-type(4) {
    width: 128px;
    top: 30px;
    left: 30px;
  }

  & img:nth-of-type(5) {
    width: 163px;
    top: 30px;
    left: 220px;
    animation-delay: 2s;
  }

  .active2_1 {
    left: 220px !important;
  }

  .active2_2 {
    top: 30px !important;
    left: 70px !important;
  }

  .active2_3 {
    top: -60px !important;
    left: 30px !important;
  }

  .effect1_1 {
    width: 150px !important;
    top: 0 !important;
    left: 70px !important;
    animation-delay: 2s;
  }

  .effect1_2 {
    width: 200px !important;
    top: 0 !important;
    left: 220px !important;
  }

  .active3_1 {
    top: 15px !important;
    left: 210px !important;
    z-index: 10 !important;
  }

  .active3_2 {
    top: 0 !important;
    left: 50px !important;
  }

  .active3_3 {
    top: -70px !important;
    left: 130px !important;
  }

  .effect3_1 {
    width: 80px !important;
    left: 350px !important;
    top: -20px !important;
    animation-delay: 2s;
  }

  .effect3_2 {
    width: 40px !important;
    left: 80px !important;
    top: 50px !important;
  }

  .effect3_3 {
    width: 20px;
    left: 260px;
  }

  @media ${theme.devices.tablet} {
    top: 0px;

    & img {
      width: 350px;
    }

    & img:nth-of-type(1) {
      top: -160px;
      left: -150px;
    }

    & img:nth-of-type(2) {
      top: 70px;
      left: -200px;
    }

    & img:nth-of-type(3) {
      top: 30px;
      left: 380px;
    }

    & img:nth-of-type(4) {
      width: 166px;
      top: 70px;
      left: -200px;
      transform: rotate(313deg);
    }

    & img:nth-of-type(5) {
      width: 249px;
      top: 60px;
      left: 440px;
      transform: rotate(45deg);
    }

    .active2_1 {
      top: 30px !important;
      left: 350px !important;
    }

    .active2_2 {
      top: 80px !important;
      left: -260px !important;
    }

    .active2_3 {
      top: -100px !important;
      left: -200px !important;
    }

    .effect1_1 {
      width: 200px !important;
      top: 90px !important;
      left: -70px !important;
    }

    .effect1_2 {
      width: 300px !important;
      top: 70px !important;
      left: 420px !important;
    }

    .active3_1 {
      top: 0 !important;
      left: 350px !important;
      z-index: 1 !important;
    }

    .active3_2 {
      top: 40px !important;
      left: -240px !important;
    }

    .active3_3 {
      top: -180px !important;
      left: -150px !important;
    }

    .effect3_1 {
      width: 60px !important;
      left: 390px !important;
      top: -11px !important;
      transform: rotate(27deg) !important;
    }

    .effect3_2 {
      width: 60px !important;
      left: 0 !important;
      top: 160px !important;
      transform: rotate(9deg) !important;
    }

    .effect3_3 {
      width: 30px;
      left: 80px;
      top: -10px;
    }

    &.EN {
      & img:nth-of-type(1) {
        top: -180px;
        left: -184px;
      }

      & img:nth-of-type(2) {
        top: 146px;
        left: -200px;
      }

      & img:nth-of-type(3) {
        top: -48px;
        left: 447px;
      }

      .active2_1 {
        top: 30px !important;
        left: 369px !important;
      }

      .active3_1 {
        top: 0 !important;
        left: 400px !important;
        z-index: 1 !important;
      }

      .active3_3 {
        top: -180px !important;
        left: -183px !important;
      }
    }
  }

  @media ${theme.devices.laptop} {
    z-index: 10;

    & img {
      width: 430px;
      transform: translatey(0px);
    }

    & img:nth-of-type(1) {
      left: -320px;
    }

    & img:nth-of-type(2) {
      left: -450px;
    }

    & img:nth-of-type(3) {
      top: -50px;
      left: 550px;
      z-index: 5;
    }

    & img:nth-of-type(4) {
      width: 166px;
      top: 70px;
      left: -330px;
      transform: rotate(313deg);
    }

    & img:nth-of-type(5) {
      width: 249px;
      top: 140px;
      left: 500px !important;
      transform: rotate(45deg);
    }

    .active2_1 {
      top: 0 !important;
      left: 550px !important;
    }

    .active2_2 {
      left: -500px !important;
    }

    .active2_3 {
      top: -140px !important;
      left: -290px !important;
    }

    .effect1_1 {
      width: 300px !important;
      top: 20px !important;
      left: -280px !important;
      transform: rotate(1deg) !important;
    }

    .effect1_2 {
      width: 330px !important;
      top: 20px !important;
      left: 500px !important;
      transform: rotate(1deg) !important;
    }

    .active3_1 {
      top: -40px !important;
      left: 550px !important;
      z-index: 1 !important;
    }

    .active3_2 {
      top: 30px !important;
      left: -500px !important;
    }

    .active3_3 {
      left: -330px !important;
    }

    .effect3_1 {
      width: 90px !important;
      left: 590px !important;
      top: 50px !important;
      transform: rotate(351deg) !important;
    }

    .effect3_2 {
      display: none;
    }

    .effect3_3 {
      width: 33px;
      left: -60px;
      top: -30px;
    }

    &.EN {
      & img:nth-of-type(1) {
        left: -320px;
      }

      & img:nth-of-type(2) {
        left: -450px;
      }

      & img:nth-of-type(3) {
        top: -50px;
        left: 550px;
        z-index: 5;
      }

      .active2_1 {
        top: 0 !important;
        left: 550px !important;
      }

      .active3_1 {
        top: -40px !important;
        left: 550px !important;
        z-index: 1 !important;
      }

      .active3_3 {
        left: -330px !important;
      }
    }
  }

  @media ${theme.devices.laptopL} {
    & img:nth-of-type(3) {
      top: 0px;
      left: 550px;
    }

    &.EN {
      & img:nth-of-type(3) {
        top: 0px;
        left: 550px;
      }
    }
  }
`;

const cloud = css`
  width: 100%;
  top: 11%;
  left: 50%;
  z-index: 9;
  position: absolute;
  margin-left: -390px;

  & img {
    width: 800px;
  }

  & img:nth-of-type(2) {
    width: 780px;
    position: relative;
    top: -450px;
    left: -20px;
    z-index: -1;
  }

  @media ${theme.devices.tablet} {
    top: -670px;
    left: 50%;
    margin-left: -1290px;

    & img {
      width: 2200px;
      position: relative;
      top: 180px;
      left: 180px;
    }

    & img:nth-of-type(1) {
      z-index: 20 !important;
    }

    & img:nth-of-type(2) {
      display: block;
      width: 1900px;
      position: absolute;
      top: 300px;
      left: 300px;
      z-index: 1;
    }
  }

  @media ${theme.devices.laptop} {
    position: absolute;
    z-index: auto;

    & img {
      width: 2600px;
      position: relative;
    }

    & img:nth-of-type(1) {
      z-index: 20 !important;
      top: 180px;
      left: -10px;
    }

    & img:nth-of-type(2) {
      width: 2400px;
      top: 270px;
      left: 50px;
    }
  }

  @media ${theme.devices.laptop} and (${theme.resolutionHeight.height1}) {
    & img:nth-of-type(1) {
      top: 50px;
    }
  }

  @media ${theme.devices.laptopL} {
    top: -580px;

    & img:nth-of-type(1) {
      top: 100px;
      left: -10px;
    }

    & img:nth-of-type(2) {
      top: 200px;
    }
  }

  @media ${theme.devices.laptop} and (${theme.resolutionHeight.height1}) {
    top: -700px;
  }

  @media ${theme.devices.desktop} {
    top: -330px;
    display: flex;
    justify-content: center;
    left: 0;
    margin-left: 0;

    & img:nth-of-type(1) {
      width: 100%;
      position: relative;
      top: 120px;
      left: 0;
      z-index: 20 !important;
    }

    & img:nth-of-type(2) {
      display: block;
      width: 100%;
      position: absolute;
      top: 120px;
      left: 0;
    }
  }

  @media ${theme.devices.desktop} and (${theme.resolutionHeight.height1}) {
    top: -368px !important;

    & img:nth-of-type(1) {
      top: -180px;
      width: 121%;
    }
  }

  @media (min-width: 2100px) {
    top: -400px;
  }

  @media (min-width: 2300px) {
    top: -550px;
  }

  @media ${theme.devices.desktopL} {
    display: flex;
    width: 100%;

    & img:nth-of-type(1) {
      top: 100px;
    }

    & img:nth-of-type(2) {
      top: 100px;
    }
  }

  @media ${theme.devices.desktopL} and (${theme.resolutionHeight.height1}) {
    top: -740px !important;
  }
`;
