import { Model } from "@common/apis";
import type { RawAxiosRequestHeaders } from "axios";
import type {
  PreRegistrationApiDrawResult,
  WooparooIdParam,
  PreRegistrationDrawCompleteResult,
  PreRegistrationFacebookTicketResult,
  PreRegistrationRegisterResult,
  PreRegistrationStatusResult,
  PreRegistrationTotalGachaCount,
  PreRegisterRegisterParam,
  PreRegistrationGetGachaInfoResult,
  PreRegistrationWooparooInfo,
  SocialPlatform,
  PreRegistrationCancelResult,
} from "./interface";
import { convertToKoreaTime } from "@common/assets/utils";
import { drawWooparooTranslation, SHARE_KEY } from "@common/constants";
import FacebookSdk from "./facebook-sdk";

export class PreRegistrationModel extends Model {
  private readonly url: string;
  private readonly commonModelConfig: {
    config: { headers: RawAxiosRequestHeaders };
    withToken: boolean;
  };

  constructor() {
    super();
    this.url = "/pre-registration";
    this.commonModelConfig = {
      config: {
        headers: {
          ...this.urlencodedContentType(),
        },
      },
      withToken: true,
    };
  }

  /* 우파루 뽑기 */
  public draw = async () => {
    const response = await this.post<PreRegistrationApiDrawResult>(
      this.url + "/draw",
      null,
      this.commonModelConfig,
    );

    return {
      ...response.data,
      result: this.convertGachaInfoToWooparooInfo(
        response.data.result.drawResult,
      ),
    };
  };

  /* 뽑기권을 사용하여 나온 우파루를 최종 확정 */
  public drawComplete = async (data: WooparooIdParam) => {
    const response = await this.put<PreRegistrationDrawCompleteResult>(
      this.url + "/drawComplete",
      { gachaId: data.wooparooId }, // wooparooId === gachaId
      this.commonModelConfig,
    );

    return response.data;
  };

  /* 페이스북 URL 게시물로 업로드 성공하였을 경우 호출 */
  public facebookTicket = async () => {
    const response = await this.post<PreRegistrationFacebookTicketResult>(
      this.url + "/facebook/ticket",
      null,
      this.commonModelConfig,
    );

    return response.data;
  };

  public register = async (data: PreRegisterRegisterParam) => {
    const response = await this.post<PreRegistrationRegisterResult>(
      this.url + "/register",
      null,
      {
        config: {
          ...this.commonModelConfig,
          params: data,
        },
        withToken: true,
      },
    );

    return response.data;
  };

  public status = async () => {
    const response = await this.get<PreRegistrationStatusResult>(
      this.url + "/status",
      this.commonModelConfig,
    );

    return response.data;
  };

  public getTotalGachaCount = async () => {
    const response = await this.get<PreRegistrationTotalGachaCount>(
      this.url + "/total-gachaCnt",
      this.commonModelConfig,
    );

    return response.data;
  };

  /* 우파루 목록 데이터 미리 받아옴 */
  public getWooparooListInfo = async () => {
    const response = await this.get<PreRegistrationGetGachaInfoResult>(
      this.url + "/getGachaInfo",
      this.commonModelConfig,
    );

    return {
      ...response.data,
      result: response.data?.result?.map(this.convertGachaInfoToWooparooInfo),
    };
  };

  /**
   * 사전등록 취소
   * @returns string
   */
  public cancelPreRegistration = async () => {
    const response = await this.post<PreRegistrationCancelResult>(
      this.url + "/cancelRegister",
      null,
      this.commonModelConfig,
    );

    return response.data;
  };

  private convertGachaInfoToWooparooInfo = (
    data: PreRegistrationWooparooInfo,
  ) => {
    return {
      wooparooId: data.gachaId,
      image: data.image,
      name: data.name,
    };
  };
}

export default function getPreRegistrationModel() {
  return new PreRegistrationModel();
}

export function getTranslatedDrawWooparoo(id: string, lang: string) {
  if (!drawWooparooTranslation.hasOwnProperty(id)) return "";

  const wooparoo =
    drawWooparooTranslation[id as keyof typeof drawWooparooTranslation];

  return wooparoo[lang as keyof typeof wooparoo];
}

export function openSocialShareLink(
  type: SocialPlatform,
  url: string,
  text?: string,
  hashTags?: string,
) {
  const openPopup = (link: string) => {
    window.open(link, "_blank", "width=800, height=800, top=50%, left=50%");
  };

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  if (type === "facebook") {
    const link = `
    https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    openPopup(link);
  } else if (type === "x") {
    const link = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url,
    )}&text=${encodeURIComponent(text!)}&hashtags=${hashTags}`;
    openPopup(link);
  } else {
    const link = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
      url,
    )}`;
    openPopup(link);
  }
}

export function getShareKey() {
  if (!window) return "";
  return window.localStorage.getItem(SHARE_KEY) as string;
}

export function setShareKey(shareKey: string) {
  if (!window) return;
  window.localStorage.setItem(SHARE_KEY, shareKey);
}

export function clearShareKey() {
  if (!window) return;
  return window.localStorage.removeItem(SHARE_KEY);
}
