/** @jsxImportSource @emotion/react */

import type React from "react";
import { FC, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { useRecoilValue } from "recoil";
import { currentSectionState } from "@modules/Brand/recoil/section";
import { route } from "@common/constants";
import { useLocation } from "react-router-dom";
import { authMenu } from "@modules/Brand/model";
import { useTranslation } from "react-i18next";

export type MenuItem = {
  id?: number;
  title: string;
  social?: string;
  sectionMap?: number;
  url?: string;
};

type Props = {
  MenuItems: MenuItem[];
  ClassName: string;
  handleClick: (item: MenuItem) => () => void;
  setTwoDepth?: React.Dispatch<React.SetStateAction<boolean[]>>;
  twoDepth?: boolean[];
};

const Dropdown: FC<Props> = ({
  MenuItems,
  ClassName,
  handleClick,
  setTwoDepth,
  twoDepth,
}) => {
  const currentSection = useRecoilValue(currentSectionState);
  const outsideRef = useRef<HTMLUListElement | null>(null);
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    // 메뉴 외부 클릭시 닫음 처리
    function handleClickOutside(event: any) {
      const menuTitle = [
        "gameIntro",
        "presale",
        "community",
        "login",
        "loginIcon",
      ];
      if (
        outsideRef.current &&
        !outsideRef.current.contains(event.target) &&
        !menuTitle.includes(event.target.id)
      ) {
        if (twoDepth && setTwoDepth) {
          event.stopPropagation();
          setTwoDepth(twoDepth.map(() => false));
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousewheel", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.addEventListener("mousewheel", handleClickOutside);
    };
  }, [outsideRef]);

  const highlightIfSelected = (sectionNumber: number) => {
    const highlight = { style: { color: "#FFDD21" } };
    let currentSectionCopy = currentSection;

    // PC 해상도 갤럭시 우파루 소개 슬라이드 영역 네비 하이라이트 처리
    if (currentSection >= 14 && currentSection <= 19 && sectionNumber === 14)
      return highlight;

    return sectionNumber === currentSectionCopy ? highlight : {};
  };

  const authPageHighlight = (pathname: string, title: string) => {
    return (
      (pathname === route.brand.myPage && title === authMenu.mypage) ||
      (pathname === route.brand.loginPage && title === authMenu.login)
    );
  };

  return (
    <ul css={dropStyle} className={ClassName} ref={outsideRef}>
      {MenuItems.map((item, idx) => {
        return (
          <li key={idx}>
            <a
              key={item.id}
              style={{
                color: authPageHighlight(location.pathname, item.title)
                  ? "#ffffff"
                  : "",
              }}
              {...highlightIfSelected(item.sectionMap as number)}
              onClick={handleClick(item)}
            >
              {item.social ? (
                <img
                  onClick={() => window.open(item.url, "_blank")}
                  src={item.social}
                  alt={item.title}
                  key={item.title}
                />
              ) : (
                item.title
              )}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Dropdown;

const dropStyle = css`
  & a {
    width: 100%;
    display: block;
    line-height: normal;
    ${theme.fonts.subCopyB};
  }

  @media ${theme.devices.laptopL} {
    width: 120px;
    position: absolute;
    text-align: center;
    border-radius: 0 0 20px 20px;
    background: ${theme.palette.grey[7]};
    left: -20px;
    top: 77px;

    & li {
      display: block;
      margin-right: 0 !important;

      & a:hover {
        color: ${theme.palette.grey[1]};
      }

      &:nth-of-type(1) {
        & a {
          padding-top: 20px;
        }
      }

      &:nth-last-of-type(1) {
        & a {
          padding-bottom: 20px;
        }
      }
    }

    & li:nth-of-type(1)::after {
      content: "";
      display: block;
      position: absolute;
      top: -3px;
      right: 0;
      width: 100%;
      height: 6px;
      background: ${theme.palette.grey[1]};
      border-radius: 4px;
    }

    & a {
      color: ${theme.palette.grey[5]};
      ${theme.fonts.small1B} !important;
      padding: 10px;
      cursor: pointer;
      line-height: 23.17px;
    }

    & img {
      width: 32px;
    }
  }
`;
