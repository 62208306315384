/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import useCopyToClipboard from "@hooks/useCopyToClipboard";
import { useRecoilState } from "recoil";
import { popupState } from "@modules/Brand/recoil/popup";
import Popup from "@modules/Brand/view/components/NewPopup";
import PopupDesc from "@modules/Brand/view/components/PopupDesc";
import PopupButton from "@modules/Brand/view/components/PopupButton";
import useShareURL from "@hooks/useShareURL";
import { Trans, useTranslation } from "react-i18next";
import { fromCDN } from "@common/assets/utils";
import { SocialPlatform } from "@modules/Brand/model";
import SnsShareIcons from "../PreRegister/SnsShareIcons";
import { openSocialShareLink } from "@modules/Brand/model/PreRegistrationModel";

function SocialShareInfo() {
  const { url } = useShareURL();
  const { t, i18n } = useTranslation();
  const { copy } = useCopyToClipboard();
  const [popups, setPopups] = useRecoilState(popupState);

  const copyPopupId = "copy-popup-id";
  const CopyPopup = (
    <Popup id={copyPopupId}>
      <span className="smallText">
        {t("brand:사전등록_이벤트:register_e7")}
      </span>

      <PopupDesc>
        <span className={`urlShareText ${i18n.language}`}>
          <Trans components={{ strong: <strong /> }}>
            {t("brand:사전등록_이벤트:register_e8")}
          </Trans>
        </span>
      </PopupDesc>

      <PopupButton count="single">
        <button
          className="button"
          onClick={() =>
            setPopups(popups.filter(popup => popup.props.id !== copyPopupId))
          }
        >
          {t("brand:한국_사전등록_프로세스:preregi_k9")}
        </button>
      </PopupButton>
    </Popup>
  );

  return (
    <div css={style}>
      <p className="socialContent">
        <Trans components={{ b: <b /> }}>
          {t("brand:사전등록_이벤트:register_e51")}
        </Trans>
      </p>
      <SnsShareIcons isPopup={true} />
      <button
        onClick={() => {
          copy(url);
          setPopups([...popups, CopyPopup]);
        }}
      >
        {t(`brand:사전등록_이벤트:register_e6:tb`)}
      </button>
    </div>
  );
}

export default SocialShareInfo;

const style = css`
  padding: 25px 0 0px;
  margin: 0 18px;
  border-top: 1px solid ${theme.palette.grey[2]};

  p {
    white-space: pre-wrap;
  }

  .socialContent {
    margin-bottom: 30px;
    ${theme.fonts.small1R};
    line-height: 26px;
    color: ${theme.palette.grey[9]};

    b {
      font-weight: 700;
    }
  }

  .btns {
    margin: 0 16px 30px;
    display: flex;
    justify-content: space-between;

    & button,
    & img {
      width: 64px;
      height: 64px;
      cursor: pointer;
    }
  }

  .socialIcons {
    width: 232px;
    height: 64px;
    margin: 0 auto 30px;
    gap: 20px;

    .socialIcon {
      width: 64px;
      img {
      }
    }
  }
`;
