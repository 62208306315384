type EasingFunction = (t: number, b: number, c: number, d: number) => number;

interface ScrollOptions {
  to: number;
  easingFunction?: EasingFunction;
}

const defaultEasingFunction: EasingFunction = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const useScrollTo = () => {
  const scrollTo = ({
    to,
    easingFunction = defaultEasingFunction,
  }: ScrollOptions) => {
    const element = document.scrollingElement || document.documentElement;
    const start = element.scrollTop;
    const change = to - start;
    const startTs = performance.now();

    const threshold = 500;
    const scaleFactor = Math.min(1, Math.abs(change) / threshold);
    const duration = 1000 * scaleFactor;

    const animateScroll = (ts: number) => {
      const currentTime = ts - startTs;
      element.scrollTop = easingFunction(currentTime, start, change, duration);

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    requestAnimationFrame(animateScroll);
  };

  return { scrollTo };
};
