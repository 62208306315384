export default function useCopyToClipboard() {
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error("Error copying text to clipboard:", error);
    }
  };
  return { copy: copyToClipboard };
}
