import { ReactElement } from "react";

export const route = {
  home: "/",
  app: "/app",
  // 글로벌 블록체인 버전
  // market: {
  //   main: "/marketplace",
  //   detail: "/marketplace/:sellingID",
  //   login: "",
  //   manual: "/manual",
  // },
  brand: {
    myPage: "/mypage",
    loginPage: "/login",
    auth: {
      login: "",
      callback: {
        line: "/auth/line",
        google: "/auth/google",
        apple: "/auth/apple",
      },
    },
    xsollaCallback: "/payment/xsolla",
  },
  coupon: {
    main: "/coupon",
  },
};

export const maxGrade = 5;

export const language = ["EN", "JP", "TH", "TW"];

export const zIndex = {
  background: -1,
  cardDimmed: 10,
  cardBadge: 100,
  mobileMenuDimmed: 110,
  mobileMenu: 1001,
  nav: 110,
  walletCard: 110,
  sharePopUp: 1000,
  stickyBottom: 100,
  dropdown: 50,
  dimmer: 9999,
  filterPopup: 1000,
  showMoreReviewBG: -10,
  rotateDimmed: 10001,
};

export const topTradesSortItems = ["ATK : Highest", "HP : Highest"];
export const recentTradesSortItems = [
  "Time : Newest",
  "Time : Oldest",
  "Level : Highest",
  "Level : Lowest",
  "ATK : Highest",
  "ATK : Lowest",
  "HP : Highest",
  "HP : Lowest",
  "Grades : Highest",
  "Grades : Lowest",
  "Price : Highest",
  "Price : Lowest",
];

export const wemixCardWidth = 254;

export const STORAGE_KEY = "wooparoo_api_key";
export const SHARE_KEY = "wooparoo_share_key";
export const LANG_KEY = "i18nextLng";

export type ControllerParam = { children: ReactElement };

export const wooparooTranslation = {
  고대신룡: {
    KR: "고대신룡",
    EN: "God Dragon",
    JP: "古代神竜",
    TH: "ก๊อดดราก้อน",
    TW: "古代神龍",
  },
  다크닉스: {
    KR: "다크닉스",
    EN: "Darknix",
    JP: "ダークニックス",
    TH: "ดาร์กนิกซ์",
    TW: "黑暗妖龍",
  },
  세이렌: {
    KR: "세이렌",
    EN: "Searene",
    JP: "セイレン",
    TH: "เซเร็น",
    TW: "睡蓮",
  },
  리쟈루: {
    KR: "리쟈루",
    EN: "Lizar",
    JP: "リザル",
    TH: "ลิซาร์",
    TW: "利蜥兒",
  },
  램피: {
    KR: "램피",
    EN: "Rempy",
    JP: "レンピー",
    TH: "เรนปี้",
    TW: "連皮",
  },
  스칼렛: {
    KR: "스칼렛",
    EN: "Scarlet",
    JP: "スカーレット",
    TH: "สการ์เล็ต",
    TW: "史卡蕾",
  },
};

export const drawWooparooTranslation = {
  "221": {
    KR: "미요",
    EN: "Miyo",
    JP: "ミヨ",
    TH: "มิโยะ",
    TW: "米優",
  },
  "23": { KR: "페페", EN: "Pepe", JP: "ペティー", TH: "เปเป้", TW: "飛飛" },
  "22": {
    KR: "치치",
    EN: "Hedgie",
    JP: "ザッポ",
    TH: "เฮดจี้",
    TW: "雪蝟",
  },
  "21": {
    KR: "하쿠루",
    EN: "Cranne",
    JP: "ポッポル",
    TH: "แครนเน่",
    TW: "菇菇鳥",
  },
  "10": {
    KR: "우파루파",
    EN: "Seamander",
    JP: "ウパルパ",
    TH: "ซีแมนเดอร์",
    TW: "六角粉螈",
  },
};

export const drawWooparooSlideTranslation = {
  미요: {
    KR: "미요",
    EN: "MIYO",
    JP: "ミヨ",
    TH: "มิโยะ",
    TW: "米優",
  },
  페페: { KR: "페페", EN: "PEPE", JP: "ペティー", TH: "เปเป้", TW: "飛飛" },
  치치: {
    KR: "치치",
    EN: "HEDGIE",
    JP: "ザッポ",
    TH: "เฮดจี้",
    TW: "雪蝟",
  },
  하쿠루: {
    KR: "하쿠루",
    EN: "CRANNE",
    JP: "ポッポル",
    TH: "แครนเน่",
    TW: "菇菇鳥",
  },
  우파루파: {
    KR: "우파루파",
    EN: "SEAMANDER",
    JP: "ウパルパ",
    TH: "ซีแมนเดอร์",
    TW: "六角粉螈",
  },
};

export const wooparoosTranslation = {
  로사: {
    KR: "로사",
    EN: "ROSA",
    JP: "ローサ",
    TH: "โรซ่า",
    TW: "羅莎",
  },
  아이린: {
    KR: "아이린",
    EN: "IRENE",
    JP: "アイリン",
    TH: "ไอรีน",
    TW: "艾琳",
  },
  레이: {
    KR: "레이",
    EN: "RAY",
    JP: "レイ",
    TH: "เรย์",
    TW: "天使龍",
  },
  쉐도우: {
    KR: "쉐도우",
    EN: "SHADOW",
    JP: "シャドー",
    TH: "แชโดว์",
    TW: "影龍",
  },
  루돌: {
    KR: "루돌",
    EN: "RUDOL",
    JP: "ルドル",
    TH: "รูดอว์",
    TW: "雪鹿",
  },
  허니럽: {
    KR: "허니럽",
    EN: "HONEYROO",
    JP: "ハニーロップ",
    TH: "ฮันนี่รู",
    TW: "蜜罐寶寶",
  },
  큐피: {
    KR: "큐피",
    EN: "CUPI",
    JP: "キュピ",
    TH: "คิวพิ",
    TW: "丘比",
  },
  하트퀸: {
    KR: "하트퀸",
    EN: "HARLEQUEEN",
    JP: "ハークイン",
    TH: "ราชินีหัวใจ",
    TW: "愛心皇后",
  },
  햄찌볼: {
    KR: "햄찌볼",
    EN: "HUTCH",
    JP: "ハッチ",
    TH: "ฮัทช์",
    TW: "哈奇",
  },
  오트: {
    KR: "오트",
    EN: "OTT",
    JP: "オット",
    TH: "ออตโต้",
    TW: "歐特",
  },
};

export const KOREA_BRAND_URL = "https://wooparoo-odyssey.hangame.com/";

export const TERMS_SERVICE_URL =
  "https://accounts.hangame.com/terms/mobile/toastTermAgreeGlobal";

export const PRIVACY_POLICY_URL =
  "https://wpd.cdn.toastoven.net/License/WOOPAROO_GLOBAL_PRIVACY_POLICY.html";

export const getAppleCppLink = (cppid: string) => {
  let storeUrl = "https://apps.apple.com/us/app/wooparoo-odyssey/id1645242780";

  switch (cppid) {
    case "asa":
      storeUrl =
        "https://apps.apple.com/app/wooparoo-odyssey-build-breed/id1645242780?ppid=f6efe310-71f8-4590-94fd-e2545f371ae5";
      break;
    case "facebook":
      storeUrl =
        "https://apps.apple.com/app/wooparoo-odyssey-build-breed/id1645242780?ppid=621441e9-4aca-4fa5-aeec-59224430140c";
      break;
    case "tiktok":
      storeUrl =
        "https://apps.apple.com/app/wooparoo-odyssey-build-breed/id1645242780?ppid=d659ddef-149f-4ba6-bf8f-ca5d6f58b6d7";
      break;
    case "unicorn":
      storeUrl =
        "https://apps.apple.com/app/wooparoo-odyssey-build-breed/id1645242780?ppid=add85065-7b5a-4b7e-b931-96aae1cf0561";
      break;
    case "zucks":
      storeUrl =
        "https://apps.apple.com/app/wooparoo-odyssey-build-breed/id1645242780?ppid=6d5f6414-47a3-4330-85fd-662f2210c5a5";
      break;
    case "line":
      storeUrl =
        "https://apps.apple.com/app/wooparoo-odyssey-build-breed/id1645242780?ppid=7371f0db-bce5-4f2c-9e76-8ac25a9c640f";
      break;
    default:
      break;
  }

  return storeUrl;
};

export const getGoogleCppLink = (cppid: string) => {
  let storeUrl =
    "https://play.google.com/store/apps/details?id=com.nhn.wooparoo";

  return storeUrl;
};
