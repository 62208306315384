/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { zIndex } from "@common/constants";
import { fromCDN } from "@common/assets/utils";
import SVGComponent from "@modules/Brand/view/components/SVGComponent";
import { useTranslation } from "react-i18next";

const Alert = SVGComponent(fromCDN("coupon/svg/alert.svg"));

type Props = {
  id: string;
  closePopupById: (id: string) => void;
};

const ErrorPopup = ({ id, closePopupById }: Props) => {
  const { t } = useTranslation();

  return (
    <div css={style} id={id}>
      <div className="contents popupContainer">
        <h3>
          <Alert />
        </h3>
        <span>{t("brand:오류팝업:error1")}</span>
        <button onClick={() => closePopupById(id)}>
          {t("brand:오류팝업:error2")}
        </button>
      </div>
    </div>
  );
};

export default ErrorPopup;

const style = css`
  width: 100vw;
  height: 100vh;
  background-color: ${theme.palette.action.disabledBackground};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.dimmer};
  color: ${theme.palette.grey[9]};
  .contents {
    width: 310px;
    height: 262px;
    background-color: ${theme.palette.common.white};
    border-radius: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    button {
      width: 150px;
      height: 50px;
      margin: 0 auto 40px;
      ${theme.mixins.displayCenter};
      ${theme.fonts.small1B};
      line-height: 24px;
      background-color: ${theme.palette.primary.main};
      border-radius: 10px;
      border: none;
      cursor: pointer;
      color: ${theme.palette.grey[9]};
      &:active {
        ${theme.mixins.buttonPressEffect}
      }
    }
    h3 {
      text-align: center;
      padding: 18px 0;
      background-color: ${theme.palette.secondary.main3};
      border-radius: 30px 30px 0 0;
      color: ${theme.palette.grey[1]};
      ${theme.fonts.small1B};
      position: relative;
      line-height: 23px;
      & img {
        position: relative;
        top: 4px;
      }
    }
    span {
      text-align: center;
      height: 50px;
      margin: 30px auto;
      ${theme.fonts.small1B};
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      line-height: 1.5;
      justify-content: center;
    }
    .successBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: center;
      height: 100px;
      margin-bottom: 50px;
      & span {
        display: block;
      }
      & span:nth-of-type(1) {
        margin-top: 70px;
        margin-bottom: 5px;
      }
      & span:nth-of-type(2) {
        margin-top: 0px;
        ${theme.fonts.small1R};
        margin-bottom: 52px;
      }
    }
  }
`;
