import { useEffect } from "react";
import useDevice from "./useDevice";
import { useLocation } from "react-router-dom";

// 팝업 뒷배경 스크롤 고정할 때 사용
const useScrollLock = () => {
  const { fromLapTop } = useDevice();
  const { pathname } = useLocation();

  const page = ["/mypage", "/login", "/coupon"];
  const minGap = 135;

  useEffect(() => {
    // 사용시에 팝업에 popupContainer 클래스명 추가 필요
    const currentPopup = document.querySelector(
      ".popupContainer",
    ) as HTMLElement;

    const preventDefault = (event: any) => {
      event.preventDefault();
    };

    // 모바일 기기 touchevent 처리
    const updateScrollLock = () => {
      const currentPopupHeight = currentPopup.offsetHeight;
      const currentViewHeight = window.innerHeight;

      if (currentViewHeight - currentPopupHeight >= minGap) {
        document.body.addEventListener("touchmove", preventDefault, {
          passive: false,
        });
      } else {
        document.body.removeEventListener("touchmove", preventDefault);
      }
    };

    // updateScrollLock();

    const initialScrollPosition = window.scrollY;
    document.body.style.overflow = "hidden";

    const handleResize = () => {
      // updateScrollLock();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      let css = "auto";

      // 원페이지 스크롤 때문에 메인페이지에서만 적용
      if (fromLapTop && !page.includes(pathname)) {
        css = "hidden";
      }
      document.body.style.overflow = css;

      window.scrollTo(0, initialScrollPosition);

      window.removeEventListener("resize", handleResize);
      document.body.removeEventListener("touchmove", preventDefault);
    };
  }, [fromLapTop]);
};

export default useScrollLock;
