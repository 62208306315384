/** @jsxImportSource @emotion/react */

import type { ReactElement } from "react";
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import { zIndex } from "@common/constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export type PopupProps = {
  children: ReactElement | ReactElement[];
  id: string;
  className?: string;
};

function Popup({ children, className }: PopupProps) {
  const { i18n } = useTranslation();

  return (
    <div css={style}>
      <div className={`contents ${className}`}>
        <div className={`${i18n.language}popup`}>{children}</div>
      </div>
    </div>
  );
}

export default Popup;

const style = css`
  width: 100vw;
  height: 100vh;
  background-color: ${theme.palette.action.disabledBackground};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.dimmer};

  .contents {
    width: 312px;
    height: auto;
    min-height: 260px;
    padding: 40px 25px;
    background-color: ${theme.palette.common.white};
    border-radius: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h3 {
      color: ${theme.palette.grey[9]};
      ${theme.fonts.buttonB};
      line-height: 29px;
      white-space: pre-wrap;
    }

    p,
    span {
      white-space: pre-wrap;
    }

    button {
      color: ${theme.palette.grey[9]};
    }

    // .ENText {
    //   & button {
    //     bottom: -10px;
    //   }
    // }

    .smallText {
      ${theme.fonts.small1B};
      line-height: 23px;
      margin-bottom: 10px;
      display: block;
      white-space: pre-wrap;
    }

    .urlShareText {
      ${theme.fonts.buttonB};
      line-height: 29px;
      display: block;
      // margin-bottom: 77px;
      white-space: pre-wrap;

      &.TH {
        strong {
          white-space: pre;
        }
      }

      & strong {
        color: ${theme.palette.secondary.main};
      }
    }

    .boldText {
      ${theme.fonts.small1B};
    }

    .desc {
      margin-top: 7px;
      ${theme.fonts.small1R};
      line-height: 23px;
      white-space: pre-wrap;
      margin-bottom: 17px;
    }

    .btns {
      // width: calc(100% - 30px);
      // position: absolute;
      // bottom: 40px;
      position: relative;
      margin: 0 auto;
      bottom: 0;
      ${theme.mixins.displayCenter};
      color: ${theme.palette.grey[9]};
      ${theme.fonts.small1B};

      &.single {
        .button {
          width: 150px;
        }
      }

      &.double {
        .button {
          width: 120px;
        }
      }

      .button {
        max-width: 150px;
        height: 50px;
        color: ${theme.palette.grey[9]};
        ${theme.fonts.small1B};
        border: none;
        border-radius: 10px;
        background-color: ${theme.palette.primary.main};
        cursor: pointer;
      }

      .left {
        margin-right: 10px;
        background-color: ${theme.palette.grey[1]};
      }
    }

    .consent-title {
      font-size: 14px;
      font-weight: 700;
      line-height: -10;
    }

    .consent-container {
      margin: 12px auto 82px;

      .consent-content {
        font-size: 12px;
        font-weight: 400;
        width: 252px;
        height: 174px;
        overflow-y: scroll;
        background-color: ${theme.palette.grey[1]};
        border-radius: 10px;
        padding: 12px;
        text-align: start;
        margin: 0 auto 22px;
      }

      .consent-checkbox-container {
        .consent-checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          #consent-checkbox {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          #consent-checkbox:checked ~ .custom-checkbox {
            background-color: ${theme.palette.grey[7]};
          }

          #consent-checkbox:checked ~ .custom-checkbox:after {
            display: block;
          }

          .custom-checkbox {
            position: relative;
            background-color: ${theme.palette.grey[2]};
            border: 2px solid ${theme.palette.grey[7]};
            border-radius: 4px;
            width: 24px;
            padding: 12px;
            margin-right: 8px;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }

          .custom-checkbox:after {
            left: 5px;
            top: 0;
            width: 10px;
            height: 14px;
            border: solid white;
            border-width: 0 4px 4px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          .consent-checkbox-text {
            font-weight: 700;
            font-size: 14px;
            color: ${theme.palette.grey[9]};
          }
        }
      }

      .consent-warning {
        margin-top: 10px;
        font-weight: 700;
        font-size: 12px;
        color: ${theme.palette.error.main};
      }
    }

    strong {
      font-weight: 700;
    }

    .red {
      font-weight: 700;
      font-size: 14px;
      color: ${theme.palette.error.main};
    }

    .purple {
      color: ${theme.palette.secondary.main};
    }
  }

  .pre-login-popup {
    .TWpopup {
      .desc {
        margin-bottom: 46px;
      }
    }
  }

  .not-regist-popup {
    .desc {
      margin-bottom: 30px;
    }

    .TWpopup,
    .THpopup {
      .desc {
        margin-bottom: 45px;
      }
    }
  }

  .copy-popup {
    .desc {
      margin-top: 17px;
    }
  }

  .regist-complete-popup {
    .desc {
      margin-bottom: 45px;
    }
  }

  .draw-confirm-popup {
    h3 {
      margin-bottom: 60px;
    }

    p {
      font-weight: 400;
    }
  }

  .draw-complete-popup {
    padding: 40px 17px;

    .desc {
      margin-bottom: 53px;

      strong {
        margin: 20px 0px;
        display: block;
      }
    }
  }

  .drawLoginPopup {
    .btns {
      margin-top: 74px;
    }
  }
`;
