import type { ApiResult } from "@modules/Brand/model/interface/common.interface";

export type AuthInfo = {
  useInfo: TUserInfo;
  accessToken: string;
};

export enum SocialPlatform {
  FACEBOOK = "facebook",
  GOOGLE = "google",
  LINE = "line",
  APPLE = "apple",
  X = "x",
}

type TUserInfo = {
  name: string;
  email: string;
  hasAgreed: boolean;
};

export type AuthApiResult = ApiResult<{
  accessToken: string;
  alreadyLoginFlg: boolean; // 기존에 등록된 회원인지 체크 (true이면 기존 등록된 회원)
}>;

export type AppleAuthParam = {
  idToken: string;
};

export type FacebookAuthParam = {
  accessToken: string;
};

export type GoogleAuthParam = {
  accessToken: string;
};

export type LineAuthParam = {
  code: string;
};

export type LoginTypeParam = {
  category: string;
};
