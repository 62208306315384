import i18n, { Module, Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector, {
  DetectorOptions,
} from "i18next-browser-languagedetector";

import * as en from "./en";
import * as ko from "./ko";
import * as jp from "./jp";
import * as th from "./th";
import * as tw from "./tw";
import getPreSaleModel from "@modules/Brand/model/PreSaleModel";
import { language, LANG_KEY } from "@common/constants";

const resources: Resource = {
  EN: {
    ...en,
  },
  KR: {
    ...ko,
  },
  JP: {
    ...jp,
  },
  TH: {
    ...th,
  },
  TW: {
    ...tw,
  },
} as const;

const preSaleModel = getPreSaleModel();

// 커스텀 언어 감지 로직 - 언어 설정 정보가 없는 경우 유저의 접속 국가 기준으로 최초 언어를 설정해준다.
const customLanguageDetector: Module & Partial<DetectorOptions> = {
  type: "languageDetector",
  async: true,
  detect() {
    return new Promise<string>(resolve => {
      detectLanguageByCustomLogic().then(userLanguage => {
        resolve(userLanguage);
      });
    });
  },
  cacheUserLanguage(lng: string) {
    localStorage.setItem(LANG_KEY, lng);
    return Promise.resolve();
  },
} as Module & Partial<DetectorOptions>;

const detectLanguageByCustomLogic = () => {
  return new Promise<string>(resolve => {
    const lang = localStorage.getItem(LANG_KEY);

    if (lang) return resolve(lang);

    preSaleModel
      .getUserIP()
      .then(res => {
        if (!res.header.isSuccessful) return resolve("EN");

        const { countryCode } = res.result;
        if (language.includes(countryCode)) return resolve(countryCode);
        return resolve("EN");
      })
      .catch(() => resolve("EN"));
  });
};

i18n
  .use(LanguageDetector)
  .use(customLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "EN",
    detection: {
      order: ["customLanguageDetector", "localstorage"],
      caches: ["localStorage"],
    },
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    returnObjects: true,
  });
export default i18n;
