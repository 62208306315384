import type { ApiResult } from "@modules/Brand/model/interface/common.interface";

export type OrderListParam = {
  page: number;
  pageSize: number;
  valid: number;
};

export enum orderStatus {
  WAIT = "wait", //결제실패
  PAID = "order_paid", // 결제완료
  CANCELED = "order_canceled", //결제취소
  REFUND = "refund", //환불완료
}

// TODO : 추후 변경예정 (최종 등록된 명칭 확인 필요)
export enum itemType {
  CROSS = "갤럭시 크로스 01",
  MISTERY_BOX = "갤럭시 우파루 미스터리 박스",
}

// TODO : 추후 아이템 넘어 오는 값 확인 필요
export enum WooparooItemType {
  GODDRAGON = "고대신룡",
  DARKNIX = "다크닉스",
  SEARENE = "세이렌",
  LIZAR = "리쟈루",
  REMPY = "램피",
  SCARLET = "스칼렛",
}

export type MyPreSellListApiResult = ApiResult<{
  records: []; //주문목록
}>;

export type UserAccountDeleteApiResult = ApiResult<{}>;

export type ItemOrderInfo = {
  [x: string]: any;
  amount: number; //구매 금액
  amountWithoutDiscount: number; //구매 할인후 금액
  cuponSku: string; //상품 SKU
  quantity: number; //갯수
  currency: string; //통화
  orderId: number; //구매 ID
  registerDatetime: number; //등록날짜?
  orderlogList: [
    {
      notificationType: orderStatus;
      seqDatetime: number;
    },
  ]; //결제상태이력 (총 4가지 쌓일 수 있음)
  orderItemsList: [
    {
      cuponKey: string; // 쿠폰코드
    },
  ]; //쿠폰정보
  cuponKey: string; //쿠폰코드
  paymentDatetime: number; //결제시간
  paymentMethod: string; //결제방식 id
  paymentMethodName: string; //결제방식 이름
  paymentMethodOrderId: string; //결제 시스템의 결제 id
  productInfo: {
    name: string; // 아이템타입 (갤럭시크로스 || 갤럭시 우파루 미스터리 박스)
    description: string; // (미스터리 박스일 경우) 확정된 상품이름
  };
  status: orderStatus; //주문상태
};
