import { RefObject, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentSectionState } from "@modules/Brand/recoil/section";

const useIntersectionObserver = (
  targetRef: RefObject<HTMLDivElement>,
): boolean => {
  const [isInViewport, setIsInViewport] = useState<boolean>(false);
  const observer = useRef<IntersectionObserver>();
  const [currentSection, setCurrentSection] =
    useRecoilState(currentSectionState);

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      if (!observer.current) {
        const observerCallback: IntersectionObserverCallback = entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setIsInViewport(true);
            }
          });
        };

        observer.current = new IntersectionObserver(observerCallback, {
          threshold: 0,
        });
      }

      if (targetRef.current) {
        observer.current.observe(targetRef.current);
      }

      return () => {
        if (observer.current) {
          observer.current.disconnect();
        }
      };
    } else {
      if (currentSection === 4) {
        setIsInViewport(true);
      }
    }
  }, [targetRef, currentSection]);

  return isInViewport;
};

export default useIntersectionObserver;
