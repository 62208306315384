/** @jsxImportSource @emotion/react */

import type { ReactElement } from "react";

type PopupButtonProps = {
  children: ReactElement | ReactElement[];
  count: "single" | "double";
};

type TButton = {
  clickHandler: () => void;
  text: string | ReactElement;
};

export default function PopupButton({ count, children }: PopupButtonProps) {
  return <div className={`btns ${count}`}>{children}</div>;
}
