import { RefObject, useEffect, useState } from 'react';

export default function useWheel(ref?: RefObject<HTMLElement> | null) {
  const [wheelValue, setWheelValue] = useState(0);
  const [hold, setHold] = useState(false);

  const reset = () => {
    setWheelValue(0);
    setHold(true);
    setTimeout(() => setHold(false), 1000);
  };

  useEffect(() => {
    const curr = ref?.current;
    if (!curr) return;

    const listener = (e: WheelEvent) => {
      setWheelValue(prev => {
        if (hold) return 0;
        return prev + e.deltaY;
      });
    };

    curr.addEventListener('wheel', listener);

    return () => curr.removeEventListener('wheel', listener);
  }, [hold, ref]);

  return { wheelValue, reset, hold };
}
