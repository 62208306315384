import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { STORAGE_KEY } from "@common/constants";

export type Stage = "local" | "development" | "production";
export type ModelConfig = {
  withToken?: boolean;
  config?: AxiosRequestConfig;
};

export class Model {
  private readonly axios: AxiosInstance;
  private readonly stage: Stage;

  constructor() {
    this.stage = (process.env.REACT_APP_STAGE as Stage) ?? "local";
    this.axios = axios.create({
      baseURL: this.getBaseUrl(this.stage),
      headers: {},
    });
  }

  protected get<T>(url: string, modelConfig?: ModelConfig) {
    if (modelConfig?.withToken) {
      return this.withToken().axios.get<T>(url, modelConfig.config);
    }

    return this.axios.get<T>(url, modelConfig?.config);
  }

  protected post<T = unknown, D = unknown>(
    url: string,
    data: D,
    modelConfig?: ModelConfig,
  ) {
    if (modelConfig?.withToken) {
      return this.withToken().axios.post<T, AxiosResponse<T>, D>(
        url,
        data,
        modelConfig.config,
      );
    }

    return this.axios.post<T, AxiosResponse<T>, D>(
      url,
      data,
      modelConfig?.config,
    );
  }

  protected put<T = unknown, D = unknown>(
    url: string,
    data: D,
    modelConfig?: ModelConfig,
  ) {
    if (modelConfig?.withToken) {
      return this.withToken().axios.put<T, AxiosResponse<T>, D>(
        url,
        data,
        modelConfig.config,
      );
    }

    return this.axios.put<T, AxiosResponse<T>, D>(
      url,
      data,
      modelConfig?.config,
    );
  }

  protected urlencodedContentType = () => {
    return { "content-type": "application/x-www-form-urlencoded" };
  };

  private withToken = () => {
    const accessToken = window.localStorage.getItem(STORAGE_KEY);
    const token = accessToken ? String(accessToken) : "";

    this.axios.interceptors.request.use((config: AxiosRequestConfig) => ({
      ...config,
      headers: { authorization: token },
    }));

    return this;
  };

  private getBaseUrl = (stage: Stage) => {
    const urlMap = {
      local: "https://dev-wpd-api.hangame.com/api",
      development: "https://dev-wpd-api.hangame.com/api",
      production: "https://wpd-api.hangame.com/api",
    };

    return urlMap[stage];
  };
}
