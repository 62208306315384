import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { route } from "@common/constants";
import Brand from "./Brand";
import Coupon from "./Coupon/index";
import ScrollToTop from "@modules/Market/view/components/layout/ScrollToTop";
import MyPage from "./Brand/MyPage";
import LineCallback from "./Brand/LineCallback";
import Login from "./Brand/LoginPage";
import GoogleCallback from "./Brand/GoogleCallback";
import XsollaCallback from "./Brand/XsollaCallback";
import AppleCallback from "./Brand/AppleCallback";

// 글로벌 블록체인 버전
// import MarketDetail from "./Market/MarketDetail";
// import Market from "./Market/index";
// import NFTManual from "./Market/Manual";

const Router: FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={route.home} element={<Brand />} />
        {/* <Route path={route.brand.myPage} element={<MyPage />} /> */}
        <Route path={route.coupon.main} element={<Coupon />} />
        {/* <Route path={route.brand.loginPage} element={<Login />} /> */}
        {/* 글로벌 블록체인 버전 - 시작 */}
        {/* <Route path={route.market.main} element={<Market />} />
        <Route path={route.market.detail} element={<MarketDetail />} />
        <Route path={route.market.manual} element={<NFTManual />} /> */}
        {/* 글로벌 블록체인 버전 - 끝 */}
        {/* <Route
          path={route.brand.auth.callback.line}
          element={<LineCallback />}
        />
        <Route
          path={route.brand.auth.callback.google}
          element={<GoogleCallback />}
        /> */}
        {/* <Route
          path={route.brand.auth.callback.apple}
          element={<AppleCallback />}
        /> */}
        {/* <Route path={route.brand.xsollaCallback} element={<XsollaCallback />} /> */}

        {/* 사용자가 입력한 경로에 대한 매칭이 없을 경우 메인 페이지로 리다이렉트 */}
        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
