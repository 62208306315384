export const queryKeys = {
  preRegistration: {
    status: {
      queryKey: ["pre-registration", "status"],
    },
    wooparooInfo: {
      queryKey: ["pre-registration", "wooparooInfo"],
    },
    total: {
      queryKey: ["pre-registration", "totalGachaCnt"],
    },
  },
  preSale: {
    checkIp: {
      queryKey: ["pre-sale", "checkIp"],
    },
    items: {
      queryKey: ["pre-sale", "items"],
    },
    order: {
      queryKey: ["pre-sale", "order"],
    },
  },
  my: {
    myPreSellItems: {
      queryKeys: ["my", "myPreSellItems"],
    },
  },
  login: {
    tokenStatus: {
      queryKeys: ["login", "tokenStatus"],
    },
  },
};
