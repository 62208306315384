import ErrorPopup from "@modules/Brand/view/components/ErrorPopup";
import Commonpopup from "@modules/Coupon/view/components/layout/Popup";
import { useTranslation } from "react-i18next";

export function useCommonPopups({
  closePopupById,
}: {
  closePopupById: (id: string) => void;
}) {
  const { t } = useTranslation();
  /* 공통팝업 */
  const FailPopup = () => {
    const id = "fail-popup-id";

    return <ErrorPopup id={id} closePopupById={closePopupById} />;
  };

  /* 쿠폰등록 팝업창 */
  const CharacterNamePopup = () => {
    const id = "characterName-popup-id";

    return (
      <Commonpopup
        id={id}
        result="Fail"
        title={t("coupon:팝업:pop1")!}
        message={t("coupon:팝업:pop2")!}
        closePopupById={closePopupById}
      />
    );
  };

  const CouponNoPopup = () => {
    const id = "coupon-no-popup-id";

    return (
      <Commonpopup
        id={id}
        result="Fail"
        title={t("coupon:팝업:pop1")!}
        message={t("coupon:팝업:pop4")!}
        closePopupById={closePopupById}
      />
    );
  };

  return {
    CharacterNamePopup,
    CouponNoPopup,
    FailPopup,
  };
}
