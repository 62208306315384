/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useState } from "react";
import theme from "@common/styles/theme";
import { zIndex } from "@common/constants";
import { fromCDN } from "@common/assets/utils";
import { useRecoilState } from "recoil";
import { preregistState } from "@modules/Brand/recoil/preregister";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  approveConsent: () => Promise<void>;
  closePopupById: (id: string) => void;
  id: string;
};

const PreregisterPopup = ({ approveConsent, closePopupById, id }: Props) => {
  const [showWarning, setShowWarning] = useState(false);
  const [agreePresonal, setAgreePresonal] = useState(false);

  const [showWarningAd, setShowWarningAd] = useState(false);
  const [agreeAd, setAgreeAd] = useState(false);

  const [, setPreregister] = useRecoilState(preregistState);
  const { t, i18n } = useTranslation();

  const handleCancel = () => {
    localStorage.removeItem("registerClicked");
    setPreregister(false);
    closePopupById(id);
  };

  return (
    <div id={id} css={preregisterPopup}>
      <div className={`contents ${i18n.language}popup popupContainer`}>
        <h3>
          <span className="consent-title">
            {t("brand:한국_사전등록_프로세스:preregi_k1")}
          </span>
        </h3>

        <div>
          <div className="consent-container">
            <div className="consent-content">
              <span>{t("brand:한국_사전등록_프로세스:preregi_k2")}</span>
              <span>
                <Trans components={{ strong: <strong /> }}>
                  {t("brand:한국_사전등록_프로세스:preregi_k3")}
                </Trans>
              </span>
              <span>
                <Trans components={{ strong: <strong /> }}>
                  {t("brand:한국_사전등록_프로세스:preregi_k4")}
                </Trans>
              </span>
              <span>
                <Trans components={{ span: <span />, strong: <strong /> }}>
                  {t("brand:한국_사전등록_프로세스:preregi_k5")}
                </Trans>
              </span>
              <span>{t("brand:한국_사전등록_프로세스:preregi_k6")}</span>
            </div>

            <div className="consent-checkbox-container">
              <label className="consent-checkbox" htmlFor="consent-checkbox">
                <input
                  id="consent-checkbox"
                  type="checkbox"
                  onChange={e => {
                    setShowWarning(!e.target.checked);

                    if (e.target.checked) {
                      setAgreePresonal(true);
                    } else {
                      setAgreePresonal(false);
                    }
                  }}
                />
                <div className="custom-checkbox" />
                <span
                  className={`${
                    i18n.language == "TH" ? "pre" : ""
                  } consent-checkbox-text`}
                >
                  {t("brand:한국_사전등록_프로세스:preregi_k7")}
                </span>
              </label>
              {showWarning && (
                <span className="consent-warning">
                  {t("brand:한국_사전등록_프로세스:preregi_k11")}
                </span>
              )}
            </div>

            <div className="consent-checkbox-container consent-checkbox-container-ad">
              <label className="consent-checkbox" htmlFor="consent-checkbox-ad">
                <input
                  id="consent-checkbox-ad"
                  type="checkbox"
                  onChange={e => {
                    setShowWarningAd(!e.target.checked);

                    if (e.target.checked) {
                      setAgreeAd(true);
                    } else {
                      setAgreeAd(false);
                    }
                  }}
                />
                <div className="custom-checkbox" />
                <span className="consent-checkbox-text">
                  {t("brand:한국_사전등록_프로세스:preregi_k8")}
                </span>
              </label>
              {showWarningAd && (
                <span className="consent-warning warning-ad">
                  {t("brand:한국_사전등록_프로세스:preregi_k12")}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="buttonBox">
          <button onClick={handleCancel} className="cancel">
            {t("brand:한국_사전등록_프로세스:preregi_k10")}
          </button>
          <button
            onClick={() => {
              if (!agreePresonal && !agreeAd) {
                setShowWarning(true);
                setShowWarningAd(true);
                return;
              }
              if (!agreePresonal) {
                setShowWarning(true);
                return;
              }

              if (!agreeAd) {
                setShowWarningAd(true);
                return;
              }

              closePopupById(id);
              approveConsent();
            }}
          >
            {t("brand:한국_사전등록_프로세스:preregi_k9")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreregisterPopup;

const preregisterPopup = css`
  width: 100vw;
  height: 100vh;
  background-color: ${theme.palette.action.disabledBackground};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.dimmer};

  button {
    color: ${theme.palette.grey[9]};
  }

  .contents {
    width: 312px;
    min-height: 260px;
    padding: 40px 30px;
    background-color: ${theme.palette.common.white};
    border-radius: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    & h3 {
      color: ${theme.palette.grey[9]};
      ${theme.fonts.buttonB};
      line-height: 18px;
    }

    .desc {
      margin-top: 7px;
      ${theme.fonts.small1R};
      line-height: 23px;
      white-space: pre-wrap;
    }

    .consent-title {
      font-size: 14px;
      font-weight: 700;
      line-height: -10;
      white-space: pre-wrap;
    }

    .consent-container {
      margin: 12px auto;

      .consent-content {
        font-size: 12px;
        font-weight: 400;
        width: 252px;
        height: 174px;
        overflow-y: scroll;
        background-color: ${theme.palette.grey[1]};
        border-radius: 10px;
        padding: 12px;
        text-align: start;
        margin: 0 auto 20px;
        margin-top: 24px;

        & span {
          display: block;
          line-height: 20px;

          &:nth-of-type(1) {
            margin-bottom: 8px;
          }

          &:nth-last-of-type(1) {
            margin-top: 8px;
          }
        }

        & strong {
          display: inline;
        }
      }

      .consent-checkbox-container {
        margin-bottom: 31px;
        position: relative;

        .consent-checkbox {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          cursor: pointer;

          #consent-checkbox {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }

          #consent-checkbox:checked ~ .custom-checkbox {
            background-color: ${theme.palette.grey[7]};
          }

          #consent-checkbox:checked ~ .custom-checkbox:after {
            display: block;
          }

          .custom-checkbox {
            position: relative;
            background-color: ${theme.palette.grey[2]};
            border-radius: 4px;
            width: 24px;
            padding: 12px;
            margin-right: 8px;
            top: -2px;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }

          .custom-checkbox:after {
            background: url(${fromCDN("brand/svg/Check_32.svg")});
            width: 24px;
            height: 24px;
            left: 0;
            top: 0;
          }

          .consent-checkbox-text {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: ${theme.palette.grey[9]};
            text-align: left;
            word-break: break-all;

            &.pre {
              white-space: pre;
            }
          }
        }
      }
      .consent-checkbox-container-ad {
        margin-bottom: 38px;

        #consent-checkbox-ad {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
        }

        #consent-checkbox-ad:checked ~ .custom-checkbox {
          background-color: ${theme.palette.grey[7]};
        }

        #consent-checkbox-ad:checked ~ .custom-checkbox:after {
          display: block;
        }
      }

      .consent-warning {
        position: absolute;
        bottom: -17px;
        font-weight: 700;
        font-size: 12px;
        color: ${theme.palette.error.main};
        left: 0;
        text-align: left;
      }

      .warning-ad {
        bottom: -18px;
      }
    }

    strong {
      font-weight: 700;
    }

    .red {
      font-weight: 700;
      font-size: 14px;
      color: ${theme.palette.error.main};
    }

    .purple {
      color: ${theme.palette.secondary.main};
    }

    .buttonBox {
      display: flex;
      justify-content: space-between;

      & button {
        width: 120px;
        height: 50px;
        border-radius: 10px;
        background-color: ${theme.palette.primary.main};
        ${theme.fonts.small1B};

        &.cancel {
          background-color: ${theme.palette.grey[1]};
        }
      }
    }
  }

  .ENpopup,
  .THpopup {
    .custom-checkbox {
      top: 1px !important;
    }

    .consent-warning {
      bottom: -24px !important;
    }
  }

  .THpopup {
    .warning-ad {
      bottom: -14px !important;
    }
  }
`;
