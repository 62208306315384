/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { useTranslation } from "react-i18next";
import theme from "@common/styles/theme";
import { zIndex } from "@common/constants";
import { fromCDN } from "@common/assets/utils";
import SVGComponent from "@modules/Brand/view/components/SVGComponent";
import useScrollLock from "@hooks/useScrollLock";

const Alert = SVGComponent(fromCDN("coupon/svg/alert.svg"));

type Props = {
  id: string;
  title?: string;
  result?: string;
  message: string[];
  confirm: () => void;
  clearPopups: () => void;
  closePopupById: (id: string) => void;
  custom?: SerializedStyles;
};

function CommonPopup({
  id,
  title,
  result,
  closePopupById,
  message,
  confirm,
  clearPopups,
  custom,
}: Props) {
  useScrollLock();
  const { t } = useTranslation();

  return (
    <div css={[style, custom]} id={id}>
      <div className="contents popupContainer">
        <h3 className="alert">
          <Alert />
        </h3>
        <div className="innerContent">
          {message.map((text, idx) => {
            return <span key={idx}>{text}</span>;
          })}
        </div>
        <button
          onClick={() => {
            closePopupById(id);
            clearPopups();
            confirm();
          }}
        >
          {t("brand:한국_로그인_프로세스:login_k10")}
        </button>
      </div>
    </div>
  );
}

export default CommonPopup;

const style = css`
  width: 100vw;
  height: 100vh;
  background-color: ${theme.palette.action.disabledBackground};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.dimmer};
  color: ${theme.palette.grey[9]};
  .contents {
    width: 310px;
    background-color: ${theme.palette.common.white};
    border-radius: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    button {
      width: 150px;
      height: 50px;
      margin: 0 auto 40px;
      ${theme.mixins.displayCenter};
      ${theme.fonts.small1B};
      line-height: 24px;
      background-color: ${theme.palette.primary.main};
      border-radius: 10px;
      border: none;
      cursor: pointer;
      color: ${theme.palette.grey[9]};
      &:active {
        ${theme.mixins.buttonPressEffect}
      }
    }
    h3 {
      text-align: center;
      padding: 18px 0;
      background-color: ${theme.palette.secondary.main3};
      border-radius: 30px 30px 0 0;
      color: ${theme.palette.grey[1]};
      ${theme.fonts.small1B};
      position: relative;
      line-height: 23px;
      & img {
        position: relative;
        top: 4px;
      }
    }
    span {
      margin: auto;
      text-align: center;
      ${theme.fonts.small1B};
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.5;
    }
    .innerContent {
      margin-bottom: 36px;
      padding: 0 26px;

      & span {
        display: block;
      }
      & span:nth-of-type(1) {
        margin-top: 33px;
        margin-bottom: 10px;
      }
    }
  }
`;
