import { cloneElement, useCallback, useEffect } from "react";
import isNil from "lodash/isNil";
import type { CouponControllerParam, CouponControllerProps } from "./interface";
import CouponResultPopup from "../view/components/layout/Popup";
import { useRecoilState } from "recoil";
import { popupState } from "@modules/Brand/recoil/popup/atom";
import { useMutation } from "@tanstack/react-query";
import getCouponRegisterModel from "../model/CouponRegisterModel";
import { isEmpty } from "lodash";
import { useCommonPopups } from "@hooks/usePopUp";
import { couponFormState } from "@modules/Coupon/recoil/formData/atom";
import { useTranslation } from "react-i18next";
import { KOREA_BRAND_URL } from "@common/constants";
// import useCheckUserIP from "@hooks/useCheckUserIP";
import useScreenOrientation from "@hooks/useScreenOrientation";

const CouponController = (param: CouponControllerParam) => {
  // const { isKorea } = useCheckUserIP();
  const [popups, setPopups] = useRecoilState(popupState);
  const couponRegisterModel = getCouponRegisterModel();
  const closePopupById = (id: string) =>
    setPopups(popups.filter(popup => popup.props.id !== id));
  const CouponPopups = useCommonPopups({ closePopupById });

  const { mutateAsync: mutateRegisterAsync } = useMutation({
    mutationFn: couponRegisterModel.register,
  });

  const [couponDataFormState, setCouponDataFormState] =
    useRecoilState(couponFormState);
  const { t, i18n } = useTranslation();
  const { isRequiredRotate } = useScreenOrientation();

  // useEffect(() => {
  //   if (isKorea) {
  //     window.location.href = KOREA_BRAND_URL;
  //     return;
  //   }
  // }, [isKorea]);

  const couponRegister = useCallback(
    async (characterName: string, couponCd: string) => {
      let popUpMessage = "";

      if (characterName === "") {
        setPopups([<CouponPopups.CharacterNamePopup />]);
        return;
      }

      if (couponCd === "") {
        setPopups([<CouponPopups.CouponNoPopup />]);
        return;
      }

      try {
        const apiRegisterResult = await mutateRegisterAsync({
          nickname: characterName,
          couponCode: couponCd,
        });

        if (isNil(apiRegisterResult) || isEmpty(apiRegisterResult))
          throw new Error("값이 없음");

        const apiResponseResult = apiRegisterResult.header;
        const apiIsSuccessful = apiRegisterResult.header.isSuccessful;

        switch (apiResponseResult.resultCode) {
          case 2:
            popUpMessage = t("coupon:팝업:pop2");
            break;
          case 3002:
            popUpMessage = t("coupon:팝업:pop6");
            break;
          case -4000205:
          case -100002:
            popUpMessage = t("coupon:팝업:pop4");
            break;
          case -100007:
          case -100008:
            popUpMessage = t("coupon:팝업:pop3");
            break;
          case -100011:
            popUpMessage = t("coupon:팝업:pop7");
            break;
          case -100005:
          case -100014:
            popUpMessage = t("coupon:팝업:pop8");
            break;
          case 0:
          case 200:
            popUpMessage = t("coupon:팝업:pop5");
            break;

          default:
            throw new Error("알 수 없는 오류 발생");
        }

        setPopups([
          <CouponResultPopup
            type={apiIsSuccessful ? "coupon" : "erorr"}
            id={"coupon-regist-popup-id'"}
            result={apiIsSuccessful ? "Success" : "Fail"}
            title={apiIsSuccessful ? "" : t("coupon:팝업:pop1")!}
            message={popUpMessage}
            closePopupById={closePopupById}
          />,
        ]);

        if (apiIsSuccessful) {
          setCouponDataFormState(true);
        }
      } catch (error) {
        setPopups([<CouponPopups.FailPopup />]);
      }
    },
    [popups, setPopups, closePopupById],
  );

  const props: CouponControllerProps = {
    couponRegister,
    isRequiredRotate,
  };

  return cloneElement(param.children, props);
};

export default CouponController;
