/** @jsxImportSource @emotion/react */
import { FC, SetStateAction, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { fromCDN } from "@assets/utils";
import i18n from "src/utiles/i18n";
import theme from "@common/styles/theme";

const Check = fromCDN("brand/images/Check_32.png");
const Check_PC = fromCDN("brand/images/Check_16.png");

type Props = {
  ClassName?: string;
  isOpen: boolean;
  showTwoDepth?: any;
  setOpenDropdown: React.Dispatch<SetStateAction<boolean>>;
  dropdownItems: string[];
  selectedItem: string;
  twoDepth?: boolean[];
  setTwoDepth?: React.Dispatch<React.SetStateAction<boolean[]>>;
};

//Nav, MobileMenu에 적용되는 언어선택 드랍다운
const LangDropdown: FC<Props> = ({
  ClassName,
  isOpen,
  showTwoDepth,
  setOpenDropdown,
  dropdownItems,
  selectedItem,
  twoDepth,
  setTwoDepth,
}) => {
  const outsideRef = useRef<HTMLUListElement | null>(null);

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang.toLowerCase();
  };

  useEffect(() => {
    // 메뉴 외부 클릭시 닫음 처리
    function handleClickOutside(event: any) {
      const title = "langDrop";
      if (
        outsideRef.current &&
        !outsideRef.current.contains(event.target) &&
        title !== event.target.id
      ) {
        if (twoDepth && setTwoDepth) {
          event.stopPropagation();
          setTwoDepth(twoDepth.map(() => false));
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousewheel", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.addEventListener("mousewheel", handleClickOutside);
    };
  }, [outsideRef]);

  return (
    <>
      {isOpen && (
        <ul css={style} className={ClassName} ref={outsideRef}>
          {dropdownItems.map(i => {
            return (
              <li
                key={i}
                onClick={() => {
                  handleChangeLanguage(i);
                  setOpenDropdown(false);
                  // showTwoDepth(3);
                }}
                className={i === selectedItem ? "active" : ""}
              >
                {i}
                {i === selectedItem && <span className="checked" />}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default LangDropdown;

const style = css`
  li {
    display: block;
    position: relative;
    cursor: pointer;
    ${theme.fonts.small2B};
    color: ${theme.palette.grey[9]};
  }

  .checked {
    position: absolute;
    width: 24px;
    height: 24px;
    background: url(${Check});
    right: 20px;
    top: 14px;
  }

  @media ${theme.devices.laptopL} {
    li {
      text-align: left;
      display: block;
      margin-right: 0 !important;
      color: ${theme.palette.grey[5]};
      ${theme.fonts.small1B};
      padding: 12px 0;
      padding-left: 18px;

      cursor: pointer;

      &:hover {
        color: ${theme.palette.grey[1]};
      }

      &.active {
        color: ${theme.palette.grey[1]} !important;
      }
    }

    padding: 8px 0;
    background: ${theme.palette.grey[7]};
    width: 80px;
    position: absolute;
    text-align: center;
    border-radius: 0 0 20px 20px;
    left: -20px;
    top: 78px;

    & li:nth-of-type(1)::after {
      content: "";
      display: block;
      position: absolute;
      top: -8px;
      right: 0;
      width: 100%;
      height: 6px;
      background: ${theme.palette.grey[1]};
      border-radius: 4px;
    }

    & img {
      width: 32px;
    }

    .checked {
      background: url(${Check_PC});
      display: block;
      width: 16px;
      height: 16px;
      right: 15px;
      top: 15px;
    }
  }
`;
