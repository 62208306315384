import { fromCDN } from "@assets/utils";

// 카드 뒷면 이미지
const Cardback_땅 = fromCDN("brand/images/cardback_땅.png");
const Cardback_매직 = fromCDN("brand/images/cardback_매직.png");
const Cardback_바람 = fromCDN("brand/images/cardback_바람.png");
const Cardback_불 = fromCDN("brand/images/cardback_불.png");
const Cardback_빛 = fromCDN("brand/images/cardback_빛.png");
const Cardback_숲 = fromCDN("brand/images/cardback_숲.png");
const Cardback_어둠 = fromCDN("brand/images/cardback_어둠.png");
const Cardback_얼음 = fromCDN("brand/images/cardback_얼음.png");

// 카드 앞면 이미지
const CardFront_어둠 = fromCDN("brand/images/cardfront_shadow.png");
const CardFront_빛 = fromCDN("brand/images/cardfront_light.png");
const CardFront_얼음 = fromCDN("brand/images/cardfront_ice.png");
const CardFront_바람 = fromCDN("brand/images/cardfront_wind.png");
const CardFront_매직 = fromCDN("brand/images/cardfront_magic.png");
const CardFront_불 = fromCDN("brand/images/cardfront_fire.png");
const CardFront_숲 = fromCDN("brand/images/cardfront_forest.png");

// 캐릭터 리소스 이미지 - gif
const RosaCharterImage = fromCDN("brand/gif/rosa_low.gif");
const RudolCharterImage = fromCDN("brand/gif/rudol.gif");
const IreenCharterImage = fromCDN("brand/gif/ireen_crop.gif");
const HoneyluvCharterImage = fromCDN("brand/gif/honeyluv.gif");
const OatCharterImage = fromCDN("brand/gif/oat.gif");
const CupiCharterImage = fromCDN("brand/gif/cupi_crop_new.gif");
const HeartqueenCharterImage = fromCDN("brand/gif/heartqueen.gif");
const HamzziballCharterImage = fromCDN("brand/gif/hamzziball.gif");
const RayCharterImage = fromCDN("brand/gif/ray.gif");
const ShadowCharterImage = fromCDN("brand/gif/shadow.gif");

// 캐릭터 리소스 이미지 - webp
const RosaCharterImageWebp = fromCDN("brand/gif/rosa_low.webp");
const RudolCharterImageWebp = fromCDN("brand/gif/rudol.webp");
const IreenCharterImageWebp = fromCDN("brand/gif/ireen_crop.webp");
const HoneyluvCharterImageWebp = fromCDN("brand/gif/honeyluv.webp");
const OatCharterImageWebp = fromCDN("brand/gif/oat.webp");
const CupiCharterImageWebp = fromCDN("brand/gif/cupi_crop_new.webp");
const HeartqueenCharterImageWebp = fromCDN("brand/gif/heartqueen.webp");
const HamzziballCharterImageWebp = fromCDN("brand/gif/hamzziball.webp");
const RayCharterImageWebp = fromCDN("brand/gif/ray.webp");
const ShadowCharterImageWebp = fromCDN("brand/gif/shadow.webp");

// 캐릭터 리소스 이미지 - gif (모바일)
const RosaCharterImage_MB = fromCDN("brand/gif/rosa_low_mb.gif");
const RudolCharterImage_MB = fromCDN("brand/gif/rudol_mb.gif");
const HoneyluvCharterImage_MB = fromCDN("brand/gif/honeyluv_mb.gif");
const OatCharterImage_MB = fromCDN("brand/gif/oat_mb.gif");
const HeartqueenCharterImage_MB = fromCDN("brand/gif/heartqueen_mb.gif");
const HamzziballCharterImage_MB = fromCDN("brand/gif/hamzziball_mb.gif");
const RayCharterImage_MB = fromCDN("brand/gif/ray_mb.gif");
const ShadowCharterImage_MB = fromCDN("brand/gif/shadow_mb.gif");

// 캐릭터 리소스 이미지 - webp (모바일)
const RosaCharterImageWebp_MB = fromCDN("brand/gif/rosa_low_mb.webp");
const RudolCharterImageWebp_MB = fromCDN("brand/gif/rudol_mb.webp");
const IreenCharterImageWebp_MB = fromCDN("brand/gif/ireen_crop_mb.webp");
const HoneyluvCharterImageWebp_MB = fromCDN("brand/gif/honeyluv_mb.webp");
const OatCharterImageWebp_MB = fromCDN("brand/gif/oat_mb.webp");
const CupiCharterImageWebp_MB = fromCDN("brand/gif/cupi_crop_new_mb.webp");
const HeartqueenCharterImageWebp_MB = fromCDN("brand/gif/heartqueen_mb.webp");
const HamzziballCharterImageWebp_MB = fromCDN("brand/gif/hamzziball_mb.webp");
const RayCharterImageWebp_MB = fromCDN("brand/gif/ray_mb.webp");
const ShadowCharterImageWebp_MB = fromCDN("brand/gif/shadow_mb.webp");

const darkness = fromCDN("market/images/attributes/darkness.svg");
const earth = fromCDN("market/images/attributes/earth.svg");
const fire = fromCDN("market/images/attributes/fire.svg");
const grass = fromCDN("market/images/attributes/grass.svg");
const light = fromCDN("market/images/attributes/속성_빛.svg");
const lightning = fromCDN("market/images/attributes/lightning.svg");
const magic = fromCDN("market/images/attributes/magic.svg");
const wind = fromCDN("market/images/attributes/wind.svg");
const ice = fromCDN("market/images/attributes/ice.svg");

export const cards = [
  {
    name: "로사",
    front: CardFront_바람,
    back: Cardback_바람,
    character: RosaCharterImage,
    character_webp: RosaCharterImageWebp,
    character_mb: RosaCharterImage_MB,
    character_mb_webp: RosaCharterImageWebp_MB,
    attr: [wind, ice, grass],
  },
  {
    name: "아이린",
    front: CardFront_매직,
    back: Cardback_매직,
    character: IreenCharterImage,
    character_webp: IreenCharterImageWebp,
    character_mb: IreenCharterImage,
    character_mb_webp: IreenCharterImageWebp,
    attr: [magic, lightning, wind],
    className: "irene",
  },
  {
    name: "루돌",
    front: CardFront_불,
    back: Cardback_불,
    character: RudolCharterImage,
    character_webp: RudolCharterImageWebp,
    character_mb: RudolCharterImage_MB,
    character_mb_webp: RudolCharterImageWebp_MB,
    attr: [fire, ice, grass],
  },
  {
    name: "오트",
    front: CardFront_얼음,
    back: Cardback_얼음,
    character: OatCharterImage,
    character_webp: OatCharterImageWebp,
    character_mb: OatCharterImage_MB,
    character_mb_webp: OatCharterImageWebp_MB,
    attr: [ice, earth, lightning],
  },
  {
    name: "허니럽",
    front: CardFront_숲,
    back: Cardback_숲,
    character: HoneyluvCharterImage,
    character_webp: HoneyluvCharterImageWebp,
    character_mb: HoneyluvCharterImage_MB,
    character_mb_webp: HoneyluvCharterImageWebp_MB,
    attr: [grass, lightning],
  },
  {
    name: "큐피",
    front: CardFront_매직,
    back: Cardback_매직,
    character: CupiCharterImage,
    character_webp: CupiCharterImageWebp,
    character_mb: CupiCharterImage,
    character_mb_webp: CupiCharterImageWebp,
    className: "cupinGIF",
    attr: [magic, wind, fire],
  },
  {
    name: "하트퀸",
    front: CardFront_불,
    back: Cardback_불,
    character: HeartqueenCharterImage,
    character_webp: HeartqueenCharterImageWebp,
    character_mb: HeartqueenCharterImage_MB,
    character_mb_webp: HeartqueenCharterImageWebp_MB,
    attr: [fire, grass, lightning],
  },
  {
    name: "햄찌볼",
    front: CardFront_숲,
    back: Cardback_숲,
    character: HamzziballCharterImage,
    character_webp: HamzziballCharterImageWebp,
    character_mb: HamzziballCharterImage_MB,
    character_mb_webp: HamzziballCharterImageWebp_MB,
    attr: [grass, earth, lightning],
  },
  {
    name: "레이",
    front: CardFront_빛,
    back: Cardback_빛,
    character: RayCharterImage,
    character_webp: RayCharterImageWebp,
    character_mb: RayCharterImage_MB,
    character_mb_webp: RayCharterImageWebp_MB,
    attr: [light],
  },
  {
    name: "쉐도우",
    front: CardFront_어둠,
    back: Cardback_어둠,
    character: ShadowCharterImage,
    character_webp: ShadowCharterImageWebp,
    character_mb: ShadowCharterImage_MB,
    character_mb_webp: ShadowCharterImageWebp_MB,
    attr: [darkness],
  },
];
