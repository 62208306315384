/** @jsxImportSource @emotion/react */
import { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useRecoilState, useRecoilValue } from "recoil";
import theme from "@common/styles/theme";
import { language } from "@common/constants";
import Dropdown from "./components/layout/Dropdown";
import { popupState } from "@modules/Brand/recoil/popup";
import { fromCDN } from "@common/assets/utils";
import SVGComponent from "@modules/Brand/view/components/SVGComponent";
import { CouponControllerProps } from "../controller/interface";
import { couponFormState } from "../recoil/formData/atom";
import { Trans, useTranslation } from "react-i18next";
import RotateNotice from "@modules/Brand/view/components/Layout/RotateNotice";
import i18n from "src/utiles/i18n";

const MainBg = fromCDN("coupon/images/coupon-bg-en.png");
const MainBg_JP = fromCDN("coupon/images/coupon-bg-jp.png");
const MainBg_TH = fromCDN("coupon/images/coupon-bg-th.png");
const MainBg_TW = fromCDN("coupon/images/coupon-bg-tw.png");
const Lang = SVGComponent(fromCDN("brand/svg/lang.svg"));
const Up = SVGComponent(fromCDN("coupon/svg/coupon-up.svg"));
const Down = SVGComponent(fromCDN("coupon/svg/coupon-down.svg"));
const Delete = SVGComponent(fromCDN("coupon/svg/delete.svg"));

const CouponView: FC<CouponControllerProps> = ({
  couponRegister,
  isRequiredRotate,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [name, setName] = useState("");
  const [couponNo, setCouponNo] = useState("");
  const popups = useRecoilValue(popupState);
  const [couponDataFormState, setCouponDataFormState] =
    useRecoilState(couponFormState);
  const [btn, setBtn] = useState(false);
  const [btnCoupon, setBtnCoupon] = useState(false);
  const { t, i18n } = useTranslation();

  const isLongerEnough = (val: string) => 1 < val.length;

  useEffect(() => {
    setBtn(isLongerEnough(name));
    setBtnCoupon(isLongerEnough(couponNo));
  }, [name, couponNo]);

  const updateName = (e: InputChangeEvent) => setName(e.target.value);
  const updateCouponNo = (e: InputChangeEvent) => setCouponNo(e.target.value);

  const reset = (valueSetter: Function, showBtnSetter: Function) => {
    valueSetter("");
    showBtnSetter(false);
  };

  const resetName = () => reset(setName, setBtn);
  const resetCouponNo = () => reset(setCouponNo, setBtnCoupon);

  const closeDropDown = () => setShowDropDown(false);
  const toggleDropDown = () => setShowDropDown(prev => !prev);

  const onRegisterCoupon = (name: string, couponNo: string) => {
    couponRegister(name, couponNo);
  };

  useEffect(() => {
    if (couponDataFormState) {
      setName("");
      setBtn(false);
      setCouponNo("");
      setBtnCoupon(false);
    }
  }, [couponDataFormState]);

  return (
    <div css={main(i18n.language)}>
      {popups?.length
        ? popups.map((popup, i) => <Fragment key={i}>{popup}</Fragment>)
        : null}
      <div className="headerBg"></div>
      <div className="container">
        <h2>{t("coupon:제목")}</h2>
        <div className="sort">
          <LangBox onClick={toggleDropDown} showDropDown={showDropDown}>
            <Lang className="langIcon" />
            <span className="selectedLang">{i18n.language}</span>
            {showDropDown ? <Up /> : <Down />}
          </LangBox>
          <Dropdown
            isOpen={showDropDown}
            closeDropDown={closeDropDown}
            dropdownItems={language}
            selectedItem={i18n.language}
            selectItem={(lang: string) => {
              i18n.changeLanguage(lang);
              document.documentElement.lang = lang.toLowerCase();
            }}
            className={"lang-drop-box"}
          />
        </div>
        <ul>
          <li>
            <span>{t("coupon:인풋1:label")}</span>
            <input
              type={"text"}
              placeholder={t("coupon:인풋1:placeholder")!}
              onChange={updateName}
              value={name}
            />
            {btn ? (
              <button
                className="inputClear"
                id="inputClear"
                onClick={resetName}
              >
                <Delete />
              </button>
            ) : null}
          </li>
          <li>
            <span>{t("coupon:인풋2:label")}</span>
            <input
              type={"text"}
              placeholder={t("coupon:인풋2:placeholder")!}
              onChange={updateCouponNo}
              value={couponNo}
            />
            {btnCoupon ? (
              <button
                className="inputClear"
                id="inputClear"
                onClick={resetCouponNo}
              >
                <Delete />
              </button>
            ) : null}
          </li>
        </ul>
        <div
          onClick={() => onRegisterCoupon(name, couponNo)}
          className="couponBtn"
        >
          <button>{t("coupon:버튼")}</button>
        </div>

        <div css={InfoTextBox(i18n.language)}>
          <ul className="textBox">
            <li>
              <Trans components={{ strong: <strong /> }}>
                {t("coupon:주의사항:0")}
              </Trans>
            </li>
            <li>{t("coupon:주의사항:1")}</li>
            <li>{t("coupon:주의사항:2")}</li>
            <li>{t("coupon:주의사항:3")}</li>
            <li>
              <Trans components={{ strong: <strong /> }}>
                {t("coupon:주의사항:4")}
              </Trans>
            </li>
            <li>{t("coupon:주의사항:5")}</li>
            <li>{t("coupon:주의사항:6")}</li>
          </ul>
        </div>
      </div>
      {isRequiredRotate && <RotateNotice />}
    </div>
  );
};

export default CouponView;

type InputChangeEvent = ChangeEvent<HTMLInputElement>;

const main = (lang: string) => css`
  min-width: 310px;

  & button {
    color: ${theme.palette.grey[9]};
    background: inherit;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    overflow: visible;
    cursor: pointer;
  }

  & h2 {
    ${theme.fonts.subTitle2B};
    color: ${theme.palette.grey[9]};
    margin: 30px 0 20px 0;
    line-height: 35px;
    white-space: pre-wrap;
    text-align: center;
  }

  & ul {
    margin: 30px 0 40px 0;
  }

  & li {
    margin-bottom: 20px;
    position: relative;

    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }
  }

  & input {
    width: 312px;
    height: 50px;
    padding: 16px 30px 16px 16px;
    border: 2px solid ${theme.palette.grey[1]};
    border-radius: 10px;
    ${theme.fonts.small1B};
    background: ${theme.palette.grey[1]};
  }

  & input::placeholder {
    ${theme.fonts.small2R};
    color: ${theme.palette.grey[5]};
  }

  & input:focus {
    outline: none;
    background: #fff;
    border: 2px solid ${theme.palette.grey[7]};
    box-sizing: border-box;
  }

  & input:focus::placeholder {
    color: transparent;
  }

  & span {
    display: block;
    ${lang === "TH" ? theme.fonts.small2B : theme.fonts.small3B};
    color: ${theme.palette.grey[7]};
    margin-bottom: 13px;
    line-height: 17px;
  }

  & strong {
    font-weight: 700;
  }

  .headerBg {
    width: 100%;
    height: 150px;
    background: url(${i18n.language === "JP"
        ? MainBg_JP
        : i18n.language === "TH"
        ? MainBg_TH
        : i18n.language === "TW"
        ? MainBg_TW
        : MainBg})
      no-repeat center;
    background-size: cover;
    border-bottom: 1px solid ${theme.palette.grey[2]};
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sort {
    position: relative;
    margin-bottom: 0 !important;
  }

  .inputClear {
    top: 49px;
    right: 15px;
    border: none;
    box-shadow: none;
    position: absolute;
    background: inherit;

    & img {
      width: 10px;
    }
  }

  .couponBtn {
    width: 100%;

    & button {
      height: 60px;
      width: 100%;
      border: none;
      ${lang === "TH" ? theme.fonts.buttonB : theme.fonts.subCopyB};
      background: ${theme.palette.primary.main};
      border-bottom: 3px solid #edcd19;
    }
  }

  .lang-drop-box {
    width: 200px;
    padding: 0px;
    top: 30px;
    left: -25px;

    & li {
      height: 50px;
      padding: 10px 20px;
      margin-bottom: 0px;
    }

    & img {
      width: 24px;
      height: 24px;
    }
  }

  .serverBox {
    border: 1px solid ${theme.palette.grey[3]};
    height: 50px;
    border-radius: 10px;
    padding: 10px;
    position: relative;

    & span {
      padding-top: 8px;
      margin-bottom: 0;
    }

    & svg {
      position: absolute;
      right: 22px;
      top: 22px;
      fill: ${theme.palette.grey[7]};
    }
  }

  .serverListBox {
    position: relative;
  }

  .serverListBox ul {
    width: 312px;
    left: 0;
  }

  @media ${theme.devices.tablet} {
    & h2 {
      line-height: ${lang === "TH" ? "53px" : "64px"};
      ${theme.fonts.title1Bl};
      color: ${theme.palette.grey[9]};
      margin: 60px 0 40px 0;
    }

    & span {
      display: block;
      ${theme.fonts.small1B};
      margin-bottom: 10px;
      line-height: 24px;
    }

    & ul {
      width: 100%;
      margin: 50px 0px 60px 0px;
    }

    & li {
      margin-bottom: 30px;
    }

    & input {
      width: 100%;
      height: 60px;
      padding: 20px 45px 20px 16px;
    }

    .headerBg {
      height: 300px;
    }

    .container {
      width: 500px;
      margin: 0 auto;
    }

    .inputClear {
      top: 60px;
      right: 22px;

      & img {
        width: 12px;
      }
    }

    .lang-drop-box {
      width: 200px;
      max-height: 266px;
      left: 0px;
      top: 20px;

      & li {
        height: 50px;
        padding: 10px 20px;
        margin-bottom: 0px;
      }

      & img {
        width: 20px;
      }
    }

    .serverListBox ul {
      width: 100%;
      left: 0;
      top: 60px;

      & li {
        margin-bottom: 0;
      }
    }

    .couponBtn {
      width: 400px;
      height: 70px;

      & button {
        height: 70px;
        width: 400px;
        border-radius: 100px;
        border-bottom: 4px solid #edcd19;
        cursor: pointer;
        transition: box-shadow 0.1s linear;
        font-size: 20px;
      }

      & button:hover {
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

const LangBox = styled.div<{ showDropDown: boolean }>`
  width: 150px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
  border: ${props =>
    props.showDropDown
      ? `1px solid ${theme.palette.grey[5]}`
      : `1px solid ${theme.palette.grey[3]}`};
  border-radius: 50px;

  .selectedLang {
    ${theme.fonts.small2B};
    line-height: 20px;
    margin-bottom: 0;
  }

  & img {
    position: absolute;
  }

  & img:nth-of-type(1) {
    width: 16px;
    height: 16px;
    left: 30px;
  }

  & img:nth-of-type(2) {
    right: 29px;
  }

  @media ${theme.devices.tablet} {
    width: 200px;
    height: 60px;
    cursor: pointer;

    &:hover {
      border: 1px solid ${theme.palette.grey[5]};
    }

    & span {
      ${theme.fonts.small1B};
      margin-bottom: 0;
    }

    & img:nth-of-type(1) {
      width: 24px;
      height: 24px;
      left: 40px;
    }

    & img:nth-of-type(2) {
      width: 10px;
      height: 10px;
      right: 45px;
    }
  }
`;

const InfoTextBox = (lang: string) => css`
  .textBox {
    ${lang === "JP"
      ? theme.fonts.small3R
      : lang === "TH"
      ? theme.fonts.small1R
      : theme.fonts.small2R};
    color: ${theme.palette.grey[7]};
    width: 312px;
    line-height: ${lang === "JP" ? "16px" : "20px"};
    margin: 40px 0px 60px 0px;

    & li {
      white-space: pre-wrap;
      margin-bottom: 8px;
      list-style-position: inside;
      text-indent: -1em;
      padding-left: 1em;

      &:nth-last-of-type(1) {
        margin-bottom: 0px;
      }
    }
  }

  @media ${theme.devices.tablet} {
    .textBox {
      width: 100%;
      line-height: 24px;
      padding-left: 10px;
      ${theme.fonts.small1R};
      margin: 60px 0px 80px 0px;

      & li {
        margin-bottom: 6px;

        &:nth-last-of-type(1) {
          margin-bottom: 0px;
        }
      }
    }
  }
`;
