/// <reference types="@types/facebook-js-sdk" />
/// <reference types="@types/google.accounts" />

import type { AuthApiResult } from "./interface";
import getAuthModel, { AuthModel } from "@modules/Brand/model/AuthModel";
import { SetterOrUpdater } from "recoil";
import React from "react";

declare const AppleID: any;

export default class SocialLogin {
  [x: string]: any;
  authModel: AuthModel;

  constructor(
    private readonly setAccessToken: SetterOrUpdater<string | undefined | null>,
  ) {
    this.authModel = getAuthModel();
    this.appleSignInRef = React.createRef();
  }

  //facebook login
  facebookLogin(callback: any) {
    const src = "https://connect.facebook.net/en_US/sdk.js";
    const { REACT_APP_FACEBOOK_CLIENT_ID, REACT_APP_FACEBOOK_CONFIG_ID } =
      process.env;

    const getAccessToken = (
      statusResponse: fb.StatusResponse,
    ): Promise<AuthApiResult> => {
      if (statusResponse.status !== "connected") {
        // 팝업을 유저가 직접 닫았을때
        return Promise.reject("self");
      }

      return new Promise(async (resolve, reject) => {
        try {
          const accessToken = statusResponse.authResponse.accessToken as string;
          const response = await this.authModel.facebook({ accessToken });
          if (response.header.isSuccessful) {
            this.setAccessToken(response?.result?.accessToken);
            const isFirstLogin = !response.result.alreadyLoginFlg;
            callback(isFirstLogin);
            resolve(response);
          } else {
            throw new Error("api 응답값 isSuccessful false");
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    return loginFacebook();

    function loginFacebook() {
      return appendScript()
        .then(() => initFacebookSdk())
        .then(response => {
          return signInWithFacebookSdk(response);
        })
        .then((response: fb.StatusResponse) => {
          return getAccessToken(response);
        });
    }

    function appendScript() {
      return new Promise<void>((resolve, reject) => {
        const scriptEl = window.document.createElement("script");
        scriptEl.async = true;
        scriptEl.src = src;
        scriptEl.onload = () => resolve();
        scriptEl.onerror = error => reject(error);
        window.document.head.appendChild(scriptEl);
      });
    }

    function initFacebookSdk(): Promise<fb.StatusResponse> {
      //sdk init 후 유저가 페이스북 로그인을 사용하여 이미 웹페이지에 로그인 했는지 확인
      FB.init({
        appId: REACT_APP_FACEBOOK_CLIENT_ID,
        cookie: true,
        xfbml: true,
        version: "v19.0", //공식 문서에서 권장하는 가장 최신 버젼
      });

      //FB.getLoginStatus 호출을 통해 로그인 상태를 가져온다.
      return new Promise(resolve =>
        FB.getLoginStatus(response => resolve(response)),
      );
    }

    function signInWithFacebookSdk(
      statusResponse: fb.StatusResponse,
    ): Promise<fb.StatusResponse> {
      //FB.getLoginStatus 호출에 대한 응답 status가 connected인 경우 사용자가 페이스북에 로그인하고 웹페이지에 로그인한 상태이다.
      if (statusResponse.status === "connected") {
        return Promise.resolve(statusResponse);
      }

      return new Promise(resolve =>
        FB.login(loginResponse => resolve(loginResponse), {
          config_id: REACT_APP_FACEBOOK_CONFIG_ID,
          // scope: "public_profile, email",
          // config_id : "test"
        }),
      );
    }
  }

  async appleLogin(callback: any) {
    try {
      // Apple 로그인 초기화
      await AppleID.auth.init({
        clientId: process.env.REACT_APP_APPLE_APPLICATION_ID,
        redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
        scope: "name email",
        state: "state",
        usePopup: true,
      });

      // Apple 로그인 호출
      const response = await AppleID.auth.signIn();
      const id_token = response.authorization.id_token;
      this.authModel
        .apple({ idToken: id_token })
        .then(res => {
          if (res.header.isSuccessful) {
            this.setAccessToken(res.result?.accessToken);
            const isFirstLogin = !res.result.alreadyLoginFlg;
            callback(isFirstLogin);
          } else {
            // alert(res.header);
            throw new Error("isSuccessful false");
          }
        })
        .catch(err => {
          throw new Error(err);
        });
      // 여기서 사용자의 정보를 처리합니다.
    } catch (error) {
      console.error("Apple Sign In error:", error);
    }
  }
}
