/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import theme from "@common/styles/theme";
import Footer from "@modules/Brand/view/components/Layout/Footer";
import { useTranslation } from "react-i18next";

type Props = {
  children: React.ReactNode;
};

function Layout({ children }: Props) {
  const { i18n } = useTranslation();

  return (
    <>
      <main className={i18n.language} css={style}>
        {children}
      </main>
      <Footer />
    </>
  );
}

export default Layout;

const style = css`
  height: auto;
  min-height: 100%;

  &.JP {
    font-family: "GenJyuuGothic", "Wooparoo Sans Serif", sans-serif;
  }

  @media ${theme.devices.tablet} {
    min-height: 100%;
    position: relative;
  }
`;
